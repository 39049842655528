import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgDone = (props: Props) => (
  <svg {...props} viewBox="0 0 80 80">
    <defs>
      <path
        id="yszcjm9nva"
        d="M40 10c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30zm0 5c-13.807 0-25 11.193-25 25s11.193 25 25 25 25-11.193 25-25-11.193-25-25-25zm14.508 13.681c.984.969.998 2.552.03 3.536L36.912 50.135c-.89.905-2.299.988-3.283.247l-.282-.247-8.63-8.773c-.967-.984-.954-2.567.03-3.535.942-.926 2.43-.955 3.406-.094l.13.123 6.846 6.96 15.843-16.105c.968-.985 2.55-.998 3.535-.03z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-294 -113) translate(294 113)">
          <path d="M0 0H80V80H0z" />
          <use
            fill="#FFF"
            fillOpacity=".6"
            fillRule="nonzero"
            xlinkHref="#yszcjm9nva"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgDone;
