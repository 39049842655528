import { makeObservable, observable, action, runInAction, computed } from 'mobx';

const SECTION_NAME = {
  PUBLISHED: "Published",  
  INREVIEW: "In Review",
  PREPARING: "Preparing", 
  REJECTED: "Rejected",
  DELETED: "Deleted",
};

export default class TemplatesStore{
  constructor() {
    makeObservable(this, {
      userCount: observable,
      getLocaleString: computed,
      // sectionLabels: observable,
    });
  }

  userCount = {
    publishedCount: 0,
    inReviewCount: 0,
    preparingCount: 0,
    rejectedCount: 0,
    deletedCount: 0,
  }

  sectionLabels = [
    SECTION_NAME.PUBLISHED,
    SECTION_NAME.INREVIEW,
    SECTION_NAME.PREPARING,
    SECTION_NAME.REJECTED,
    SECTION_NAME.DELETED,
  ];

  setUserCount = action((res : any) => {
    this.userCount = res;
  })

  isLoading = true;

  sectionValue = action((label : string) => {
    switch(label){
      case SECTION_NAME.PUBLISHED : 
        return this.getLocaleString.publishedCount;
      case SECTION_NAME.INREVIEW : 
        return this.getLocaleString.inReviewCount;
      case SECTION_NAME.PREPARING : 
        return this.getLocaleString.preparingCount;
      case SECTION_NAME.REJECTED : 
        return this.getLocaleString.rejectedCount;
      case SECTION_NAME.DELETED : 
        return this.getLocaleString.deletedCount;
      default : 
        return 0;
    }
  });

  userUpdate = action(async () => {
    this.isLoading = true;
    const baseUrl = process.env.REACT_APP_TEMPLATES_BASE_URL;
    const userCountUrl = process.env.REACT_APP_TEMPLATES_COUNT_ALL_URL;
    const option = {
      method: 'get',
      headers: {
        'Authorization': `bearer ${localStorage.getItem('ntoken') || ""} `
      }
    }
    const data = await fetch(`${baseUrl}${userCountUrl}`, option);
    if (!data) return;
    data.json().then((res) => {
      runInAction(()=> { 
        this.setUserCount(res); 
        this.isLoading=false;
      })
    });
  });

  get getLocaleString () {
    const resultObject = Object.assign(this.userCount);
    const keyArray = Object.keys(resultObject);
    keyArray.forEach((key)=> {resultObject[key] = resultObject[key].toLocaleString()})
    
    return resultObject;
  }
}