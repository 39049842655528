import React, { useState } from 'react';
import { 
	TableContainer,Table,
	TableHead, TableBody, TableCell, TableRow 
} from '@mui/material';
import { getDynamicLinkAnalytics } from '../../utils/fetch_status';
import ErrorMessage from './ErrorMessage';
import Search from './Search';
import Processing from '../../components/Processing/Processing';

const PLATFORMS = [
	'ANDROID', 
	'IOS', 
	'OTHER'
];

const EVENT = [
	'CLICK', 
	'REDIRECT', 
	'APP_INSTALL', 
	'APP_FIRST_OPEN',
	'APP_RE_OPEN', 
];

export const ShortUrl = () => {
	// API Call => 생성 날짜를 파라미터로 받아와 현재날짜와의 차이를 계산하여 호출 
	const [isInit, setIsInit] = useState<boolean>(false);
	const [isProgress, setIsProgress] = useState<boolean>(false);
	const [searchText, setSearchText] = useState('');
	const [analytics, setAnalytics] = useState<any>({
		LinkEventStats:[]
	});
	const [errorMessage, setErrorMessage] = useState('');
	const textHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchText(event.target.value.trim());
	};
	const searchAction = async () => {
		try {
			setIsInit(true);
			setIsProgress(true);
			setErrorMessage('');
			const response = await getDynamicLinkAnalytics(searchText) as any;
			setAnalytics(response);
			if(response.LinkEventStats === null) {
				setErrorMessage('Analytics not Found.');
			}
		} catch {
			setAnalytics({ LinkEventStats: [] });
			setErrorMessage('Analytics not Found.');
		} finally {
			setIsProgress(false);
		}
	};
	return (
		<div 
			style={{ 
				width: '100%',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				gap: '24px'
			}}
		>
			<p style={{ fontWeight: 700 }}>Enter a Firebase Dynamic Links</p>
			<Search 
				value={searchText}
				textHandler={textHandler}
				action={searchAction}
			/>
			{errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
			{isProgress && <Processing size={40} color='#6750A4' />}
			{(!isProgress && errorMessage === '' && isInit) && (
				<TableContainer sx={{ width: '400px' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell> </TableCell>
								<TableCell>Android</TableCell>
								<TableCell>iOS</TableCell>
								<TableCell>Other</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{EVENT.map((event) => {
								const data = analytics?.LinkEventStats?.filter((obj : any) => obj.Event === event);
								return (
									<TableRow key={event}>
										<TableCell>{event}</TableCell>
										{PLATFORMS.map((platform) => (
											<TableCell key={`${event}${platform}`} align="center">{data?.filter((obj : any) => obj.Platform === platform)[0]?.Count ?? '0'}</TableCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			)}
			<p style={{ width: '400px', textAlign: 'right' }}> - Note that these statistics might not include events that have been logged within the last 36 hours.</p>
		</div>
	);
};

export default ShortUrl;
