import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgNauth = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1Inside-1_51736_10074" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.3625 11.3823C48.6671 10.8856 47.7329 10.8856 47.0375 11.3823L43.4673 13.9325C36.627 18.8197 28.4137 21.4498 20 21.4498C18.8954 21.4498 18 22.3452 18 23.4498V47.8298C18 65.5694 30.2595 80.9371 47.5201 84.8944C47.8644 85.0169 48.2452 85.0465 48.6247 84.9619C66.0187 81.0884 78.4 65.6576 78.4 47.8398V23.4498C78.4 22.3452 77.5046 21.4498 76.4 21.4498C67.9871 21.4498 59.783 18.8201 52.9316 13.9317L49.3625 11.3823Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.3625 11.3823C48.6671 10.8856 47.7329 10.8856 47.0375 11.3823L43.4673 13.9325C36.627 18.8197 28.4137 21.4498 20 21.4498C18.8954 21.4498 18 22.3452 18 23.4498V47.8298C18 65.5694 30.2595 80.9371 47.5201 84.8944C47.8644 85.0169 48.2452 85.0465 48.6247 84.9619C66.0187 81.0884 78.4 65.6576 78.4 47.8398V23.4498C78.4 22.3452 77.5046 21.4498 76.4 21.4498C67.9871 21.4498 59.783 18.8201 52.9316 13.9317L49.3625 11.3823Z"
      fill="#F99191"
    />
    <path
      d="M47.0375 11.3823L48.2 13.0098L48.2 13.0098L47.0375 11.3823ZM49.3625 11.3823L48.2 13.0098L48.2 13.0098L49.3625 11.3823ZM43.4673 13.9325L42.3048 12.305L42.3046 12.3051L43.4673 13.9325ZM47.5201 84.8944L48.191 83.0103C48.1177 82.9841 48.0429 82.9623 47.9671 82.9449L47.5201 84.8944ZM48.6247 84.9619L49.0595 86.9141H49.0595L48.6247 84.9619ZM52.9316 13.9317L51.7691 15.5592L51.77 15.5598L52.9316 13.9317ZM48.2 13.0098H48.2L50.525 9.75483C49.1342 8.76141 47.2658 8.76141 45.875 9.75483L48.2 13.0098ZM44.6298 15.5599L48.2 13.0098L45.875 9.75483L42.3048 12.305L44.6298 15.5599ZM20 23.4498C28.83 23.4498 37.45 20.6898 44.63 15.5598L42.3046 12.3051C35.8041 16.9497 27.9973 19.4498 20 19.4498V23.4498ZM20 23.4498V19.4498C17.7909 19.4498 16 21.2406 16 23.4498H20ZM20 47.8298V23.4498H16V47.8298H20ZM47.9671 82.9449C31.6149 79.1959 20 64.6367 20 47.8298H16C16 66.5021 28.9041 82.6782 47.0732 86.8438L47.9671 82.9449ZM48.19 83.0098C48.1878 83.0103 48.188 83.01 48.1897 83.0101C48.1909 83.0102 48.1914 83.0104 48.191 83.0103L46.8492 86.7785C47.5383 87.0238 48.3021 87.0828 49.0595 86.9141L48.19 83.0098ZM76.4 47.8398C76.4 64.7198 64.67 79.3398 48.19 83.0098L49.0595 86.9141C67.3673 82.8371 80.4 66.5954 80.4 47.8398H76.4ZM76.4 23.4498V47.8398H80.4V23.4498H76.4ZM76.4 23.4498H80.4C80.4 21.2406 78.6091 19.4498 76.4 19.4498V23.4498ZM51.77 15.5598C58.96 20.6898 67.57 23.4498 76.4 23.4498V19.4498C68.4041 19.4498 60.6061 16.9505 54.0932 12.3036L51.77 15.5598ZM48.2 13.0098L51.7691 15.5592L54.0941 12.3042L50.525 9.75483L48.2 13.0098Z"
      fill="url(#paint0_linear_51736_10074)"
      fillOpacity="0.1"
      mask="url(#path-1Inside-1_51736_10074)"
    />
    <g filter="">
      <path
        d="M48 32C39.1657 32 32 39.1543 32 48C32 56.8457 39.1543 64 48 64C56.8457 64 64 56.8343 64 48C64 39.1657 56.8343 32 48 32ZM48 33.8057C49.04 33.7371 49.9429 34.64 49.9429 35.7486C49.9429 36.8571 49.04 37.6914 48 37.6914C46.8914 37.6914 46.0571 36.7886 46.0571 35.7486C46.0571 34.64 46.96 33.8057 48 33.8057ZM36.4571 46.1943C35.3486 46.1943 34.4457 45.2914 34.5143 44.2514C34.5143 43.2114 35.3486 42.3086 36.4571 42.3086C37.4971 42.3086 38.4 43.1429 38.4 44.2514C38.4 45.2914 37.5657 46.1943 36.4571 46.1943ZM40.7657 59.76C39.7257 59.76 38.8229 58.8571 38.8229 57.8171C38.8229 56.7086 39.7257 55.8743 40.7657 55.8743C41.8057 55.8743 42.7086 56.7086 42.7086 57.8171C42.7086 58.9257 41.8057 59.76 40.7657 59.76ZM52.08 54.6629C51.7257 54.6629 51.36 54.5143 51.1086 54.2286L46.64 49.3143H46.0571V53.3486C46.0571 54.08 45.4629 54.6629 44.7314 54.6629C44 54.6629 43.4057 54.0686 43.4057 53.3486V42.64C43.4057 41.9086 44 41.3257 44.7314 41.3257C45.4629 41.3257 46.0571 41.92 46.0571 42.64V46.6743H46.6057L50.5943 41.9543C51.0629 41.3943 51.8971 41.3257 52.4571 41.8057C53.0171 42.2743 53.0857 43.1086 52.6057 43.6686L48.9714 47.9771L53.0629 52.4686C53.5543 53.0057 53.5086 53.84 52.9714 54.3314C52.72 54.56 52.4 54.6629 52.08 54.6629V54.6629ZM55.1657 59.76C54.0571 59.76 53.1543 58.8571 53.2229 57.8171C53.2229 56.7771 54.0571 55.8743 55.1657 55.8743C56.2057 55.8743 57.1086 56.7086 57.1086 57.8171C57.1086 58.8571 56.2743 59.76 55.1657 59.76ZM59.5429 46.1257C58.4343 46.1257 57.6 45.2229 57.6 44.1829C57.6 43.0743 58.5029 42.24 59.5429 42.24C60.5829 42.24 61.4857 43.0743 61.4857 44.1829C61.4857 45.2914 60.5943 46.1257 59.5429 46.1257Z"
        fill="url(#paint1_linear_51736_10074)"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M53.3078 54.7014L53.3089 54.7003C54.0452 54.0268 54.1136 52.877 53.4323 52.1317C53.4321 52.1315 53.4319 52.1313 53.4318 52.1311L49.6362 47.9645L52.9853 43.994C52.9858 43.9934 52.9863 43.9929 52.9868 43.9923C53.6443 43.2233 53.5529 42.0727 52.7802 41.4241C52.0109 40.7668 50.86 40.8588 50.2117 41.6323C50.2114 41.6327 50.2111 41.6331 50.2108 41.6334L46.5571 45.9571V42.64C46.5571 41.6411 45.7362 40.8257 44.7314 40.8257C43.7295 40.8257 42.9057 41.6268 42.9057 42.64V53.3486C42.9057 54.3475 43.7267 55.1629 44.7314 55.1629C45.7333 55.1629 46.5571 54.3618 46.5571 53.3486V49.9664L50.7362 54.5622C51.0888 54.9609 51.5934 55.1629 52.08 55.1629C52.5062 55.1629 52.9517 55.0251 53.3078 54.7014ZM32.5 48C32.5 39.4307 39.4416 32.5 48 32.5C56.5581 32.5 63.5 39.4419 63.5 48C63.5 56.5584 56.5693 63.5 48 63.5C39.4304 63.5 32.5 56.5696 32.5 48ZM50.4429 35.7486C50.4429 34.389 49.3361 33.2266 47.9836 33.3058C46.6859 33.3145 45.5571 34.3583 45.5571 35.7486C45.5571 37.0531 46.6039 38.1914 48 38.1914C49.3046 38.1914 50.4429 37.1447 50.4429 35.7486ZM34.0143 44.2351C33.9352 45.5876 35.0976 46.6943 36.4571 46.6943C37.8533 46.6943 38.9 45.556 38.9 44.2514C38.9 42.8553 37.7617 41.8086 36.4571 41.8086C35.0668 41.8086 34.0231 42.9373 34.0143 44.2351ZM38.3229 57.8171C38.3229 59.1333 39.4496 60.26 40.7657 60.26C42.0703 60.26 43.2086 59.2133 43.2086 57.8171C43.2086 56.421 42.0703 55.3743 40.7657 55.3743C39.4611 55.3743 38.3229 56.421 38.3229 57.8171ZM52.7229 57.8008C52.6437 59.1533 53.8062 60.26 55.1657 60.26C56.5619 60.26 57.6086 59.1217 57.6086 57.8171C57.6086 56.421 56.4703 55.3743 55.1657 55.3743C53.7754 55.3743 52.7316 56.5031 52.7229 57.8008ZM57.1 44.1829C57.1 45.4874 58.1467 46.6257 59.5429 46.6257C60.8608 46.6257 61.9857 45.5771 61.9857 44.1829C61.9857 42.7867 60.8474 41.74 59.5429 41.74C58.2383 41.74 57.1 42.7867 57.1 44.1829Z"
        stroke="url(#paint2_linear_51736_10074)"
        strokeOpacity="0.1"
        strokeLinecap="round"
        shapeRendering="geometricPrecision"
      />
    </g>
    <defs>
      <filter
        id="filter0_di_51736_10074"
        x="32"
        y="32"
        width="32"
        height="33"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10074"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10074"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10074"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51736_10074"
        x1="48.2"
        y1="11.0098"
        x2="48.2"
        y2="85.01"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_51736_10074"
        x1="48"
        y1="32"
        x2="48"
        y2="64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_51736_10074"
        x1="48"
        y1="32"
        x2="48"
        y2="64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgNauth;
