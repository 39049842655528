import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import { useStore } from '../../store';

export default observer(() => {
  const { 
    receiptStore:{
      searchData : {
        isVendorId,
        errorMessage,
      }
    } 
  } = useStore();

  return (
    <Typography
      component="h5"
      variant="body2"
      className="alert-message"
      style={{
        textAlign: 'center',
        color: '#DA4852',
        fontSize: '16px',
        marginTop: '20px',
      }}
    >
      {isVendorId ? `⚠️ ${errorMessage}` : null}
    </Typography>
  );
});
