import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgSection = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 544 544"
    fill="none"
  >
    <path
      d="M16 98.5602V444.948C16 459.752 28.0016 471.754 42.8063 471.754H501.194C515.998 471.754 528 459.752 528 444.948V153.227C528 138.422 515.998 126.421 501.194 126.421H260.603C250.182 126.421 240.743 120.338 235.291 111.456C225.357 95.2712 207.444 71.7539 187.225 71.7539H42.7644C27.9597 71.7539 16 83.7555 16 98.5602Z"
      fill="#FFC531"
    />
    <path
      d="M528 163.561V435.948C528 450.752 515.998 462.754 501.194 462.754H42.8063C28.0016 462.754 16 450.752 16 435.948V190.699C16 175.895 27.962 163.893 42.7667 163.893H178.178C223.749 163.893 217.382 136.752 248.544 136.754C333.567 136.758 449.546 136.756 501.239 136.755C516.044 136.754 528 148.756 528 163.561Z"
      fill="url(#paint0_linear_42_2384)"
    />
    <g filter="url(#filter0_ddiiii_42_2384)">
      <path
        d="M412.827 206.357C411.154 201.22 406.662 197.492 401.278 196.771C395.894 196.05 390.623 198.47 387.613 202.98L351.691 257.254L287.584 258.136C282.225 258.222 277.354 261.321 275.059 266.149C272.763 270.978 273.33 276.726 276.595 280.959L316.15 332.303L296.819 394.784C295.238 399.932 296.71 405.578 300.627 409.273C304.543 412.969 310.225 414.132 315.287 412.264L375.476 389.733L427.614 427.31C432.007 430.444 437.788 430.861 442.545 428.282C447.302 425.704 450.202 420.688 450.032 415.306L447.781 349.934L499.454 310.81C503.718 307.585 505.821 302.195 504.834 296.927C503.846 291.658 500.006 287.392 494.859 285.899L433.2 268.172L412.827 206.357Z"
        fill="#FFC531"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddiiii_42_2384"
        x="261.667"
        y="184.645"
        width="255.407"
        height="257.325"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="-1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.878431 0 0 0 0 0.678431 0 0 0 0 0.168627 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_42_2384"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.866667 0 0 0 0 0.215686 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_42_2384"
          result="effect2_dropShadow_42_2384"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_42_2384"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-12" dy="12" />
        <feGaussianBlur stdDeviation="15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.878431 0 0 0 0 0.678431 0 0 0 0 0.168627 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect3_innerShadow_42_2384"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="12" dy="-12" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.866667 0 0 0 0 0.215686 0 0 0 0.9 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_innerShadow_42_2384"
          result="effect4_innerShadow_42_2384"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-12" dy="-12" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.878431 0 0 0 0 0.678431 0 0 0 0 0.168627 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_innerShadow_42_2384"
          result="effect5_innerShadow_42_2384"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="12" dy="12" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.878431 0 0 0 0 0.678431 0 0 0 0 0.168627 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_innerShadow_42_2384"
          result="effect6_innerShadow_42_2384"
        />
      </filter>
      <linearGradient
        id="paint0_linear_42_2384"
        x1="528"
        y1="136.754"
        x2="27.1538"
        y2="479.073"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.234375" stopColor="#FCF68D" />
        <stop offset="1" stopColor="#FFC531" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgSection;
