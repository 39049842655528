import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgUser = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="48" cy="48" r="30" fill="#9A82DB" />
    <circle
      cx="48"
      cy="48"
      r="29"
      stroke="url(#paint0_linear_51581_3655)"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <g filter="">
      <mask id="path-3-inside-1_51581_3655" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.4029 39.9739C55.4029 44.0622 52.0886 47.3765 48.0003 47.3765C43.9119 47.3765 40.5977 44.0622 40.5977 39.9739C40.5977 35.8855 43.9119 32.5713 48.0003 32.5713C52.0886 32.5713 55.4029 35.8855 55.4029 39.9739ZM31.7144 62.1817C31.7144 62.9993 32.3772 63.6622 33.1949 63.6622H62.8053C63.6229 63.6622 64.2858 62.9993 64.2858 62.1817C64.2858 58.5449 62.3169 55.3654 59.3572 53.1456C56.398 50.9262 52.3778 49.5973 48.0001 49.5973C43.6224 49.5973 39.6021 50.9262 36.6429 53.1456C33.6832 55.3654 31.7144 58.5449 31.7144 62.1817Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.4029 39.9739C55.4029 44.0622 52.0886 47.3765 48.0003 47.3765C43.9119 47.3765 40.5977 44.0622 40.5977 39.9739C40.5977 35.8855 43.9119 32.5713 48.0003 32.5713C52.0886 32.5713 55.4029 35.8855 55.4029 39.9739ZM31.7144 62.1817C31.7144 62.9993 32.3772 63.6622 33.1949 63.6622H62.8053C63.6229 63.6622 64.2858 62.9993 64.2858 62.1817C64.2858 58.5449 62.3169 55.3654 59.3572 53.1456C56.398 50.9262 52.3778 49.5973 48.0001 49.5973C43.6224 49.5973 39.6021 50.9262 36.6429 53.1456C33.6832 55.3654 31.7144 58.5449 31.7144 62.1817Z"
        fill="url(#paint1_linear_51581_3655)"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M59.3572 53.1456L59.9572 52.3456L59.3572 53.1456ZM36.6429 53.1456L37.2429 53.9456L36.6429 53.1456ZM48.0003 48.3765C52.6409 48.3765 56.4029 44.6145 56.4029 39.9739H54.4029C54.4029 43.5099 51.5363 46.3765 48.0003 46.3765V48.3765ZM39.5977 39.9739C39.5977 44.6145 43.3596 48.3765 48.0003 48.3765V46.3765C44.4642 46.3765 41.5977 43.5099 41.5977 39.9739H39.5977ZM48.0003 31.5713C43.3596 31.5713 39.5977 35.3333 39.5977 39.9739H41.5977C41.5977 36.4378 44.4642 33.5713 48.0003 33.5713V31.5713ZM56.4029 39.9739C56.4029 35.3333 52.6409 31.5713 48.0003 31.5713V33.5713C51.5363 33.5713 54.4029 36.4378 54.4029 39.9739H56.4029ZM33.1949 62.6622C32.9295 62.6622 32.7144 62.4471 32.7144 62.1817H30.7144C30.7144 63.5516 31.8249 64.6622 33.1949 64.6622V62.6622ZM62.8053 62.6622H33.1949V64.6622H62.8053V62.6622ZM63.2858 62.1817C63.2858 62.4471 63.0707 62.6622 62.8053 62.6622V64.6622C64.1752 64.6622 65.2858 63.5516 65.2858 62.1817H63.2858ZM58.7572 53.9456C61.5282 56.0238 63.2858 58.931 63.2858 62.1817H65.2858C65.2858 58.1589 63.1056 54.7069 59.9572 52.3456L58.7572 53.9456ZM48.0001 50.5973C52.1824 50.5973 55.9872 51.8681 58.7572 53.9456L59.9572 52.3456C56.8089 49.9843 52.5732 48.5973 48.0001 48.5973V50.5973ZM37.2429 53.9456C40.0129 51.8681 43.8177 50.5973 48.0001 50.5973V48.5973C43.427 48.5973 39.1913 49.9843 36.0429 52.3456L37.2429 53.9456ZM32.7144 62.1817C32.7144 58.931 34.472 56.0238 37.2429 53.9456L36.0429 52.3456C32.8945 54.7069 30.7144 58.1589 30.7144 62.1817H32.7144Z"
        fill="url(#paint2_linear_51581_3655)"
        mask="url(#path-3-inside-1_51581_3655)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_51581_3655"
        x="31.7144"
        y="32.5713"
        width="32.5713"
        height="32.0908"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51581_3655"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51581_3655"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51581_3655"
        x1="48"
        y1="18"
        x2="48"
        y2="78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_51581_3655"
        x1="48.0001"
        y1="32.5713"
        x2="48.0001"
        y2="63.6622"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_51581_3655"
        x1="48.0001"
        y1="32.5713"
        x2="48.0001"
        y2="63.6622"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgUser;
