import React from 'react';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { ReceiptElementProps } from './types';

export default observer(({receiptContents} : ReceiptElementProps ) => {
  return (
    <>
      {receiptContents.map(({ name, value }) => (
        <dl key={name}>
            <dt><Typography sx={{fontWeight:500}}>{name}</Typography></dt>
            <dd><Typography>{value}</Typography></dd>
        </dl>
      ))}
    </>
)});
