import React from 'react';
import { Modal, Typography } from '@mui/material';
import { NoticeSelectWrap, NoticeSearchButton, NoticeModalConfirmButton, NoticeModalContents } from './style'

export default ({objState, initializeState} : any) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const searchHandler = () => {
    const { edition, language, application, environment } = objState;
    if(edition===initializeState.edition || 
       language===initializeState.language || 
       application===initializeState.application || 
       environment===initializeState.environment){
      if(edition!=='China'){
        return handleOpen();
      }
      if(environment===initializeState.environment){
        return handleOpen();
      }
    }

    const baseUrl = process.env.REACT_APP_NOTICE_BASE_URL;
    const paramsObject = {
      language,
      edition,
      application,
      environment
    }
    const params = new URLSearchParams(paramsObject).toString();
    
    const openUrl = `${baseUrl}?${params}`
    
    // alert(openUrl)

    window.open(openUrl,'_blank');
  };

  return(
    <>
      <NoticeSelectWrap>
        <NoticeSearchButton onClick={searchHandler}>Search Notice</NoticeSearchButton>
      </NoticeSelectWrap>
      <Modal
        open={open} 
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <NoticeModalContents>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You have not choosen anything to search. <br/> Select sequentially and press the Search button.
          </Typography>
          <NoticeModalConfirmButton 
            style={{float:'right'}}
            onClick={handleClose}>OK</NoticeModalConfirmButton>
        </NoticeModalContents>
      </Modal>
    </>
  )
}