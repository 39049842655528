import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgShare = (props: Props) => (
  <svg {...props} viewBox="0 0 28 28">
    <g fill="none" fillRule="evenodd">
      <g>
        <path d="M0 0H28V28H0z" />
        <path
          fill="#FAFAFA"
          fillRule="nonzero"
          d="M10.521 7.292c.483 0 .875.391.875.875 0 .443-.329.809-.756.867l-.119.008H7c-.755 0-1.376.574-1.45 1.309l-.008.149V21c0 .755.574 1.376 1.309 1.45l.149.008h14c.755 0 1.376-.574 1.45-1.309l.008-.149v-4.667c0-.483.392-.875.875-.875.443 0 .81.33.867.757l.008.118V21c0 1.706-1.332 3.101-3.013 3.202l-.195.006H7c-1.706 0-3.101-1.332-3.202-3.013L3.792 21V10.5c0-1.706 1.332-3.101 3.013-3.202L7 7.292h3.521zm10.425-3.947l.09.078 4.124 4.125c.313.313.34.805.078 1.148l-.078.09-4.125 4.124c-.341.342-.895.342-1.237 0-.313-.313-.34-.805-.078-1.148l.078-.09 2.63-2.631H21c-5.316 0-9.625 4.31-9.625 9.626 0 .483-.392.875-.875.875s-.875-.392-.875-.875c0-6.176 4.922-11.202 11.057-11.371L21 7.292l1.429-.001-2.631-2.63c-.313-.314-.34-.805-.078-1.148l.078-.09c.313-.313.805-.34 1.148-.078z"
        />
      </g>
    </g>
  </svg>
);

export default SvgShare;
