import React from 'react';
import { observer } from 'mobx-react';
import Search from './Search';
import ReceiptElements from './ReceiptElements';
import { Container, ReceiptGrid, ReceiptPaper, ReceiptInformation } from './style';
import { useStore } from '../../store'

export default observer(() => {
  const {
    receiptStore :{
      receiptContents
    }
  }
  = useStore();

  return(
    <Container>
      <ReceiptGrid container>
        <ReceiptInformation>
          <Search />
          <ReceiptPaper variant="outlined">
            <ReceiptElements receiptContents={receiptContents}/> 
          </ReceiptPaper>
        </ReceiptInformation>
      </ReceiptGrid>
    </Container>
  )
});
