import React from 'react';

interface Props{
  width:number,
  height:number,
}

const SvgVideo = (props:Props) => (
  <svg {...props} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_311_2974" fill="white">
  <path fillRule="evenodd" clipRule="evenodd" d="M7.04166 16.1806C7.04166 13.2274 9.43569 10.8333 12.3889 10.8333H39.6111C42.5643 10.8333 44.9583 13.2274 44.9583 16.1806V35.625C44.9583 38.5782 42.5643 40.9722 39.6111 40.9722H12.3889C9.43569 40.9722 7.04166 38.5782 7.04166 35.625V16.1806Z"/>
  </mask>
  <path fillRule="evenodd" clipRule="evenodd" d="M7.04166 16.1806C7.04166 13.2274 9.43569 10.8333 12.3889 10.8333H39.6111C42.5643 10.8333 44.9583 13.2274 44.9583 16.1806V35.625C44.9583 38.5782 42.5643 40.9722 39.6111 40.9722H12.3889C9.43569 40.9722 7.04166 38.5782 7.04166 35.625V16.1806Z" fill="#F2B8B5"/>
  <path d="M12.3889 8.83333C8.33112 8.83333 5.04166 12.1228 5.04166 16.1806H9.04166C9.04166 14.3319 10.5403 12.8333 12.3889 12.8333V8.83333ZM39.6111 8.83333H12.3889V12.8333H39.6111V8.83333ZM46.9583 16.1806C46.9583 12.1228 43.6689 8.83333 39.6111 8.83333V12.8333C41.4597 12.8333 42.9583 14.3319 42.9583 16.1806H46.9583ZM46.9583 35.625V16.1806H42.9583V35.625H46.9583ZM39.6111 42.9722C43.6689 42.9722 46.9583 39.6828 46.9583 35.625H42.9583C42.9583 37.4736 41.4597 38.9722 39.6111 38.9722V42.9722ZM12.3889 42.9722H39.6111V38.9722H12.3889V42.9722ZM5.04166 35.625C5.04166 39.6828 8.33112 42.9722 12.3889 42.9722V38.9722C10.5403 38.9722 9.04166 37.4736 9.04166 35.625H5.04166ZM5.04166 16.1806V35.625H9.04166V16.1806H5.04166Z" fill="url(#paint0_linear_311_2974)" fillOpacity="0.1" mask="url(#path-1-inside-1_311_2974)"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M26 33.5833C30.1881 33.5833 33.5833 30.1882 33.5833 26C33.5833 21.8118 30.1881 18.4167 26 18.4167C21.8118 18.4167 18.4167 21.8118 18.4167 26C18.4167 30.1882 21.8118 33.5833 26 33.5833ZM30.5577 24.2386C30.9808 23.8155 30.9808 23.1296 30.5577 22.7065C30.1346 22.2835 29.4487 22.2835 29.0256 22.7065L24.7361 26.9961L22.9744 25.2343C22.5513 24.8112 21.8654 24.8112 21.4423 25.2343C21.0192 25.6574 21.0192 26.3433 21.4423 26.7664L23.9701 29.2942C24.3931 29.7172 25.0791 29.7172 25.5021 29.2942L30.5577 24.2386Z" fill="url(#paint1_linear_311_2974)" shapeRendering="geometricPrecision"/>
  <path d="M11.9024 17.1524C11.9024 16.347 12.5553 15.694 13.3607 15.694C14.1661 15.694 14.819 16.347 14.819 17.1524C14.819 17.9578 14.1661 18.6107 13.3607 18.6107C12.5553 18.6107 11.9024 17.9578 11.9024 17.1524Z" fill="#FAFAFA"/>
  <path d="M37.1801 17.1524C37.1801 16.347 37.8331 15.694 38.6385 15.694C39.4439 15.694 40.0968 16.347 40.0968 17.1524C40.0968 17.9578 39.4439 18.6107 38.6385 18.6107C37.8331 18.6107 37.1801 17.9578 37.1801 17.1524Z" fill="#FAFAFA"/>
  <path d="M11.9024 22.9857C11.9024 22.1803 12.5553 21.5274 13.3607 21.5274C14.1661 21.5274 14.819 22.1803 14.819 22.9857C14.819 23.7911 14.1661 24.4441 13.3607 24.4441C12.5553 24.4441 11.9024 23.7911 11.9024 22.9857Z" fill="#FAFAFA"/>
  <path d="M37.1801 22.9857C37.1801 22.1803 37.8331 21.5274 38.6385 21.5274C39.4439 21.5274 40.0968 22.1803 40.0968 22.9857C40.0968 23.7911 39.4439 24.4441 38.6385 24.4441C37.8331 24.4441 37.1801 23.7911 37.1801 22.9857Z" fill="#FAFAFA"/>
  <path d="M11.9024 28.8191C11.9024 28.0136 12.5553 27.3607 13.3607 27.3607C14.1661 27.3607 14.819 28.0136 14.819 28.8191C14.819 29.6245 14.1661 30.2774 13.3607 30.2774C12.5553 30.2774 11.9024 29.6245 11.9024 28.8191Z" fill="#FAFAFA"/>
  <path d="M37.1801 28.8191C37.1801 28.0136 37.8331 27.3607 38.6385 27.3607C39.4439 27.3607 40.0968 28.0136 40.0968 28.8191C40.0968 29.6245 39.4439 30.2774 38.6385 30.2774C37.8331 30.2774 37.1801 29.6245 37.1801 28.8191Z" fill="#FAFAFA"/>
  <path d="M11.9024 34.6524C11.9024 33.847 12.5553 33.1941 13.3607 33.1941C14.1661 33.1941 14.819 33.847 14.819 34.6524C14.819 35.4578 14.1661 36.1107 13.3607 36.1107C12.5553 36.1107 11.9024 35.4578 11.9024 34.6524Z" fill="#FAFAFA"/>
  <path d="M37.1801 34.6524C37.1801 33.847 37.8331 33.1941 38.6385 33.1941C39.4439 33.1941 40.0968 33.847 40.0968 34.6524C40.0968 35.4578 39.4439 36.1107 38.6385 36.1107C37.8331 36.1107 37.1801 35.4578 37.1801 34.6524Z" fill="#FAFAFA"/>
  <defs>
  <filter id="filter0_di_311_2974" x="18.4167" y="18.4167" width="15.1667" height="16.1667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_311_2974"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_311_2974" result="shape"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="shape" result="effect2_innerShadow_311_2974"/>
  </filter>
  <filter id="filter1_di_311_2974" x="11.9024" y="15.694" width="28.1944" height="21.4167" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feComposite in2="hardAlpha" operator="out"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_311_2974"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_311_2974" result="shape"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="1"/>
  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
  <feBlend mode="normal" in2="shape" result="effect2_innerShadow_311_2974"/>
  </filter>
  <linearGradient id="paint0_linear_311_2974" x1="26" y1="10.8333" x2="26" y2="40.9722" gradientUnits="userSpaceOnUse">
  <stop stopColor="white"/>
  <stop offset="1" stopColor="white" stopOpacity="0"/>
  </linearGradient>
  <linearGradient id="paint1_linear_311_2974" x1="26" y1="18.4167" x2="26" y2="33.5833" gradientUnits="userSpaceOnUse">
  <stop stopColor="white" stopOpacity="0.9"/>
  <stop offset="1" stopColor="white" stopOpacity="0.8"/>
  </linearGradient>
  </defs>
  </svg>
)

export default SvgVideo;