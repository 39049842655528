import React from 'react';
import { Modal, Typography } from '@mui/material';
import { FaqSelectWrap, FaqSearchButton, FaqModalConfirmButton, FaqModalContents } from './style'

export default ({objState, initializeState} : any) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const searchHandler = () => {
    const { edition, language } = objState;
    
    if(language===initializeState.language || edition===initializeState.edition){
      if(edition.indexOf('china') < 0){
        return handleOpen();
      }
    }

    const paramsObj = { lang : '' };

    if(edition.indexOf('china') < 0){
      paramsObj.lang=language
    }else{
      paramsObj.lang='zh';
    }
    
    const baseUrl = process.env.REACT_APP_FAQ_BASE_URL;
    const params = new URLSearchParams(paramsObj).toString();
    
    const openUrl = `${baseUrl}${edition}?${params}`

    window.open(openUrl,'_blank');
  };

  return(
    <>
      <FaqSelectWrap>
        <FaqSearchButton onClick={searchHandler}>Search FAQ</FaqSearchButton>
      </FaqSelectWrap>
      <Modal
        open={open}
        onClose={handleClose}
        aria-describedby="modal-modal-description"
      >
        <FaqModalContents>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You have not choosen anything to search. <br/> Select sequentially and press the Search button.
          </Typography>
          <FaqModalConfirmButton 
            style={{float:'right'}}
            onClick={handleClose}>OK</FaqModalConfirmButton>
        </FaqModalContents>
      </Modal>
    </>
  )
}