import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

export const DashboardContainer = styled.div`
  display: grid;
  &.badges {
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (min-width: 1920px) {
    width: 90%;
    max-width: 1920px;
    grid-template-columns: repeat(3, 1fr);
    // padding-left: var(--dashboard-container-padding);
    // padding-right: var(--dashboard-container-padding);
    padding-bottom: 20px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    width: calc(100vw - 312px);
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 20px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    width: calc(100% - 80px);
    grid-template-columns: repeat(3, 1fr);
    // padding-left: 40px;
    // padding-right: 40px;
    padding-bottom: 20px;
    &.badges {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1024px) {
    width: calc(100% - 80px);
    grid-template-columns: repeat(2, 1fr);
    // padding-left: 40px;
    // padding-right: 40px;
    padding-bottom: 20px;
    &.badges {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 640px) {
    width: calc(100% - 40px);
    grid-template-columns: repeat(1, 1fr);
    // padding-left: 20px;
    // padding-right: 20px;
    padding-bottom: 20px;
    &.badges {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 480px) {
    &.badges {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  row-gap: 16px;
  column-gap: 16px;
`;

export const DashboardTime = styled.div`
  margin-top: 90px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;

  @media screen and (min-width: 1920px) {
    width: 90%;
    max-width: 1920px;
    padding-left: 5%;
    padding-right: 5%;
  }

  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    width: calc(100vw - 312px);
  }

  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 640px) and (max-width: 1024px) {
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: 640px) {
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const DashboardContent = styled.div`
  height: 92px;
  background: #ffffff;
  border-radius: 11px;
  border: 1px solid #eaddff;
`;

export const DashboardTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #eaddff;
  border-radius: 8px 8px 0px 0px;
  color: #6750a4;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.703819px;
  img {
    width: 16px;
    height: 16px;
  }
`;

export const DashboardCount = styled(Typography)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 22px !important;
  line-height: 34px;
  font-family: 'Roboto';
  letter-spacing: 0.140764px;
  font-style: normal;
  font-weight: 500 !important;
  color: #1c1b1f;

  &.progress {
    justify-content: center;
  }
`;

export const ProgressImage = styled(CircularProgress)`
  width: 28px !important;
  height: 28px !important;
  color: #939094 !important;
`;
