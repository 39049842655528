import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@mui/material';
import { useStore } from '../../store';
import { HomeMainContainer } from '../Home/style';
import {
  DashboardContainer,
  DashboardContent,
  DashboardTime,
  DashboardTitle,
  DashboardCount,
  ProgressImage,
} from './style';

/* eslint-disable @typescript-eslint/no-unused-vars */

export default observer(() => {
  const {
    dashboardStore: {
      latestDate,
      userCount,
      sectionLabels,
      sectionValue,
      userUpdate,
      isLoading,
    },
  } = useStore();
  const useerLabel: any = {
    TOTAL: 'Members',
    ACTIVATED: 'Activated Memebers',
    DELETED: 'Deleted Members',
    SUSPENDED: 'Suspended Members',
    TO_BE_DELETED_BY_USER: 'To be deleted Members (by Member)',
    TO_BE_DELETED_BY_ADMIN: 'To be deleted Members (by Admin)',
  };

  useEffect(() => {
    userUpdate();
  }, [userUpdate]);

  return (
    <HomeMainContainer>
      {latestDate !== '' && (
        <DashboardTime>
          The value is effective until: {latestDate} (UTC)
        </DashboardTime>
      )}
      <DashboardContainer style={{ borderBottom: '1px solid #ddd' }}>
        {userCount.users.map((user: any) => (
          <DashboardContent key={user.name}>
            <DashboardTitle>{useerLabel[user.name]}</DashboardTitle>
            {!isLoading && (
              <DashboardCount>{user.count.toLocaleString()}</DashboardCount>
            )}
            {isLoading && (
              <DashboardCount className="progress">
                <ProgressImage />
              </DashboardCount>
            )}
          </DashboardContent>
        ))}
      </DashboardContainer>
      {/* <div style={{ height: '1px', backgroundColor: '#000' }} /> */}
      <DashboardContainer className="badges">
        {userCount.badges.map((badge: any) => (
          <DashboardContent key={badge.name}>
            <DashboardTitle>
              <img src={badge.image} alt={badge.name} />
              <p>{badge.name.replaceAll('_', ' ')}</p>
            </DashboardTitle>
            {!isLoading && (
              <DashboardCount>{badge.count.toLocaleString()}</DashboardCount>
            )}
            {isLoading && (
              <DashboardCount className="progress">
                <ProgressImage />
              </DashboardCount>
            )}
          </DashboardContent>
        ))}
      </DashboardContainer>
    </HomeMainContainer>
  );
});
