import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgErrorServer = (props: Props) => (
  <svg {...props} viewBox="0 0 80 80">
    <defs>
      <path
        id="g6ipbha5ja"
        d="M14.494 17.642l.262.226 44.705 44.81c.911.915.91 2.394-.004 3.306-.83.829-2.13.902-3.043.223l-.262-.227-8.788-8.81H21.796c-8.337 0-15.13-6.415-15.13-14.178 0-7.406 4.935-13.337 11.776-14.811l-6.995-7.012c-.912-.913-.91-2.393.004-3.305.83-.828 2.129-.902 3.043-.222zM39.508 15c10.616 0 19.328 8.164 20.015 18.41l.013.336 1.62.002c6.537 0 11.826 4.733 12.16 10.625l.017.593c0 6.748-4.906 11.875-11.53 12.189l-.647.015c-.65 0-1.273-.253-1.738-.707l-3.717-3.624c-.08-.077-.08-.203-.004-.282.038-.039.09-.06.143-.06h5.316c4.366 0 7.503-3.144 7.503-7.531 0-3.36-2.993-6.29-6.978-6.528l-.525-.016H57.22c-1.183 0-2.16-.879-2.316-2.02l-.02-.317v-1.901c-.246-7.97-7.037-14.51-15.376-14.51-3.901 0-7.72 1.69-10.494 4.539-.396.415-1.052.43-1.467.035l-.01-.01-2.17-2.122c-.137-.133-.41-.426.06-.914C29.13 17.357 34.202 15 39.507 15zm3.193 37.496L22.75 32.501h-.955c-5.943 0-10.455 4.505-10.455 10.491 0 4.944 4.327 9.201 9.858 9.489l.597.015H42.7z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-294 -113) translate(294 113)">
          <path fillRule="nonzero" d="M0 0L80 0 80 80 0 80z" />
          <mask id="atnw6ye18b" fill="#fff">
            <use xlinkHref="#g6ipbha5ja" />
          </mask>
          <path
            fill="#FFF"
            fillOpacity=".6"
            fillRule="nonzero"
            d="M0 0L80 0 80 80 0 80z"
            mask="url(#atnw6ye18b)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgErrorServer;
