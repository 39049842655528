import React from 'react';

interface Props{
  width:number,
  height:number,
}

const SvgVideo = (props:Props) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22 4H2V20H22V4ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM14.7071 10.667L14.3536 11.0205L11.6869 13.6872L11.3333 14.0407L10.9798 13.6872L9.64645 12.3539L9.29289 12.0003L10 11.2932L10.3536 11.6468L11.3333 12.6265L13.6464 10.3134L14 9.95987L14.7071 10.667ZM4 6H5.5V7.5H4V6ZM4 9.5H5.5V11H4V9.5ZM5.5 13H4V14.5H5.5V13ZM4 16.5H5.5V18H4V16.5ZM20 6H18.5V7.5H20V6ZM20 9.5H18.5V11H20V9.5ZM18.5 13H20V14.5H18.5V13ZM20 16.5H18.5V18H20V16.5Z" fill="#6750A4"/>
  </svg>
)

export default SvgVideo;