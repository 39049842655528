import React from 'react';
import { 
  NoticeSelectWrap, NoticeSelectBox, NoticeSelectLabel, 
  NoticeSelect, NoticeSelectItem } from './style';

export default ({ application, objState, selectHandler, settingObject } : any) => {
  const labelText = 'Edition';
  return (
    <NoticeSelectWrap>
      <NoticeSelectBox>
        <NoticeSelectLabel>{labelText}</NoticeSelectLabel>
        <NoticeSelect 
          value={objState.edition}
          name="edition"
          onChange={selectHandler}
        > 
          {settingObject.edition[application].map((item : any) => (
            <NoticeSelectItem
              value={item.value}
              key={item.value}
              style={{ zIndex: 1600 }}
            >
              {item.name}
            </NoticeSelectItem>
          ))}
        </NoticeSelect>
      </NoticeSelectBox>
    </NoticeSelectWrap>
  )
}