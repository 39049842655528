import React from 'react';
import {
  Container,
  SearchGrid,
} from './style';
import SearchTitle from './SearchTitle';
import SearchForm from './SearchForm'

const SearchComment = () => (
  <SearchGrid>
    <Container>
      <SearchTitle title="Enter a Vendor Id"/>
      <SearchForm />
    </Container>
  </SearchGrid>
);

export default SearchComment;
