import React from 'react';

const SvgGoogle = () => (
	<svg width="52" height="52" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.15039 98.743C3.15039 81.8197 17.38 68 34.3618 68C43.7311 68 50.4067 71.6892 55.4427 76.4909L49.5284 82.4053C45.9563 79.0089 41.0374 76.4324 34.3618 76.4324C22.0061 76.4324 12.344 86.3872 12.344 98.743C12.344 111.099 22.0061 121.054 34.3618 121.054C42.3843 121.054 46.9518 117.833 49.8797 114.905C52.2806 112.504 53.8617 109.049 54.4472 104.365H34.3618V95.9907H62.6454C62.9382 97.5133 63.1138 99.27 63.1138 101.261C63.1138 107.585 61.4156 115.373 55.8526 120.878C50.4653 126.499 43.5554 129.486 34.4204 129.486C17.38 129.427 3.15039 115.666 3.15039 98.743Z" fill="#4285F4"/>
<path d="M85.8922 89.9006C74.9418 89.9006 66.041 98.2159 66.041 109.693C66.041 121.112 74.9418 129.486 85.8922 129.486C96.8425 129.486 105.743 121.112 105.743 109.693C105.743 98.2159 96.8425 89.9006 85.8922 89.9006ZM85.8922 121.639C79.9193 121.639 74.7076 116.72 74.7076 109.635C74.7076 102.491 79.8607 97.6303 85.8922 97.6303C91.8651 97.6303 97.0768 102.491 97.0768 109.635C97.0768 116.72 91.8651 121.639 85.8922 121.639Z" fill="#DB4437"/>
<path d="M129.224 89.9006C118.274 89.9006 109.373 98.2159 109.373 109.693C109.373 121.112 118.274 129.486 129.224 129.486C140.175 129.486 149.075 121.112 149.075 109.693C149.075 98.2159 140.175 89.9006 129.224 89.9006ZM129.224 121.639C123.251 121.639 118.04 116.72 118.04 109.635C118.04 102.491 123.193 97.6303 129.224 97.6303C135.256 97.6303 140.409 102.491 140.409 109.635C140.409 116.72 135.197 121.639 129.224 121.639Z" fill="#F4B400"/>
<path d="M182.398 91.0721V94.2928H182.105C180.173 91.9504 176.425 89.8423 171.682 89.8423C161.785 89.8423 152.709 98.5089 152.709 109.694C152.709 120.761 161.785 129.428 171.682 129.428C176.425 129.428 180.173 127.319 182.105 124.919H182.398V127.788C182.398 135.342 178.357 139.382 171.857 139.382C166.529 139.382 163.249 135.576 161.903 132.355L154.349 135.518C156.515 140.788 162.313 147.229 171.916 147.229C182.105 147.229 190.713 141.256 190.713 126.617V91.0721H182.398ZM172.443 121.639C166.47 121.639 161.434 116.603 161.434 109.694C161.434 102.725 166.47 97.6306 172.443 97.6306C178.357 97.6306 182.983 102.725 182.983 109.694C183.042 116.662 178.357 121.639 172.443 121.639Z" fill="#4285F4"/>
<path d="M205.352 70.108H196.686V128.256H205.352V70.108Z" fill="#0F9D58"/>
<path d="M229.183 121.639C224.733 121.639 221.629 119.59 219.58 115.666L246.048 104.716L245.17 102.491C243.53 98.0402 238.494 89.9006 228.246 89.9006C218.057 89.9006 209.566 97.9231 209.566 109.693C209.566 120.761 217.94 129.486 229.183 129.486C238.26 129.486 243.472 123.923 245.697 120.702L238.963 116.193C236.679 119.472 233.634 121.639 229.183 121.639ZM228.539 97.5132C231.994 97.5132 234.922 99.2114 235.859 101.729L218.174 109.049C217.94 101.437 224.089 97.5132 228.539 97.5132Z" fill="#DB4437"/>
<path d="M12.5711 182.479C10.5986 182.479 8.68504 181.831 6.83034 180.565C4.94621 179.27 3.73918 177.474 3.15039 175.178L6.32987 173.882C6.71259 175.354 7.47802 176.62 8.6556 177.621C9.83319 178.652 11.1285 179.152 12.6005 179.152C14.1019 179.152 15.3973 178.769 16.4571 177.975C17.5169 177.18 18.0468 176.12 18.0468 174.766C18.0468 173.264 17.5169 172.087 16.4571 171.292C15.3973 170.467 13.6898 169.702 11.3935 169.025C9.00888 168.259 7.18362 167.288 5.97659 166.11C4.74013 164.903 4.1219 163.402 4.1219 161.518C4.1219 159.575 4.88733 157.897 6.41819 156.454C7.94905 155.012 9.95094 154.305 12.4239 154.305C14.7202 154.305 16.5749 154.864 17.988 156.013C19.4305 157.161 20.3431 158.397 20.7847 159.751L17.6052 161.076C17.3697 160.222 16.8104 159.428 15.9272 158.692C15.044 157.956 13.8959 157.602 12.4828 157.602C11.158 157.602 10.0098 157.985 9.06776 158.721C8.12569 159.457 7.65465 160.399 7.65465 161.488C7.65465 162.489 8.09625 163.343 8.95 164.02C9.80375 164.697 11.0991 165.345 12.7771 165.875C14.1019 166.316 15.2206 166.729 16.1038 167.111C16.987 167.523 17.8996 168.053 18.8123 168.701C19.7249 169.378 20.4315 170.202 20.873 171.174C21.3441 172.175 21.5796 173.323 21.5796 174.618C21.5796 175.914 21.3146 177.091 20.7553 178.122C20.2254 179.152 19.4894 179.976 18.6062 180.565C17.723 181.154 16.7515 181.625 15.7211 181.949C14.6907 182.332 13.6309 182.479 12.5711 182.479Z" fill="#616161"/>
<path d="M32.9129 182.479C30.1161 182.479 27.8493 181.537 26.1123 179.682C24.3754 177.827 23.4922 175.472 23.4922 172.646C23.4922 169.967 24.3459 167.641 26.024 165.698C27.7315 163.755 29.8806 162.784 32.5302 162.784C35.268 162.784 37.4466 163.667 39.0952 165.463C40.7438 167.259 41.5681 169.643 41.5681 172.617L41.5387 173.264H26.9661C27.0544 175.119 27.7021 176.591 28.8208 177.68C29.9689 178.77 31.2937 179.299 32.8246 179.299C35.2975 179.299 37.005 178.24 37.8882 176.12L40.9793 177.415C40.3905 178.858 39.3896 180.035 38.0059 181.007C36.6223 182.008 34.9442 182.479 32.9129 182.479ZM37.8882 170.438C37.7998 169.378 37.3288 168.377 36.4456 167.435C35.5624 166.493 34.2377 165.993 32.4713 165.993C31.1759 165.993 30.0867 166.405 29.1446 167.2C28.2025 167.995 27.5549 169.084 27.2016 170.438H37.8882Z" fill="#616161"/>
<path d="M49.7815 182.479C47.809 182.479 46.1898 181.92 44.865 180.771C43.5402 179.653 42.8926 178.151 42.8926 176.297C42.8926 174.295 43.658 172.705 45.2183 171.557C46.7786 170.409 48.6922 169.849 50.9885 169.849C53.0198 169.849 54.6979 170.232 55.9932 170.968V170.438C55.9932 169.084 55.5222 167.995 54.6095 167.17C53.6675 166.346 52.5488 165.934 51.1946 165.934C50.1936 165.934 49.281 166.169 48.4567 166.64C47.6324 167.111 47.073 167.759 46.7786 168.613L43.5991 167.259C44.0113 166.14 44.865 165.139 46.1309 164.197C47.3968 163.255 49.0455 162.784 51.1062 162.784C53.4614 162.784 55.4339 163.461 56.9942 164.845C58.5545 166.228 59.3199 168.171 59.3199 170.674V181.89H55.9932V179.329H55.846C54.4624 181.449 52.431 182.479 49.7815 182.479ZM50.3408 179.329C51.7833 179.329 53.0787 178.799 54.2268 177.739C55.4044 176.679 55.9932 175.413 55.9932 173.941C55.0217 173.147 53.5497 172.734 51.5773 172.734C49.8992 172.734 48.6333 173.088 47.7796 173.824C46.9258 174.56 46.4842 175.413 46.4842 176.385C46.4842 177.298 46.8669 178.004 47.6618 178.534C48.4567 179.064 49.3399 179.329 50.3408 179.329Z" fill="#616161"/>
<path d="M66.0911 181.89H62.6172V163.432H65.9439V166.434H66.0911C66.4443 165.433 67.1509 164.609 68.2696 163.961C69.3589 163.314 70.4187 162.99 71.4491 162.99C72.4795 162.99 73.3332 163.137 74.0398 163.432L72.715 166.67C72.2734 166.493 71.6552 166.405 70.8603 166.405C69.5944 166.405 68.5051 166.905 67.5336 167.906C66.5621 168.907 66.0911 170.203 66.0911 171.792V181.89Z" fill="#616161"/>
<path d="M83.1965 182.479C80.4292 182.479 78.1623 181.537 76.3665 179.682C74.5707 177.798 73.6875 175.472 73.6875 172.646C73.6875 169.82 74.5707 167.494 76.3665 165.61C78.1623 163.726 80.4292 162.813 83.1965 162.813C85.2573 162.813 86.9648 163.313 88.319 164.314C89.6732 165.315 90.6742 166.581 91.2924 168.112L88.1129 169.437C87.1709 167.111 85.4339 165.963 82.961 165.963C81.4301 165.963 80.0759 166.581 78.8983 167.847C77.7207 169.113 77.1319 170.703 77.1319 172.646C77.1319 174.589 77.7207 176.179 78.8983 177.444C80.0759 178.71 81.4301 179.329 82.961 179.329C85.5517 179.329 87.318 178.18 88.319 175.855L91.4102 177.18C90.7919 178.681 89.7615 179.947 88.3779 180.977C86.9648 181.978 85.2573 182.479 83.1965 182.479Z" fill="#616161"/>
<path d="M96.9739 154.923V163.431L96.8267 165.992H96.9739C97.4743 165.109 98.2692 164.373 99.3585 163.755C100.448 163.137 101.596 162.842 102.832 162.842C105.129 162.842 106.866 163.549 108.073 164.962C109.28 166.375 109.868 168.259 109.868 170.614V181.89H106.395V171.115C106.395 167.7 104.864 165.992 101.831 165.992C100.389 165.992 99.2407 166.552 98.3281 167.7C97.4155 168.848 96.9739 170.173 96.9739 171.674V181.89H93.5V154.923H96.9739Z" fill="#616161"/>
<path d="M130.654 182.479C126.68 182.479 123.353 181.124 120.674 178.445C117.995 175.766 116.641 172.41 116.641 168.377C116.641 164.344 117.995 161.017 120.674 158.309C123.353 155.63 126.68 154.275 130.654 154.275C134.717 154.275 138.014 155.747 140.575 158.691L138.161 161.017C136.336 158.721 133.833 157.602 130.654 157.602C127.71 157.602 125.237 158.603 123.206 160.576C121.174 162.548 120.173 165.168 120.173 168.377C120.173 171.586 121.174 174.206 123.206 176.179C125.237 178.151 127.71 179.152 130.654 179.152C133.892 179.152 136.66 177.857 138.956 175.236L141.37 177.651C140.133 179.122 138.603 180.3 136.748 181.183C134.864 182.066 132.832 182.479 130.654 182.479Z" fill="#616161"/>
<path d="M145.372 165.639C147.168 163.755 149.435 162.843 152.202 162.843C154.97 162.843 157.237 163.785 159.032 165.639C160.828 167.523 161.711 169.849 161.711 172.675C161.711 175.502 160.828 177.827 159.032 179.711C157.237 181.596 154.97 182.508 152.202 182.508C149.435 182.508 147.168 181.566 145.372 179.711C143.577 177.827 142.693 175.502 142.693 172.675C142.693 169.849 143.577 167.494 145.372 165.639ZM147.934 177.504C149.141 178.711 150.554 179.329 152.173 179.329C153.792 179.329 155.205 178.711 156.412 177.504C157.619 176.296 158.208 174.677 158.208 172.675C158.208 170.674 157.619 169.054 156.412 167.847C155.205 166.64 153.792 166.022 152.173 166.022C150.554 166.022 149.141 166.64 147.934 167.847C146.727 169.054 146.138 170.674 146.138 172.675C146.167 174.677 146.756 176.267 147.934 177.504Z" fill="#616161"/>
<path d="M167.454 163.431V165.993H167.601C168.101 165.109 168.896 164.373 169.985 163.755C171.075 163.137 172.223 162.843 173.459 162.843C175.756 162.843 177.493 163.549 178.7 164.962C179.907 166.375 180.495 168.259 180.495 170.615V181.89H177.022V171.115C177.022 167.7 175.491 165.993 172.458 165.993C171.016 165.993 169.868 166.552 168.955 167.7C168.042 168.848 167.601 170.173 167.601 171.674V181.89H164.127V163.431H167.454Z" fill="#616161"/>
<path d="M190.594 182.479C188.533 182.479 186.825 181.978 185.501 180.977C184.146 179.976 183.175 178.71 182.557 177.209L185.648 175.914C186.619 178.239 188.297 179.388 190.653 179.388C191.742 179.388 192.625 179.152 193.302 178.681C193.979 178.21 194.332 177.562 194.332 176.797C194.332 175.59 193.479 174.766 191.801 174.353L188.062 173.441C186.884 173.146 185.766 172.558 184.706 171.733C183.646 170.88 183.116 169.761 183.116 168.318C183.116 166.699 183.852 165.374 185.295 164.344C186.737 163.314 188.445 162.813 190.446 162.813C192.066 162.813 193.538 163.196 194.804 163.932C196.099 164.668 197.011 165.728 197.541 167.111L194.539 168.348C193.861 166.729 192.448 165.904 190.329 165.904C189.298 165.904 188.445 166.11 187.738 166.552C187.031 166.993 186.678 167.553 186.678 168.289C186.678 169.349 187.502 170.055 189.122 170.438L192.772 171.292C194.509 171.704 195.775 172.381 196.629 173.352C197.453 174.324 197.865 175.443 197.865 176.679C197.865 178.328 197.188 179.711 195.834 180.83C194.48 181.949 192.713 182.479 190.594 182.479Z" fill="#616161"/>
<path d="M201.839 165.639C203.635 163.755 205.902 162.843 208.669 162.843C211.436 162.843 213.703 163.785 215.499 165.639C217.295 167.523 218.178 169.849 218.178 172.675C218.178 175.502 217.295 177.827 215.499 179.711C213.703 181.596 211.436 182.508 208.669 182.508C205.902 182.508 203.635 181.566 201.839 179.711C200.043 177.827 199.16 175.502 199.16 172.675C199.16 169.849 200.043 167.494 201.839 165.639ZM204.43 177.504C205.637 178.711 207.05 179.329 208.669 179.329C210.288 179.329 211.701 178.711 212.908 177.504C214.115 176.296 214.704 174.677 214.704 172.675C214.704 170.674 214.115 169.054 212.908 167.847C211.701 166.64 210.288 166.022 208.669 166.022C207.05 166.022 205.637 166.64 204.43 167.847C203.223 169.054 202.634 170.674 202.634 172.675C202.634 174.677 203.223 176.267 204.43 177.504Z" fill="#616161"/>
<path d="M224.126 181.89H220.652V154.923H224.126V181.89Z" fill="#616161"/>
<path d="M236.048 182.479C233.251 182.479 230.984 181.536 229.247 179.682C227.51 177.827 226.627 175.472 226.627 172.646C226.627 169.967 227.481 167.641 229.159 165.698C230.866 163.755 233.015 162.783 235.665 162.783C238.403 162.783 240.581 163.667 242.23 165.462C243.879 167.258 244.703 169.643 244.703 172.616L244.673 173.264H230.101C230.189 175.119 230.837 176.591 231.956 177.68C233.104 178.769 234.428 179.299 235.959 179.299C238.432 179.299 240.14 178.239 241.023 176.12L244.114 177.415C243.525 178.857 242.524 180.035 241.141 181.007C239.757 182.008 238.05 182.479 236.048 182.479ZM241.023 170.438C240.935 169.378 240.464 168.377 239.58 167.435C238.697 166.493 237.372 165.992 235.606 165.992C234.311 165.992 233.221 166.405 232.279 167.199C231.337 167.994 230.69 169.084 230.336 170.438H241.023Z" fill="#616161"/>
</svg>
)

export default SvgGoogle;
