import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../store';
import { HeaderWrap, MuiAppBar } from './style';
import HeaderToolbar from './HeaderToolbar';

// import HeaderLogoutButton from './HeaderLogoutButton';
/* eslint-disable @typescript-eslint/no-unused-vars */
// observer로 userCount를 관찰 하기 위해 import 하였으나, 사용되지 않으므로 eslint 오류 발생.
// 추후 다른 문법을 사용해볼 것

export default observer(() => {
  const {
    appStore: {
      onChangeServer,
      appState: { server },
      setLocation,
      menuInit,
    }
  } = useStore();

  const location = useLocation();

  useEffect(() => {
    setLocation(location);
    menuInit();
},[setLocation,location,menuInit]);

  const handleChange = (event: any) => {
    const value = event.target.value 
      ? event.target.value 
      : event.currentTarget.value;
    onChangeServer(value);
  };

  return (
    <HeaderWrap 
      sx={{ flexGrow: 1 }}>
      <MuiAppBar>
        <HeaderToolbar 
          server={server} 
          locationProps={location}
          handleChange={ handleChange } />
      </MuiAppBar>
    </HeaderWrap>
  );
});
