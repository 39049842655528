import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgFavorite = (props: Props) => (
  <svg {...props} viewBox="0 0 13 12">
    <defs>
      <path
        id="zhx1m16fba"
        d="M6.272 2.447c1.053-1.096 2.766-1.096 3.82 0 1.014 1.057 1.043 2.747.086 3.84l-.087.095-3.655 3.807c-.114.117-.27.186-.436.186-.096 0-.188-.023-.27-.064l-.014-.008h-.042L1.908 6.382C.878 5.308.863 3.578 1.873 2.485 2.887 1.39 4.6 1.373 5.683 2.404l.089.088L6 2.73l.272-.283zm3.201.444c-.735-.688-1.848-.688-2.583 0l-.077.076-.38.396c-.222.23-.577.246-.816.046l-.05-.046-.336-.351c-.78-.812-2.062-.823-2.808-.017-.715.773-.73 1.992-.05 2.784l.076.083L6 9.56 9.55 5.862c.741-.771.766-2.011.074-2.814l-.074-.081-.077-.076z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-71.000000, -178.000000) translate(23.500000, 176.000000) translate(48.000000, 2.000000)">
            <path fillRule="nonzero" d="M0 0H12V12H0z" />
            <use fill="#FAFAFA" fillRule="nonzero" xlinkHref="#zhx1m16fba" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgFavorite;
