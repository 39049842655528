import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgClose = (props: Props) => (
  <svg {...props} viewBox="0 0 28 28">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <path
            d="M0 0H28V28H0z"
            transform="translate(-623 -8) translate(623 8)"
          />
          <path
            fill="#FAFAFA"
            d="M21.467 6.535c.466.467.466 1.05.116 1.517l-.116.117-5.717 5.833 5.833 5.717c.467.466.467 1.166 0 1.633-.466.467-1.05.467-1.516.117l-.234-.117L14 15.635 8.167 21.47c-.467.466-1.167.466-1.634 0-.466-.467-.466-1.05-.116-1.517l.116-.117 5.834-5.833-5.834-5.717c-.466-.466-.466-1.166 0-1.633.467-.467 1.05-.467 1.517-.117l.117.117L14 12.37l5.717-5.717c.583-.583 1.283-.583 1.75-.117z"
            transform="translate(-623 -8) translate(623 8)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgClose;
