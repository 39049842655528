import React from 'react';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ExpandIconWrap } from './style';

export default () => {
  return (
    <ExpandIconWrap className="icon">
      <ArrowDropDownIcon />
    </ExpandIconWrap>
  )
}