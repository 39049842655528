import React from 'react';
import { Typography } from '@mui/material';

interface Props {
  title:string;
}

export default ({title} : Props) => (
  <Typography
    component="h5"
    variant="body2"
    style={{
      textAlign: 'center',
      color: '#000',
      fontSize: '16px',
      marginBottom: '20px',
    }}
  >
    {title}
  </Typography>
    
)
