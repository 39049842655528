import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from '../../router';
import GlobalStyle from '../../styles/GlobalStyle';
import { Header } from '../Header';
import { MuiContainer } from './style';

export default () => {
  // const location = window.location;
  const { pathname } = window.location;
  return (
    <>
      <GlobalStyle pathname={pathname}/>
      <MuiContainer>
        <BrowserRouter>
          <Header />
          <AppRouter />
        </BrowserRouter>
      </MuiContainer>
    </>
  );
};

