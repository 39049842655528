// import React, { useEffect } from 'react';
// import { Route, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import { checkToken, checkAuth } from '../utils/nauth';
import { checkToken } from '../utils/nauth';
// import { Home } from '../pages/Home';

export const PrivateRoute = ({ children }: any) => {
  const { search } = useLocation();
  // eslint-disable-next-line
  const query = new URLSearchParams(search);
  useEffect(() => {
    if (!localStorage.getItem('role')) {
      checkToken(query.get('ntoken'));
    }
    
  }, [query]);
  return children;
};

export default PrivateRoute;
