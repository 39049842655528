import React from 'react';
import {
  AccordionDetail,
  AccordionDetailContent,
  AccordionDetailName,
  AccordionDetailDesc,
  LinkAccordionDetail,
  IconWrap,
} from './style';
import { AccordionProps } from './type';
import { Icon } from '../Icon';

export default ({ contents, server }: AccordionProps) => {
  return (
    <>
      {contents.map((detail) => {
        const { popup, name, icon, description } = detail;
        const className = detail[server] ? '' : 'none-link';
        const href = detail[server] ? detail[server] : '#';
        const target = detail[server] ? '_blank' : '_self';
        const to = detail[server] ? detail[server] : '/';
        const disabledHandler = (event: React.MouseEvent) => {
          if (!detail[server]) {
            event.preventDefault();
          }
        };
        if (popup) {
          return (
            <AccordionDetail
              key={name}
              className={className}
              href={href}
              target={target}
              onClick={disabledHandler}
            >
              <IconWrap>
                <Icon name={icon} size={name === 'Sections' ? 42 : 52} />
              </IconWrap>
              <AccordionDetailContent>
                <AccordionDetailName>{name}</AccordionDetailName>
                <AccordionDetailDesc>{description}</AccordionDetailDesc>
              </AccordionDetailContent>
            </AccordionDetail>
          );
        } else {
          return (
            <LinkAccordionDetail key={name} className={className} to={to}>
              <IconWrap>
                <Icon name={icon} size={name === 'Sections' ? 42 : 52} />
              </IconWrap>
              <AccordionDetailContent>
                <AccordionDetailName>{name}</AccordionDetailName>
                <AccordionDetailDesc>{description}</AccordionDetailDesc>
              </AccordionDetailContent>
            </LinkAccordionDetail>
          );
        }
      })}
    </>
  );
};
