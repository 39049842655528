import React from 'react';
import { Box, Typography } from './styles';

export interface ProcessingProps {
  size: number;
  color: string;
  label?: string;
}

const Processing = ({ size = 40, color = "#6750A4", label } : ProcessingProps) => {
  return (
    <Box size={size} color={color}>
      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
      <Typography>{label}</Typography>
    </Box>
  );
};

export default Processing;
