import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

export const DashboardContainer = styled.div`
  margin-top:90px;
  display:grid;

  @media screen and (min-width: 1920px) {
    width: 100%;
    max-width: 1920px;
    grid-template-columns: repeat(4, 1fr);
    padding-left: var(--dashboard-container-padding);
    padding-right: var(--dashboard-container-padding);
    padding-bottom: 20px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    width: calc(100vw - 312px);
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 20px;
  }

  @media screen and (min-width: 1024px) and (max-width:1440px){
    width:100vw;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
  }

  @media screen and (min-width: 640px) and (max-width: 1024px){
    width:100vw;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 640px) {
    width:100vw;
    grid-template-columns: repeat(1, 1fr);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  
  row-gap:16px;
  column-gap:16px;
  
`;

export const DashboardContent = styled.div`
  height: 92px;
  background: #FFFFFF;
  border-radius: 11px;
  border: 1px solid #EADDFF;
`;

export const DashboardTitle = styled(Typography)`
  display:flex;
  justify-content:flex-start;
  align-items:center;
  height:32px;
  padding-left: 16px;
  padding-right: 16px;
  background-color:#EADDFF;
  border-radius: 8px 8px 0px 0px;
  color: #6750A4;
  font-size:12px !important;
  font-weight:500 !important;
  letter-spacing: 0.703819px;
`;

export const DashboardCount = styled(Typography)`
  display:flex;
  justify-content:flex-end;
  align-items:center;
  height:60px;
  padding-right:16px;
  padding-left:16px;
  font-size: 22px !important;
  line-height: 34px;
  font-family: 'Roboto';
  letter-spacing: 0.140764px;
  font-style: normal;
  font-weight: 500 !important;
  color:#1C1B1F;

  &.progress {
    justify-content:center;
  }
`;

export const ProgressImage = styled(CircularProgress)`
  width: 28px !important;
  height: 28px !important;
  color: #939094 !important;
`