import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgArrowDown = (props: Props) => (
  <svg {...props} viewBox="0 0 28 28">
    <defs>
      <path
        id="c6u8zp4o5a"
        d="M9.034 14.766c-.467-.466-.467-1.05-.117-1.516l.117-.117 8.283-8.283c.467-.467 1.167-.467 1.633 0 .467.466.467 1.05.117 1.516l-.117.117-7.466 7.467 7.466 7.466c.467.467.467 1.05.117 1.517l-.117.117c-.466.466-1.05.466-1.516.116l-.117-.116-8.283-8.284z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-16 -8) translate(16 8)">
          <path fillRule="nonzero" d="M0 0H28V28H0z" />
          <use
            fill="#FAFAFA"
            fillRule="nonzero"
            transform="matrix(0 -1 -1 0 27.95 27.95)"
            xlinkHref="#c6u8zp4o5a"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgArrowDown;
