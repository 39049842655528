import React from 'react';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { 
  MuiTitleTypography, HeaderBlank, 
  HeaderToolbar, HeaderTitle } from './style';
import { ToolbarProps } from './type';
import { IndexModal } from '../Modal';
import HeaderServer from './HeaderServer';
import HeaderLogoutButton from './HeaderLogoutButton';

export default observer(({server, handleChange, locationProps } : ToolbarProps) => {
  
  return (
    <HeaderToolbar>
      <IndexModal 
        server={server} 
        menuHandler={handleChange} 
        locationProps={locationProps}
      />
      <HeaderTitle>
        <MuiTitleTypography variant="h5">
          <NavLink to="/">KineMaster Console</NavLink>
        </MuiTitleTypography>
        <HeaderServer
          server={server} 
          handleChange={handleChange} 
          locationProps={locationProps}
        />
      </HeaderTitle>
      <HeaderBlank />
      {/* Nauth 사용시 로그아웃 UI */}
      {localStorage.getItem('ntoken') && <HeaderLogoutButton /> }
    </HeaderToolbar>
  );
});
