import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgAssetStore = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M73.6199 77.9999H23.2999C21.6999 77.9999 20.3999 76.6999 20.3999 75.0999V41.6299H76.5199V75.0999C76.5199 76.6999 75.2199 77.9999 73.6199 77.9999Z"
      fill="#9DD0FF"
    />
    <path
      d="M73.6199 76.9999H23.2999C22.2522 76.9999 21.3999 76.1476 21.3999 75.0999V42.6299H75.5199V75.0999C75.5199 76.1476 74.6676 76.9999 73.6199 76.9999Z"
      stroke="url(#paint0_linear_51736_10489)"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <path
      d="M20.3999 38.01V51.98C21.1499 52.15 21.9199 52.26 22.7199 52.26C26.8399 52.26 30.4699 49.73 31.9699 45.94C32.3799 46.96 32.9099 47.93 33.6399 48.78C35.5599 50.99 38.3399 52.26 41.2699 52.26C44.1999 52.26 46.6299 51.1 48.4599 49.23C50.2899 51.1 52.8399 52.26 55.6499 52.26C58.4599 52.26 61.3599 50.99 63.2799 48.78C64.0099 47.93 64.5399 46.96 64.9499 45.94C66.4499 49.73 70.0899 52.26 74.1999 52.26C74.9999 52.26 75.7699 52.16 76.5199 51.98V38H20.3999V38.01Z"
      fill="#208CEE"
      fillOpacity="0.8"
    />
    <path
      d="M20.8999 51.5744V38.5H76.0199V51.578C75.4274 51.6961 74.8227 51.76 74.1999 51.76C70.2988 51.76 66.8407 49.3588 65.4148 45.756L64.9535 44.5904L64.486 45.7535C64.0905 46.7373 63.5862 47.6557 62.9013 48.4533C61.0782 50.5511 58.3175 51.76 55.6499 51.76C52.9802 51.76 50.5574 50.6584 48.8173 48.8803L48.4599 48.5151L48.1025 48.8803C46.3601 50.6608 44.0564 51.76 41.2699 51.76C38.4854 51.76 35.8435 50.5534 34.0183 48.4532C33.3335 47.6555 32.8292 46.7372 32.4338 45.7535L31.9663 44.5904L31.505 45.756C30.079 49.3591 26.6307 51.76 22.7199 51.76C22.1009 51.76 21.4968 51.6902 20.8999 51.5744Z"
      stroke="url(#paint1_linear_51736_10489)"
      strokeOpacity="0.1"
    />
    <g filter="">
      <path
        d="M61.04 66.3898H35.88C34.28 66.3898 32.98 65.0898 32.98 63.4898C32.98 61.8898 34.28 60.5898 35.88 60.5898H61.04C62.64 60.5898 63.94 61.8898 63.94 63.4898C63.94 65.0898 62.64 66.3898 61.04 66.3898Z"
        fill="url(#paint2_linear_51736_10489)"
        shapeRendering="geometricPrecision"
      />
      <path
        d="M61.04 65.8898H35.88C34.5561 65.8898 33.48 64.8137 33.48 63.4898C33.48 62.166 34.5561 61.0898 35.88 61.0898H61.04C62.3638 61.0898 63.44 62.166 63.44 63.4898C63.44 64.8137 62.3638 65.8898 61.04 65.8898Z"
        stroke="url(#paint3_linear_51736_10489)"
        strokeOpacity="0.1"
        strokeLinecap="round"
        shapeRendering="geometricPrecision"
      />
    </g>
    <path
      d="M82.2402 34.97L72.4802 19.78C71.7702 18.67 70.5402 18 69.2102 18H27.6902C26.3702 18 25.1402 18.67 24.4202 19.79L14.6702 34.98C12.7702 37.94 12.3502 43.04 14.1502 46.06C15.9502 49.08 19.1402 50.91 22.7102 50.91C26.8302 50.91 30.4602 48.38 31.9602 44.59C32.3702 45.61 32.9002 46.58 33.6302 47.43C35.5502 49.64 38.3302 50.91 41.2602 50.91C44.1902 50.91 46.6202 49.75 48.4502 47.88C50.2802 49.75 52.8302 50.91 55.6402 50.91C58.4502 50.91 61.3502 49.64 63.2702 47.43C64.0102 46.58 64.5302 45.61 64.9402 44.59C66.4402 48.38 70.0802 50.91 74.1902 50.91C77.7602 50.91 80.9302 49.11 82.7502 46.06C84.5702 43.01 84.1402 37.94 82.2302 34.98L82.2402 34.97Z"
      fill="#5AAAF3"
    />
    <path
      d="M71.6389 20.3206L71.6378 20.3188C71.1134 19.4991 70.2026 19 69.2102 19H27.6902C26.7115 19 25.7983 19.496 25.2617 20.3302L71.6389 20.3206ZM71.6389 20.3206L80.9634 34.8329L80.9523 34.844L81.3899 35.5222C82.2332 36.829 82.7799 38.6612 82.8907 40.545C83.0018 42.4334 82.668 44.2462 81.8915 45.5476C80.2524 48.2943 77.4091 49.91 74.1902 49.91C70.498 49.91 67.2218 47.6376 65.87 44.222L64.9474 41.8908L64.0123 44.217C63.6289 45.1709 63.1602 46.0334 62.516 46.7734L62.5153 46.7742C60.789 48.7612 58.1665 49.91 55.6402 49.91C53.1108 49.91 50.8151 48.8669 49.1649 47.1806L48.4502 46.4502L47.7355 47.1806C46.0806 48.8716 43.9032 49.91 41.2602 49.91C38.6212 49.91 36.1173 48.7667 34.387 46.7763C33.7474 46.0311 33.2688 45.1644 32.888 44.217L31.953 41.8908L31.0304 44.222C29.6783 47.6382 26.4118 49.91 22.7102 49.91C19.4929 49.91 16.629 48.2658 15.0092 45.548C14.2433 44.263 13.9109 42.4506 14.021 40.5555C14.1308 38.6657 14.6735 36.8261 15.5117 35.5202L15.5117 35.5202L25.2614 20.3308L71.6389 20.3206Z"
      stroke="url(#paint4_linear_51736_10489)"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <defs>
      <filter
        id="filter0_di_51736_10489"
        x="32.98"
        y="60.5898"
        width="30.96"
        height="6.7998"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10489"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10489"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10489"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51736_10489"
        x1="48.4599"
        y1="41.6299"
        x2="48.4599"
        y2="77.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_51736_10489"
        x1="48.4599"
        y1="38"
        x2="48.4599"
        y2="52.26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_51736_10489"
        x1="48.46"
        y1="60.5898"
        x2="48.46"
        y2="66.3898"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_51736_10489"
        x1="48.46"
        y1="60.5898"
        x2="48.46"
        y2="66.3898"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_51736_10489"
        x1="48.456"
        y1="18"
        x2="48.456"
        y2="50.91"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgAssetStore;
