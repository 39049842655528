/* eslint-disable react/self-closing-comp */
import { makeObservable, observable, action } from "mobx";
import { Location } from "react-router";

interface IAppState {
  server: any;
}

export interface IAppStore {
  appState: IAppState;
}

export default class AppStore {
  constructor() {
    makeObservable(this, {
      appState: observable,
    });
  }

  appState: IAppState = {
    server: "product",
  };

  location : Location = {
    state:'',
    key:'',
    pathname:'',
    search:'',
    hash:''
  };

  setLocation = (location : Location) => {
    this.location = location;
  }

  onChangeServer = action((val: any) => {
    if(val){
      this.appState.server = val;
    }
  });

  menuInit = () => {

    return process.env.REACT_APP_SEVER_NAME === 'production'
      ? this.onChangeServer('product')
      : this.onChangeServer('test');
  };
}
