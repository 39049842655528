import styled from 'styled-components';
import { Container, Box, Button, InputLabel, Select, MenuItem } from '@mui/material';

export const FaqContainer = styled(Container)`
  display:block;
  width:100%;
  padding-top:120px;
  height:auto;
`;

export const FaqSelectWrap = styled(Box)`
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const FaqSelectBox = styled(Box)`
  
`;

export const FaqSelectLabel = styled(InputLabel)`
  margin-bottom:16px;
`;

export const FaqSelect = styled(Select)`
  width:200px;
  margin-bottom:32px;
`;

export const FaqSelectItem = styled(MenuItem)`

`;

export const FaqSearchButton = styled(Button)`
  &.MuiButtonBase-root{
    text-transform:none !important;
    background-color: #6750A4;
    color: white;
    font-weight:700;
    // pointer-events:none;
  }
  &.MuiButtonBase-root:hover{
    // background: none !important;
    background-color : #6750A4 !important;
  }
`;

export const FaqModalContents = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: none;
  padding: 24px;
`;

export const FaqModalConfirmButton = styled(Button)`
  &.MuiButtonBase-root{
    text-transform:none !important;
    background-color: transparent !important;
    color: #6750A4;
    // width:100px;
    font-weight:700;
    // pointer-events:none;
  }
  &.MuiButtonBase-root:hover{
    // background: none !important;
    background-color : transparent !important;
  }
`;