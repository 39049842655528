/* eslint-disable react/self-closing-comp */
import { createContext, useContext } from "react";
import AppStore from "./AppStore";
import ReceiptStore from './ReceiptStore';
import DashboardStore from './DashboardStore';
import StatusStore from './StatusStore';
import TemplatesStore from './TemplatesStore';

export const store = {
  appStore: new AppStore(),
  receiptStore: new ReceiptStore(),
  dashboardStore: new DashboardStore(),
  statusStore: new StatusStore(),
  templatesStore: new TemplatesStore(),
};

export const StoreContext = createContext(store);
export const useStore = () => useContext(StoreContext);
