import React from 'react';
import { Icon } from '../Icon';
import { AccordionProps } from './type';
import {
  SingleAccordion,
  SingleAccordionContent,
  SingleAccordionName,
  SingleAccordionDesc,
  IconWrap,
} from './style';

export default ({ contents, server }: AccordionProps) => {
  const detail = contents[0];
  const { icon, name, description }: any = detail;

  const className = detail[server] ? '' : 'none-link';
  const href = detail[server] ? detail[server] : '#a';
  const target = detail[server] ? '_blank' : '_self';
  const disabledHandler = (event: React.MouseEvent) => {
    if (!detail[server]) {
      event.preventDefault();
    }
  };
  return (
    <SingleAccordion
      className={className}
      href={href}
      target={target}
      onClick={disabledHandler}
    >
      <IconWrap>
        <Icon name={icon} size={52} />
      </IconWrap>
      <SingleAccordionContent>
        <SingleAccordionName>{name}</SingleAccordionName>
        <SingleAccordionDesc>{description}</SingleAccordionDesc>
      </SingleAccordionContent>
    </SingleAccordion>
  );
};
