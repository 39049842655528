import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { IconButton } from '@mui/material';
import {
  MuiIconButton,
  SearchInput,
  SearchLabel,
  InputButtonWrap,
} from './style';
import { Icon } from '../../components/Icon';
import { useStore } from '../../store';

const SearchFormLabel = observer(() =>{
  const { 
    receiptStore:{
      searchData : {
        inputRef,
        searchValue
      },
      setSearchData : {
        setInputRef,
      },
      onClick,
      onChange,
      onSubmit,
      visibility
    } 
  } = useStore();

  const submitHandler = (e: React.FormEvent) => {
    onSubmit(e);
  }

  const defaultInput = useRef<HTMLInputElement>(null);

  useEffect(()=>{
    setInputRef(defaultInput);
  },[setInputRef]);

  return (
    <SearchLabel htmlFor="commentSearch">
      <SearchInput
        placeholder="search"
        ref={inputRef}
        onChange={onChange}
        value={searchValue}
      />
      <InputButtonWrap>
        <IconButton
          style={{visibility}}
          size="small"
          color="secondary"
          onClick={onClick}
        >
          <Icon
            name="inputDelete"
            size={26}
            color="#79747E"
            opacity={1}
          />
        </IconButton>
        <MuiIconButton onClick={submitHandler}>
          <Icon name="consoleSearch" size={38} />
        </MuiIconButton>
      </InputButtonWrap>
    </SearchLabel>
  );
});

export default SearchFormLabel;
