import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgReceiptIos = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_51736_10034)">
      <path
        d="M66.5 12.5H19.5C16.1863 12.5 13.5 15.1863 13.5 18.5C13.5 21.8137 16.1863 24.5 19.5 24.5H66.5C69.8137 24.5 72.5 21.8137 72.5 18.5C72.5 15.1863 69.8137 12.5 66.5 12.5Z"
        fill="#BAA675"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 14C17.0147 14 15 16.0147 15 18.5C15 20.9853 17.0147 23 19.5 23H66.5C68.9853 23 71 20.9853 71 18.5C71 16.0147 68.9853 14 66.5 14H19.5ZM12 18.5C12 14.3579 15.3579 11 19.5 11H66.5C70.6421 11 74 14.3579 74 18.5C74 22.6421 70.6421 26 66.5 26H19.5C15.3579 26 12 22.6421 12 18.5Z"
        fill="#E3C993"
      />
      <path
        d="M64.9102 17.71H20.9102C19.8056 17.71 18.9102 18.6054 18.9102 19.71V75.71C18.9102 76.8145 19.8056 77.71 20.9102 77.71H64.9102C66.0147 77.71 66.9102 76.8145 66.9102 75.71V19.71C66.9102 18.6054 66.0147 17.71 64.9102 17.71Z"
        fill="#E9E1BB"
      />
      <path
        d="M20.9102 18.71H64.9102C65.4624 18.71 65.9102 19.1577 65.9102 19.71V75.71C65.9102 76.2622 65.4624 76.71 64.9102 76.71H20.9102C20.3579 76.71 19.9102 76.2622 19.9102 75.71V19.71C19.9102 19.1577 20.3579 18.71 20.9102 18.71Z"
        stroke="url(#paint0_linear_51736_10034)"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g filter="">
        <path
          d="M56.9102 33H28.9102C27.8056 33 26.9102 33.8954 26.9102 35C26.9102 36.1046 27.8056 37 28.9102 37H56.9102C58.0147 37 58.9102 36.1046 58.9102 35C58.9102 33.8954 58.0147 33 56.9102 33Z"
          fill="url(#paint1_linear_51736_10034)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M28.9102 33.5H56.9102C57.7386 33.5 58.4102 34.1716 58.4102 35C58.4102 35.8284 57.7386 36.5 56.9102 36.5H28.9102C28.0817 36.5 27.4102 35.8284 27.4102 35C27.4102 34.1716 28.0817 33.5 28.9102 33.5Z"
          stroke="url(#paint2_linear_51736_10034)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <g filter="">
        <path
          d="M57 45H29C27.8954 45 27 45.8954 27 47C27 48.1046 27.8954 49 29 49H57C58.1046 49 59 48.1046 59 47C59 45.8954 58.1046 45 57 45Z"
          fill="url(#paint3_linear_51736_10034)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M29 45.5H57C57.8284 45.5 58.5 46.1716 58.5 47C58.5 47.8284 57.8284 48.5 57 48.5H29C28.1716 48.5 27.5 47.8284 27.5 47C27.5 46.1716 28.1716 45.5 29 45.5Z"
          stroke="url(#paint4_linear_51736_10034)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <g filter="">
        <path
          d="M57 57H29C27.8954 57 27 57.8954 27 59C27 60.1046 27.8954 61 29 61H57C58.1046 61 59 60.1046 59 59C59 57.8954 58.1046 57 57 57Z"
          fill="url(#paint5_linear_51736_10034)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M29 57.5H57C57.8284 57.5 58.5 58.1716 58.5 59C58.5 59.8284 57.8284 60.5 57 60.5H29C28.1716 60.5 27.5 59.8284 27.5 59C27.5 58.1716 28.1716 57.5 29 57.5Z"
          stroke="url(#paint6_linear_51736_10034)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <path
        d="M67.5601 85.4502C76.3966 85.4502 83.5601 78.2868 83.5601 69.4502C83.5601 60.6136 76.3966 53.4502 67.5601 53.4502C58.7235 53.4502 51.5601 60.6136 51.5601 69.4502C51.5601 78.2868 58.7235 85.4502 67.5601 85.4502Z"
        fill="black"
      />
      <path
        d="M82.5601 69.4502C82.5601 77.7345 75.8443 84.4502 67.5601 84.4502C59.2758 84.4502 52.5601 77.7345 52.5601 69.4502C52.5601 61.1659 59.2758 54.4502 67.5601 54.4502C75.8443 54.4502 82.5601 61.1659 82.5601 69.4502Z"
        stroke="url(#paint7_linear_51736_10034)"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g filter="">
        <path
          d="M70.0998 62.6704C70.7298 61.9204 71.0798 60.9904 71.0998 60.0104C71.0998 59.8804 71.0898 59.7504 71.0598 59.6304C69.9798 59.7304 68.9798 60.2504 68.2698 61.0704C67.6398 61.7804 67.2698 62.6904 67.2298 63.6404C67.2298 63.7504 67.2398 63.8704 67.2698 63.9804C67.3598 64.0004 67.4398 64.0004 67.5298 64.0004C68.5398 63.9204 69.4598 63.4304 70.1098 62.6504L70.0998 62.6704ZM70.7698 64.2304C69.3398 64.2304 68.1698 65.1004 67.4298 65.1004C66.6298 65.1004 65.5898 64.2804 64.3398 64.2804C61.9798 64.2804 59.5698 66.2404 59.5698 69.9304C59.6098 72.1804 60.2998 74.3604 61.5498 76.2304C62.4898 77.5504 63.3098 78.6404 64.4998 78.6404C65.6898 78.6404 66.1898 77.8604 67.6498 77.8604C69.1098 77.8604 69.4598 78.6204 70.7598 78.6204C72.0598 78.6204 72.8998 77.4404 73.7098 76.2804C74.2698 75.4504 74.7098 74.5404 75.0098 73.5904C73.4698 72.9304 72.4798 71.4204 72.4698 69.7404C72.4798 68.2604 73.2598 66.9004 74.5198 66.1304C73.6598 64.9104 72.2498 64.2004 70.7598 64.2404L70.7698 64.2304Z"
          fill="url(#paint8_linear_51736_10034)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M70.4218 64.7496L70.7732 64.7402C71.9264 64.7092 73.0262 65.1845 73.7954 66.0258C72.6641 66.9142 71.9797 68.2722 71.9698 69.737L71.9698 69.737L71.9698 69.7433C71.9801 71.4632 72.9107 73.0275 74.3949 73.8458C74.1192 74.6019 73.7495 75.327 73.2975 75.9975C72.8873 76.5849 72.5052 77.1225 72.0783 77.519C71.6603 77.9072 71.2411 78.1204 70.7598 78.1204C70.2345 78.1204 69.9096 77.9776 69.4899 77.7931L69.4659 77.7826C69.0144 77.5843 68.4851 77.3604 67.6498 77.3604C66.8166 77.3604 66.2509 77.5881 65.7771 77.7906C65.7603 77.7978 65.7437 77.8049 65.7272 77.8119C65.2838 78.0017 64.9598 78.1404 64.4998 78.1404C64.0759 78.1404 63.6893 77.952 63.2713 77.5639C62.8451 77.1683 62.4349 76.6112 61.9614 75.9464C60.7675 74.1579 60.1089 72.075 60.0698 69.9259C60.0717 66.4873 62.2839 64.7804 64.3398 64.7804C64.8613 64.7804 65.356 64.9514 65.869 65.1551C65.9213 65.1758 65.9746 65.1973 66.0285 65.2189C66.2265 65.2985 66.4325 65.3814 66.6265 65.4464C66.8751 65.5296 67.1501 65.6004 67.4298 65.6004C67.7016 65.6004 67.9785 65.5225 68.2261 65.437C68.3956 65.3785 68.5834 65.3042 68.7696 65.2305C68.8544 65.197 68.9388 65.1636 69.021 65.1319C69.4694 64.9589 69.9335 64.8027 70.4218 64.7496ZM68.6478 61.3977C69.1592 60.8071 69.8403 60.3937 70.5896 60.2137C70.5301 60.9936 70.2328 61.7281 69.7301 62.3331L69.7257 62.3303C69.214 62.9443 68.5145 63.3483 67.7424 63.4718C67.8153 62.7065 68.1318 61.9792 68.6438 61.4022L68.6438 61.4022L68.6478 61.3977Z"
          stroke="url(#paint9_linear_51736_10034)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_di_51736_10034"
        x="26.9102%"
        y="33%"
        width="32%"
        height="5%"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10034"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10034"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10034"
        />
      </filter>
      <filter
        id="filter1_di_51736_10034"
        x="27"
        y="45"
        width="32"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10034"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10034"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10034"
        />
      </filter>
      <filter
        id="filter2_di_51736_10034"
        x="27"
        y="57"
        width="32"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10034"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10034"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10034"
        />
      </filter>
      <filter
        id="filter3_di_51736_10034"
        x="59.5698"
        y="59.6304"
        width="15.4399"
        height="20.01"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10034"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10034"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10034"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51736_10034"
        x1="42.9102"
        y1="17.71"
        x2="42.9102"
        y2="77.71"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_51736_10034"
        x1="42.9102"
        y1="33"
        x2="42.9102"
        y2="37"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_51736_10034"
        x1="42.9102"
        y1="33"
        x2="42.9102"
        y2="37"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_51736_10034"
        x1="43"
        y1="45"
        x2="43"
        y2="49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_51736_10034"
        x1="43"
        y1="45"
        x2="43"
        y2="49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_51736_10034"
        x1="43"
        y1="57"
        x2="43"
        y2="61"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_51736_10034"
        x1="43"
        y1="57"
        x2="43"
        y2="61"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_51736_10034"
        x1="67.5601"
        y1="53.4502"
        x2="67.5601"
        y2="85.4502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_51736_10034"
        x1="67.2898"
        y1="59.6304"
        x2="67.2898"
        y2="78.6404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_51736_10034"
        x1="67.2898"
        y1="59.6304"
        x2="67.2898"
        y2="78.6404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_51736_10034">
        <rect
          width="71.56"
          height="74.45"
          fill="white"
          transform="translate(12 11)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgReceiptIos;
