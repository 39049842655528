import React from 'react';
import { 
  NoticeSelectWrap, NoticeSelectBox, NoticeSelectLabel, 
  NoticeSelect, NoticeSelectItem } from './style';

export default ({ nameText, objState, selectHandler, settingObject, languageState } : any) => {
  const labelText = nameText.replace(/^[a-z]/, (char : any) => char.toUpperCase());
  return (
    <NoticeSelectWrap>
      <NoticeSelectBox>
        <NoticeSelectLabel>{labelText}</NoticeSelectLabel>
        <NoticeSelect 
          value={objState[nameText]}
          name={nameText}
          onChange={selectHandler}
          disabled={languageState}
        > 
          {settingObject[nameText].map((item : any) => (
            <NoticeSelectItem
              value={item.value}
              key={item.value}
              style={{ zIndex: 1600 }}
            >
              {item.name}
            </NoticeSelectItem>
          ))}
        </NoticeSelect>
      </NoticeSelectBox>
    </NoticeSelectWrap>
  )
}