export default {
  edition: [
    { name: '- Select -', value: '- Select -'},
    { name: 'Android Global', value: '/android' },
    { name: 'iOS Global', value: '/ios' },
    { name: 'Android China', value: '/android/china'},
    { name: 'iOS China', value: '/ios/china' }
  ],
  language: [
    { name: '- Select -', value: '- Select -'},
    { name: "Korean", value: "ko" },
    { name: "English", value: "en" },
    { name: "Arabic", value: "ar" },
    { name: "German", value: "de" },
    { name: "Indonesian", value: "id" },
    { name: "simplified Chinese", value: "zh-cn" },
    { name: "Traditional Chinese", value: "zh-tw" },
    { name: "Spanish; Castilian", value: "es" },
    { name: "Turkish", value: "tr" },
    { name: "French", value: "fr" },
    { name: "Hindi", value: "hi" },
    { name: "Italian", value: "it" },
    { name: "Japanese", value: "ja" },
    { name: "Malay", value: "ms" },
    { name: "Portuguese", value: "pt" },
    { name: "Russian", value: "ru" },
    { name: "Hong Kong", value: "zh-hk" },
    { name: "Vietnamese", value: "vi" },
    { name: "Thai", value: "th" },
  ]
}

// edition: [
//   { name:'iosGlobal', url: 'https://www.kinemaster.com/faq/ios' },
//   { name:'aosGlobal', url: 'https://www.kinemaster.com/faq/android' },
//   { name:'aosChina', url:'https://www.kinemaster.com/faq/android/china' },
//   { name:'iosChina', url:'https://www.kinemaster.com/faq/ios/china' }
// ],