import React, { useState } from 'react';
import { Typography } from '@mui/material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DivisionLine,
  Container,
} from './style';
import { AccordionProps } from './type';
import AccordionIcon from './AccordionIcon';
import AccordionContents from './AccordionContents';

export default ({ title, contents, server }: AccordionProps) => {
  const [expanded, setExpanded] = useState(true);
  const expandedClassName = expanded ? 'customExpended' : '';
  const expandedHandler = () => {
    return expanded ? setExpanded(false) : setExpanded(true);
  };
  return (
    <Container>
      <Accordion className={expandedClassName} expanded={expanded}>
        <AccordionSummary
          expandIcon={<AccordionIcon />}
          aria-controls={title}
          id={title}
          expanded={expanded ? 'expanded' : 'none'}
          onClick={expandedHandler}
        >
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DivisionLine />
          <AccordionContents contents={contents} server={server} />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};
