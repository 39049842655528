import React from "react";
import ContactMailIcon from '@mui/icons-material/ContactMail';

interface Props {
  width: number;
  height: number;
}

const SvgPurchase = () => (
  <ContactMailIcon fontSize="large" sx={{ color: '#9A82DB' }} />
);

export default SvgPurchase;