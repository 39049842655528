import styled from 'styled-components';
import { Container, Paper, MenuList, MenuItem, Box, Typography } from '@mui/material';

export const StatusContainer = styled(Container)`
  &.MuiContainer-root {
    display:flex;
    justify-content:flex-start;
    width: 100vw;
    height: 100vh;
    // min-width: 1920px;
    // min-height: 1080px;
    background-color: #FFFFFF;
    padding: 0;
    border: none;
    max-width:none;
    overflow: hidden;
    touch-action: none;
  }
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    width: 255px;
    height: calc(100% - 64px);
    padding: 64px 0 0 0;
    border: none;
    box-shadow: none;
  }
`;

export const StyledMenuList = styled(MenuList)`
  &.MuiList-root {
    width: 100%;
    height: calc(100% - 12px);
    padding: 12px 0 0 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)<{pathname : string, id: string}>`
  &.MuiButtonBase-root {
    width: 100%;
    height: 72px;
    // background-color: #FFFBFE;
    background: ${(props) => props.pathname.includes(props.id) && !props.pathname.includes('detail')  
      ? 'linear-gradient(0deg, rgba(103, 80, 164, 0.05), rgba(103, 80, 164, 0.05)), rgb(255, 251, 254)' 
      : 'none'} !important;
    border-radius: 0px 40px 40px 0px;
  }
`;

export const StyledIconBox = styled(Box)`
  // margin-left: 16px;
  width:24px;
  height:24px;
`;

export const StyledTextBox = styled(Box)`
  display:block;
  width:auto;
  margin-left: 36px;
`;

export const StyledTypography = styled(Typography)`
  &.MuiTypography-root{
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #6750A4;
    padding:0;
  }
`;

export const StyledContents = styled(Box)`
  &.MuiBox-root {
    width: calc(100% - 400px);
    height: calc(100% - 64px);
    // background-color: skyblue;
    padding: 64px 96px 0 48px;
    border: none;
    max-width:1520px;
    min-width:840px;
    overflow:scroll;
  }
`;

export const StyledContentsTitle = styled(Typography)`
  &.MuiTypography-root{
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    margin-top: 28px;
    padding: 0;
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  }
`;

export const StyledDatePickerBox = styled(Box)`
  &.MuiBox-root {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    border: none;
    max-width:none;
  }

  & div:first-child {
    margin-right: 10px;
  }

  & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
    transition:none !important;
    border: none !important;
    background-color:white;
  }

`;

export const StyledDataGridBox = styled(Box)`
  // & .MuiDataGrid-menu{
  //   color:red;
  //   background-color:red;
  // }
`;

export const HistoryDetailWrap = styled.section`
  dl {
    display: table;
    font-size: 12px;
    dt {
      display: table-cell;
      width: 80px;
      font-weight: bold;
    }
    dd {
      display: table-cell;
      padding-left: 8px;
    }
  }
`;

export const StyledDetailBox = styled(Box)`
  &.MuiBox-root {
    padding-top:24px;
    padding-bottom:24px;
    overflow:scroll;
  }
`;

export const StyledDetailRows = styled(Box)`
  &.MuiBox-root {
    display:flex;
    justify-content:flex-start;
    align-items:center;
    width:100%;
  }
  &.success, &.fail {
    align-items: flex-start;
    padding-top:20px;
  }
`;

export const StyledDetailTableHead = styled(Box)`
  &.MuiBox-root {
    display:flex;
    justify-content:flex-start;
    align-items:center;
    width:180px;
    height:52px;
    margin-left:23px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  &.success, &.fail {
    align-items:flex-start !important;
  }
`

export const StyledDetailTableCell = styled(Box)`
  &.MuiBox-root {
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    width:1260px;
    height:auto;
    min-height:52px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    flex-wrap:wrap;
  }
  
`
export const StyledAnchorBox = styled(Box)`
  margin:0 4px 0 0;
  padding:0;
  flex-wrap:wrap;
`;

export const StyledAnchorText = styled.a`
  &:link, &:visited { 
    color: #6750A4; 
  }

  &:hover , &:active { 
    color: #6750A4;
    text-decoration:underline;
  }
`;
