import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store';
import { MainContainer } from '../Home/style';
import { 
  DashboardContainer, DashboardContent, 
  DashboardTitle, DashboardCount, ProgressImage
} from './style';

/* eslint-disable @typescript-eslint/no-unused-vars */

export default observer(() => {
  const {
    templatesStore : {
      sectionLabels,
      sectionValue,
      userCount,
      userUpdate,
      isLoading,
    }
  } = useStore();

  useEffect(() => {
    userUpdate();
  },[userUpdate]);

  return(
    <MainContainer>
      <DashboardContainer>
        {sectionLabels.map((label:any) => (
          <DashboardContent key={label}>
            <DashboardTitle>{label}</DashboardTitle>
            {!isLoading && (
              <DashboardCount>{sectionValue(label)}</DashboardCount>
            )}
            {isLoading && (
              <DashboardCount className="progress">
                <ProgressImage />
              </DashboardCount>
            )}
          </DashboardContent>
        ))}
      </DashboardContainer>
    </MainContainer>
  )
});