import styled from 'styled-components';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Typography, Box, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const MenuButton = styled(DragHandleIcon)`
  @media screen and (min-width: 600px) {
    display:none !important;
  }
  margin-right:5px;
`;

export const MenuContainer = styled(Box)`
  background-color: #FFFBFE;
  width: 85vw;
  height: calc(100vh - 12px);
  padding: 12px 12px 0 12px;
  border: none !important;
`;

export const MenuTitle = styled(Box)`
  width:calc(100% - 32px);
  height:56px;
  padding-left:16px;
  padding-right:16px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  color:white;
`;

export const MenuTitleText = styled(Typography)`
  color:black !important; 
  font-size:16px !important;
`;

export const MenuContents = styled(Box)`
  
`;

export const MenuBlank = styled.div`
  width:22px;
  background-color:transparent; 
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const MenuContent = styled(Button)<{server?:string, value?:string}>`
  width:100% !important;
  padding: 18px 24px !important;
  background-color: ${(props) => props.server && props.server===props.value ? '#E8DEF8' : 'transparent'} !important;
  display:flex !important;
  justify-content:space-between !important;
  border-radius: 100px !important;
`;

export const MenuName = styled(Typography)`
  color:black;
  font-size: 14px !important;
  font-weight: 700 !important;
  font-family: 'Roboto' !important;
  text-transform: none !important;
`;

export const MenuCheckIcon = styled(CheckIcon)<{server?:string, value?:string}>`
  color:${(props) => props.server && props.server===props.value ? '#95A4EF' : 'transparent'};
`;