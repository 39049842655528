import { makeObservable, observable, action } from 'mobx';
import dayjs from 'dayjs';

export default class StatusStore {
  constructor() {
    makeObservable(this, {
      publishingStatus: observable,
    })
  }

  publishingStatus = {
    historyId: 0,
    serviceName: "",
    ipAddress: "",
    user: "",
    details: {
        action: "",
        fail: 0,
        failed: [],
        startAt: "",
        succeed: [],
        success: 0,
        total: 0
    },
    createdAt: "",
    prevPageNo:0,
  }

  setStatus = action((row : any) => {
    this.publishingStatus = row;
  })

  get getStatus() {
    const obj = {...this.publishingStatus};
    obj.details.startAt = `${dayjs(obj.details.startAt).format("YYYY-MM-DD HH:mm")} (UTC)`;
    obj.createdAt = `${dayjs(obj.createdAt).utc().format("YYYY-MM-DD HH:mm")} (UTC)`;
    return obj;
  }
}
