import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Icon } from '../../components/Icon';
import { 
  StyledPaper, StyledMenuList, StyledMenuItem, 
  StyledIconBox, StyledTypography, StyledTextBox
} from './style';

export default () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const menuClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    if(pathname.includes('detail')){
      navigate(-1);
    }else{
      const path = event.currentTarget.id;
      if(pathname.includes('test')){
        navigate(`${path}/test`);
      }
      if(pathname.includes('product')){
        navigate(`${path}/product`);
      }
      
    }
  }
  return (
    <StyledPaper>
      <StyledMenuList>
        <StyledMenuItem id="/status" pathname={pathname} onClick={menuClickHandler}>
          <StyledIconBox>
            <Icon
              name="SvgStatus"
              size={24}
              color="#79747E"
              opacity={1}
            />
          </StyledIconBox>
          <StyledTextBox>
            <StyledTypography>Video Template</StyledTypography>
            <StyledTypography>Publishing Status</StyledTypography>
          </StyledTextBox>
        </StyledMenuItem>
      </StyledMenuList>
    </StyledPaper>
  )
};
