import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgSearch = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="19" fill="#EADDFF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0833 24.3333H25.1617L24.835 24.0183C25.9783 22.6883 26.6667 20.9617 26.6667 19.0833C26.6667 14.895 23.2717 11.5 19.0833 11.5C14.895 11.5 11.5 14.895 11.5 19.0833C11.5 23.2717 14.895 26.6667 19.0833 26.6667C20.9617 26.6667 22.6883 25.9783 24.0183 24.835L24.3333 25.1617V26.0833L30.1667 31.905L31.905 30.1667L26.0833 24.3333ZM19.0833 24.3333C16.1783 24.3333 13.8333 21.9883 13.8333 19.0833C13.8333 16.1783 16.1783 13.8333 19.0833 13.8333C21.9883 13.8333 24.3333 16.1783 24.3333 19.0833C24.3333 21.9883 21.9883 24.3333 19.0833 24.3333Z"
      fill="#21005D"
    />
  </svg>
);

export default SvgSearch;
