import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgSearch = (props: Props) => (
  <svg {...props} viewBox="0 0 28 28">
    <defs>
      <path
        id="9tpzihbrfa"
        d="M12.25 3.792c4.671 0 8.458 3.787 8.458 8.458 0 2.02-.708 3.875-1.89 5.33l5.115 5.095c.343.341.343.895.002 1.237-.31.312-.796.34-1.139.087l-.098-.085-5.118-5.096c-1.454 1.182-3.31 1.89-5.33 1.89-4.671 0-8.458-3.787-8.458-8.458s3.787-8.458 8.458-8.458zm0 1.75c-3.705 0-6.708 3.003-6.708 6.708s3.003 6.708 6.708 6.708 6.708-3.003 6.708-6.708-3.003-6.708-6.708-6.708z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-579 -10) translate(579 10)">
          <path d="M0 0H28V28H0z" />
          <use fill="#FAFAFA" fillRule="nonzero" xlinkHref="#9tpzihbrfa" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgSearch;
