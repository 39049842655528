import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgBlog = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="52" viewBox="0 0 500 500" enableBackground="new 0 0 500 500">
<path fill="#02897B" opacity="1.000000" stroke="none" 
	d="
M85.095398,43.169132 
	C98.188225,47.099960 111.289474,51.002979 124.371803,54.968430 
	C141.975113,60.304253 159.564697,65.685501 177.154160,71.066849 
	C177.605270,71.204872 177.986710,71.570648 178.729919,72.462509 
	C179.043167,148.163803 179.041275,223.232819 178.967789,298.301758 
	C178.964233,301.927002 178.330383,305.551666 177.990051,309.176605 
	C169.449463,316.886536 160.906860,324.594269 152.369171,332.307434 
	C148.200272,336.073730 144.072922,339.886688 139.870392,343.614990 
	C128.486923,353.713837 117.064949,363.769318 105.676796,373.862915 
	C102.349525,376.811920 99.084511,379.831177 95.360924,383.237488 
	C94.825455,384.002319 94.720024,384.347900 94.614586,384.693512 
	C95.100891,384.490173 95.587196,384.286835 96.073502,384.083527 
	C109.047409,377.538422 122.066765,371.081421 134.980301,364.419312 
	C144.659821,359.425598 154.169876,354.104462 163.813812,349.040405 
	C186.031616,337.373749 208.297592,325.798828 230.519882,314.140625 
	C247.175858,305.402649 263.804657,296.612610 280.425446,287.807800 
	C290.452759,282.495819 300.441620,277.111298 311.173157,271.371399 
	C306.396790,268.883179 302.252258,266.467438 297.903168,264.505096 
	C282.979675,257.771515 267.935638,251.302551 253.075882,244.433487 
	C251.121872,243.530228 249.531113,241.103806 248.518021,239.020157 
	C241.120560,223.805679 233.860870,208.523361 226.663681,193.212631 
	C221.005493,181.175827 215.480179,169.076340 209.946350,156.981598 
	C209.178253,155.302856 208.700272,153.491379 207.693497,150.613815 
	C277.711670,171.890625 347.060638,192.964081 416.697632,214.776154 
	C416.963501,249.778366 416.941345,284.041962 416.919189,318.305573 
	C400.865356,328.119751 384.885193,338.056976 368.738495,347.715820 
	C350.065613,358.885864 331.228546,369.780914 312.495667,380.851074 
	C296.159973,390.504700 279.868958,400.234009 263.560120,409.933044 
	C242.294510,422.579895 221.024307,435.219086 199.770554,447.885864 
	C194.145752,451.238037 188.513565,454.585114 183.003036,458.118927 
	C180.138763,459.955719 177.831421,459.607697 175.137238,457.698303 
	C158.714569,446.059479 142.170395,434.592163 125.739441,422.964966 
	C112.521774,413.611603 99.406830,404.113098 85.979950,393.968445 
	C85.471230,390.299225 85.020027,387.340790 85.017525,384.381927 
	C84.972717,331.426392 84.966454,278.470825 84.984299,225.515259 
	C85.004776,164.733215 85.056976,103.951172 85.095398,43.169132 
z"/>
<path fill="#4FABA2" opacity="1.000000" stroke="none" 
	d="
M84.681274,43.305588 
	C85.056976,103.951172 85.004776,164.733215 84.984299,225.515259 
	C84.966454,278.470825 84.972717,331.426392 85.017525,384.381927 
	C85.020027,387.340790 85.471230,390.299225 85.825157,393.663849 
	C81.997795,391.683777 84.065155,387.759918 83.906410,384.669983 
	C83.356384,373.963379 83.293159,363.225342 83.287216,352.499115 
	C83.282051,343.189728 83.659111,333.880798 83.770035,324.570465 
	C83.893410,314.214386 83.932243,303.856995 83.961464,293.500061 
	C83.996300,281.151245 83.982307,268.802307 83.988464,256.453400 
	C83.990578,252.211365 83.990204,247.969345 83.989975,243.727310 
	C83.988846,222.624222 84.065727,201.520752 83.962379,180.418167 
	C83.872444,162.055145 83.435738,143.693253 83.419556,125.330643 
	C83.406136,110.104210 83.807892,94.877724 83.977509,79.650719 
	C84.111954,67.581581 84.173325,55.511631 84.681274,43.305588 
z"/>
<path fill="#259A8E" opacity="1.000000" stroke="none" 
	d="
M178.422699,309.095520 
	C178.330383,305.551666 178.964233,301.927002 178.967789,298.301758 
	C179.041275,223.232819 179.043167,148.163803 179.072418,72.637428 
	C179.590836,76.684616 180.468307,81.184013 180.530991,85.694740 
	C180.714264,98.883026 180.639984,112.076355 180.538071,125.266708 
	C180.448425,136.870621 180.056000,148.472839 180.024475,160.076431 
	C179.946396,188.814178 179.996994,217.552277 179.998657,246.290237 
	C179.999481,260.362183 180.007614,274.434143 180.010071,288.506104 
	C180.011093,294.381439 180.057663,300.257660 179.952194,306.131165 
	C179.934814,307.098511 179.238098,308.053650 178.422699,309.095520 
z"/>
<path fill="#4BA79E" opacity="1.000000" stroke="none" 
	d="
M417.309082,318.063904 
	C416.941345,284.041962 416.963501,249.778366 416.967957,215.012894 
	C417.609314,215.299942 418.844360,216.090057 418.842712,216.877594 
	C418.808014,233.617294 418.652740,250.356888 418.480560,267.096100 
	C418.382019,276.677124 418.219208,286.257538 418.071503,295.838013 
	C417.958557,303.166260 417.823914,310.494202 417.309082,318.063904 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M95.886192,383.889160 
	C95.587196,384.286835 95.100891,384.490173 94.614586,384.693512 
	C94.720024,384.347900 94.825455,384.002319 95.127289,383.481689 
	C95.448761,383.436035 95.573822,383.565430 95.886192,383.889160 
z"/>
</svg>
);

export default SvgBlog;