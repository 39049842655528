import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

export const NoRowsOverlayWrap = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledDataGrid = styled(DataGrid)`
  &.MuiDataGrid-root {
    border:none;
    width:100%;
    height:calc(100vh - 230px);
  }

  & .MuiDataGrid-columnHeaderTitle{
    font-weight:700;
  }

  & .MuiDataGrid-row {
    min-height: 52px !important;
    max-height: fit-content !important;
    height: auto !important;
  }

  & .MuiDataGrid-columnHeaders .MuiSvgIcon-root {
    display:none;
  }

  & .MuiDataGrid-columnHeaders {
    background: rgba(98, 91, 113, 0.08);
  }

  & .MuiDataGrid-columnHeader[data-field="number"]:first-child .MuiDataGrid-columnHeaderTitleContainer{
    justify-content: center !important;
    margin:0 !important;
  }

  & .MuiDataGrid-columnHeader[data-field="number"]:first-child .MuiDataGrid-columnHeaderDraggableContainer{
    width:calc(100% - 10px) !important;
  }

  // & .MuiDataGrid-cell {
  //   min-height: 52px !important;
  //   max-height: fit-content !important;
  //   height: auto !important;
  //   padding: 6px !important;
  //   white-space: inherit !important;
  // }

  & .MuiButtonBase-root {
    color: #6a6a6a;
  }

  & .MuiButtonBase-root[title="Menu"] {
    color: red;
    display: none;
  }

  & .columnHeader--filtered {
    color: red !important;
  }

  & .MuiTablePagination-selectLabel, .MuiTablePagination-root , .MuiTablePagination-displayedRows{
    font-size:12px !important;
  }

  & .hover-cursor-default:hover{
    cursor:default;
  }

  & .hover-cursor-point:hover{
    cursor:pointer;
  }
`;