import React from 'react';

interface Props {
  width: number;
  height: number;
  color?: string;
  opacity?: number;
}

const SvgLicense = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M81.8919 21H14.1081C11.8393 21 10 23.1979 10 25.9091V70.0909C10 72.8021 11.8393 75 14.1081 75H81.8919C84.1607 75 86 72.8021 86 70.0909V25.9091C86 23.1979 84.1607 21 81.8919 21Z"
      fill="#9DD0FF"
    />
    <path
      d="M14.1081 22H81.8919C83.449 22 85 23.5759 85 25.9091V70.0909C85 72.4241 83.449 74 81.8919 74H14.1081C12.551 74 11 72.4241 11 70.0909V25.9091C11 23.5759 12.551 22 14.1081 22Z"
      stroke="url(#paint0_linear_53634_10186)"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <g filter="url(#filter0_di_53634_10186)">
      <path
        d="M78 34H57C55.8954 34 55 34.8954 55 36C55 37.1046 55.8954 38 57 38H78C79.1046 38 80 37.1046 80 36C80 34.8954 79.1046 34 78 34Z"
        fill="url(#paint1_linear_53634_10186)"
        shapeRendering="crispEdges"
      />
      <path
        d="M57 34.5H78C78.8284 34.5 79.5 35.1716 79.5 36C79.5 36.8284 78.8284 37.5 78 37.5H57C56.1716 37.5 55.5 36.8284 55.5 36C55.5 35.1716 56.1716 34.5 57 34.5Z"
        stroke="url(#paint2_linear_53634_10186)"
        strokeOpacity="0.1"
        strokeLinecap="round"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_di_53634_10186)">
      <path
        d="M78 46H57C55.8954 46 55 46.8954 55 48C55 49.1046 55.8954 50 57 50H78C79.1046 50 80 49.1046 80 48C80 46.8954 79.1046 46 78 46Z"
        fill="url(#paint3_linear_53634_10186)"
        shapeRendering="crispEdges"
      />
      <path
        d="M57 46.5H78C78.8284 46.5 79.5 47.1716 79.5 48C79.5 48.8284 78.8284 49.5 78 49.5H57C56.1716 49.5 55.5 48.8284 55.5 48C55.5 47.1716 56.1716 46.5 57 46.5Z"
        stroke="url(#paint4_linear_53634_10186)"
        strokeOpacity="0.1"
        strokeLinecap="round"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_di_53634_10186)">
      <path
        d="M78 58H57C55.8954 58 55 58.8954 55 60C55 61.1046 55.8954 62 57 62H78C79.1046 62 80 61.1046 80 60C80 58.8954 79.1046 58 78 58Z"
        fill="url(#paint5_linear_53634_10186)"
        shapeRendering="crispEdges"
      />
      <path
        d="M57 58.5H78C78.8284 58.5 79.5 59.1716 79.5 60C79.5 60.8284 78.8284 61.5 78 61.5H57C56.1716 61.5 55.5 60.8284 55.5 60C55.5 59.1716 56.1716 58.5 57 58.5Z"
        stroke="url(#paint6_linear_53634_10186)"
        strokeOpacity="0.1"
        strokeLinecap="round"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter3_di_53634_10186)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.25 51.5596L27.1588 46.5L15.79 59.9122C15.1219 60.7004 15.6815 61.9027 16.7208 61.9122L20.8858 61.9502L21.6468 66.004C21.8367 67.0155 23.13 67.3523 23.7981 66.5641L33.25 55.4133L42.7019 66.5641C43.37 67.3523 44.6633 67.0155 44.8532 66.004L45.6142 61.9502L49.7792 61.9122C50.8185 61.9027 51.3781 60.7004 50.71 59.9122L39.3412 46.5L33.25 51.5596Z"
        fill="url(#paint7_linear_53634_10186)"
        shapeRendering="crispEdges"
      />
      <path
        d="M32.9305 51.9442L33.25 52.2096L33.5695 51.9442L39.2799 47.2009L50.3286 60.2355C50.7163 60.6929 50.4002 61.4065 49.7746 61.4122L45.6096 61.4502L45.1986 61.4539L45.1228 61.8579L44.3618 65.9117C44.2507 66.5034 43.4833 66.7127 43.0833 66.2408L33.6314 55.09L33.25 54.64L32.8686 55.09L23.4167 66.2408C23.0167 66.7127 22.2493 66.5034 22.1382 65.9117L21.3772 61.8579L21.3014 61.4539L20.8904 61.4502L16.7254 61.4122C16.0998 61.4065 15.7837 60.6929 16.1714 60.2355L27.2201 47.2009L32.9305 51.9442Z"
        stroke="url(#paint8_linear_53634_10186)"
        strokeOpacity="0.1"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.5951 32.4848C39.9496 31.3494 38.619 30.7929 37.3573 31.1307L34.0479 32.017C33.555 32.149 33.0359 32.1472 32.544 32.0119L29.2407 31.1031C27.9814 30.7566 26.6469 31.3041 25.9937 32.4351L24.2803 35.4019C24.0251 35.8437 23.6568 36.2095 23.2132 36.4617L20.2348 38.1549C19.0994 38.8004 18.5429 40.131 18.8807 41.3927L19.767 44.7021C19.899 45.195 19.8972 45.7141 19.7619 46.206L18.8531 49.5093C18.5066 50.7686 19.0541 52.1031 20.1851 52.7563L23.1519 54.4697C23.5937 54.7249 23.9595 55.0932 24.2117 55.5368L25.9049 58.5152C26.5504 59.6506 27.881 60.2071 29.1427 59.8693L32.4521 58.983C32.945 58.851 33.4641 58.8528 33.956 58.9881L37.2593 59.8969C38.5186 60.2434 39.8531 59.6959 40.5063 58.5649L42.2197 55.5981C42.4749 55.1563 42.8432 54.7905 43.2868 54.5383L46.2652 52.8451C47.4006 52.1996 47.9571 50.869 47.6193 49.6073L46.733 46.2979C46.601 45.805 46.6028 45.2859 46.7381 44.794L47.6469 41.4907C47.9934 40.2314 47.4459 38.8969 46.3149 38.2437L43.3481 36.5303C42.9063 36.2751 42.5405 35.9068 42.2883 35.4632L40.5951 32.4848Z"
      fill="#448FFF"
    />
    <path
      d="M29.108 31.5852L29.2407 31.1031L29.108 31.5852L32.4114 32.494C32.989 32.6529 33.5985 32.6549 34.1772 32.5L34.0479 32.017L34.1772 32.5L37.4867 31.6137C38.5285 31.3347 39.6274 31.7943 40.1605 32.7319L41.8537 35.7103L42.2883 35.4632L41.8537 35.7103C42.1497 36.2311 42.5793 36.6636 43.098 36.9632L46.0648 38.6767C46.9989 39.2161 47.4509 40.3181 47.1648 41.358L46.256 44.6614C46.0971 45.239 46.0951 45.8485 46.25 46.4272L47.1363 49.7366C47.4153 50.7785 46.9557 51.8774 46.0181 52.4105L43.0397 54.1037C42.5189 54.3997 42.0864 54.8293 41.7868 55.3481L40.0733 58.3148C39.5339 59.2489 38.4319 59.7009 37.392 59.4148L34.0886 58.506C33.511 58.3471 32.9015 58.3451 32.3228 58.5L29.0133 59.3863C27.9715 59.6653 26.8726 59.2057 26.3395 58.2681L24.6463 55.2897L24.2117 55.5368L24.6463 55.2897C24.3503 54.7689 23.9207 54.3364 23.4019 54.0368L20.4351 52.3233C19.5011 51.7839 19.0491 50.6819 19.3352 49.642L20.244 46.3386C20.4029 45.761 20.4049 45.1515 20.25 44.5728L19.767 44.7021L20.25 44.5728L19.3637 41.2633C19.0847 40.2215 19.5443 39.1226 20.4819 38.5895L23.4603 36.8963C23.9811 36.6003 24.4136 36.1707 24.7132 35.6519L26.4267 32.6852C26.9661 31.7511 28.0681 31.2991 29.108 31.5852Z"
      stroke="url(#paint9_linear_53634_10186)"
      strokeOpacity="0.1"
    />
    <circle
      cx="33.25"
      cy="45.5"
      r="9"
      fill="url(#paint10_linear_53634_10186)"
    />
    <path
      d="M78 80C86.8366 80 94 72.8366 94 64C94 55.1634 86.8366 48 78 48C69.1634 48 62 55.1634 62 64C62 72.8366 69.1634 80 78 80Z"
      fill="#448FFF"
    />
    <path
      d="M93 64C93 72.2843 86.2843 79 78 79C69.7157 79 63 72.2843 63 64C63 55.7157 69.7157 49 78 49C86.2843 49 93 55.7157 93 64Z"
      stroke="url(#paint11_linear_53634_10186)"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.4142 57.9198C87.1953 58.7008 87.1953 59.9671 86.4142 60.7482L77.0809 70.0815C76.2998 70.8626 75.0335 70.8626 74.2525 70.0815L69.5858 65.4149C68.8047 64.6338 68.8047 63.3675 69.5858 62.5864C70.3668 61.8054 71.6332 61.8054 72.4142 62.5864L75.6667 65.8389L83.5858 57.9198C84.3668 57.1387 85.6332 57.1387 86.4142 57.9198Z"
      fill="url(#paint12_linear_53634_10186)"
    />
    <defs>
      <filter
        id="filter0_di_53634_10186"
        x="55"
        y="34"
        width="25"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53634_10186"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53634_10186"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53634_10186"
        />
      </filter>
      <filter
        id="filter1_di_53634_10186"
        x="55"
        y="46"
        width="25"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53634_10186"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53634_10186"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53634_10186"
        />
      </filter>
      <filter
        id="filter2_di_53634_10186"
        x="55"
        y="58"
        width="25"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53634_10186"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53634_10186"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53634_10186"
        />
      </filter>
      <filter
        id="filter3_di_53634_10186"
        x="15.5"
        y="46.5"
        width="35.5"
        height="21.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53634_10186"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53634_10186"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53634_10186"
        />
      </filter>
      <linearGradient
        id="paint0_linear_53634_10186"
        x1="48"
        y1="21"
        x2="48"
        y2="75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_53634_10186"
        x1="67.5"
        y1="34"
        x2="67.5"
        y2="38"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_53634_10186"
        x1="67.5"
        y1="34"
        x2="67.5"
        y2="38"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_53634_10186"
        x1="67.5"
        y1="46"
        x2="67.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_53634_10186"
        x1="67.5"
        y1="46"
        x2="67.5"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_53634_10186"
        x1="67.5"
        y1="58"
        x2="67.5"
        y2="62"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_53634_10186"
        x1="67.5"
        y1="58"
        x2="67.5"
        y2="62"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_53634_10186"
        x1="33.25"
        y1="46.5"
        x2="33.25"
        y2="67"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_53634_10186"
        x1="33.25"
        y1="46.5"
        x2="33.25"
        y2="67"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_53634_10186"
        x1="33.25"
        y1="31"
        x2="33.25"
        y2="60"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_53634_10186"
        x1="33.25"
        y1="36.5"
        x2="33.25"
        y2="54.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_53634_10186"
        x1="78"
        y1="48"
        x2="78"
        y2="80"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_53634_10186"
        x1="78"
        y1="57.334"
        x2="78"
        y2="70.6673"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgLicense;
