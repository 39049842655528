import React from 'react';
import { Routes, Route } from 'react-router';
import { StatusContainer, } from './style';
import { StatusMenu, StatusContents, StatusDetail } from './index';

export default () => {
  return (
    <StatusContainer>
      <StatusMenu />
      <Routes>
        <Route path="/" element={<StatusContents />}/>
        <Route path="/:server" element={<StatusContents />}/>
        <Route path="/detail" element={<StatusDetail />} />
        <Route path="/:server/detail" element={<StatusDetail />} />
      </Routes>
    </StatusContainer>
  )
};
