import styled from 'styled-components';

export const MuiContainer = styled.div`
  // padding: 128px 20px;
  height: 100%;
  // min-height: 800px;
  padding:0;
  @media screen and (min-width: 1100px) {
    // padding-left:238px;
    // padding-right:238px;
  }
  overflow: hidden !important;
  touch-action: none !important;
`;
