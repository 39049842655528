import React from 'react';
import { observer } from 'mobx-react';
import { MenuItem } from '@mui/material';
import { MuiSelect, ServerText } from './style';
import { ToolbarProps } from './type';

export default observer(({ server, handleChange, locationProps } : ToolbarProps) => {
  const path = locationProps.pathname;
  const isHome = path==='/';

  if(!isHome){
    return (
      <ServerText>
        {server}
      </ServerText>
    )
  }else{
    return(
      <MuiSelect
        value={server}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="product">Product</MenuItem>
        <MenuItem value="test">Test</MenuItem>
      </MuiSelect>
    )
  }
});
