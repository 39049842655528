import React from 'react';
import { GridRowParams } from '@mui/x-data-grid';
import { useNavigate, useLocation } from 'react-router';
import { useStore } from '../../store'
import { DataGridProps } from './type';
import { StyledDataGrid, NoRowsOverlayWrap } from './style';

const NoRowsOverlay = () => (
  <NoRowsOverlayWrap>No search results</NoRowsOverlayWrap>
);

export default ({
  rows,
  column,
  totalCount,
  onPageSizeChange,
  onPageChange,
  loading,
  page,
  rowsPerPage,
} : DataGridProps) => {
  const {
    statusStore:{
      setStatus
    }
  } = useStore();
  
  const navigate = useNavigate();
  const location = useLocation();

  const rowsClickHandler = (params: GridRowParams) => {
    const {row} = params;
    if(row.details.action.includes('End')){
      setStatus(row);
      window.scrollTo(0,0);
      localStorage.setItem('pageNo',page);
      localStorage.setItem('display',rowsPerPage);
      navigate(`${location.pathname}/detail`);
    }
  }
  const rowsMouseoverHandler = (event: React.MouseEvent<HTMLElement>) => {
    // eslint-disable-next-line
    const id = event.currentTarget.dataset.id!;
    // eslint-disable-next-line
    const row = rows.find((r:any) => r.id === Number(id))!;
    if(row.details.action.includes('End')){
      event.currentTarget.classList.add('hover-cursor-point');
      event.currentTarget.classList.remove('hover-cursor-default');
    }else{
      event.currentTarget.classList.add('hover-cursor-default');
      event.currentTarget.classList.remove('hover-cursor-point');
    }
  }

  return (
    <StyledDataGrid
      columnBuffer={2}
      columnThreshold={2}
      // autoHeight
      rows={rows}
      rowHeight={70}
      columns={column}
      pageSize={rowsPerPage}
      rowCount={totalCount}
      sortingMode="server"
      sortingOrder={[]}
      components={{
        ColumnUnsortedIcon: null,
        NoRowsOverlay,
      }}
      paginationMode="server"
      rowsPerPageOptions={[20, 40, 80, 100]}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      pagination
      hideFooterSelectedRowCount
      // checkboxSelection
      loading={loading}
      page={page}
      onRowClick={rowsClickHandler}
      componentsProps={{
        row:{
          onMouseEnter: rowsMouseoverHandler,
          onMouseLeave: rowsMouseoverHandler,
        },
      }}
    />
  )
}