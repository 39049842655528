/* eslint-disable react/self-closing-comp */
import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgComments = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 41C14 28.2186 24.5385 18 37.5385 18H58.4615C71.4615 18 82 28.2186 82 41C82 53.7814 71.4615 64 58.4615 64H57.7202C56.656 64 55.6356 64.4241 54.885 65.1784L42.875 77.2468C42.127 77.9822 41.0021 78.2022 40.0248 77.8042C39.0475 77.4062 38.4103 76.4685 38.4103 75.4285V64.8718C38.4103 64.3903 38.0199 64 37.5385 64C24.5385 64 14 53.7814 14 41Z"
      fill="#65D8A7"
    />
    <path
      d="M15 41C15 28.7948 25.0666 19 37.5385 19H58.4615C70.9334 19 81 28.7948 81 41C81 53.2052 70.9334 63 58.4615 63H57.7202C56.3899 63 55.1145 63.5301 54.1761 64.473L42.1739 76.5337C42.1728 76.5348 42.1716 76.5359 42.1705 76.537C41.7094 76.9877 41.0109 77.1261 40.4019 76.878C39.794 76.6304 39.4103 76.0541 39.4103 75.4285V64.8718C39.4103 63.838 38.5722 63 37.5385 63C25.0666 63 15 53.2052 15 41Z"
      stroke="url(#paint0_linear_51581_3667)"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <g filter="">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 45C36.2091 45 38 43.2091 38 41C38 38.7909 36.2091 37 34 37C31.7909 37 30 38.7909 30 41C30 43.2091 31.7909 45 34 45ZM52 41C52 43.2091 50.2091 45 48 45C45.7909 45 44 43.2091 44 41C44 38.7909 45.7909 37 48 37C50.2091 37 52 38.7909 52 41ZM66 41C66 43.2091 64.2091 45 62 45C59.7909 45 58 43.2091 58 41C58 38.7909 59.7909 37 62 37C64.2091 37 66 38.7909 66 41Z"
        fill="url(#paint1_linear_51581_3667)"
        shapeRendering="geometricPrecision"
      />
    </g>
    <defs>
      <filter
        id="filter0_di_51581_3667"
        x="30"
        y="37"
        width="36"
        height="9"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51581_3667"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51581_3667"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51581_3667"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51581_3667"
        x1="48"
        y1="18"
        x2="48"
        y2="78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_51581_3667"
        x1="48"
        y1="37"
        x2="48"
        y2="45"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgComments;
