import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgHome = (props: Props) => (
  <svg {...props} viewBox="0 0 28 28">
    <defs>
      <path
        id="kmy2lryzga"
        d="M10.714.756l7.687 5.774c.587.44.932 1.132.932 1.866v10.5c0 1.29-1.044 2.334-2.333 2.334h-4.333c-.829-.001-1.5-.673-1.5-1.501v-4.332c0-.599-.45-1.092-1.03-1.159L10 14.23c-.598 0-1.091.45-1.159 1.03l-.008.137v4.332c0 .828-.671 1.5-1.5 1.5L3 21.23c-1.289 0-2.333-1.045-2.333-2.333v-10.5c0-.734.346-1.426.934-1.867L9.314.755c.415-.311.985-.31 1.4 0zm-.701 1.662L2.65 7.93c-.117.088-.196.216-.223.358l-.01.109v10.499c0 .286.206.524.478.574l.105.01 3.585-.002c.276 0 .5-.224.5-.5l.001-3.683.017-.237c.163-1.401 1.306-2.481 2.793-2.57l.205-.006.237.017c1.401.162 2.481 1.306 2.572 2.705l.007.192v3.582c0 .276.224.5.5.5L17 19.48c.286 0 .525-.206.574-.478l.01-.105V8.396c0-.147-.056-.287-.153-.393l-.08-.074-7.338-5.511z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-554 -1525) translate(60 1471) translate(498 57)">
            <mask id="5yi8zopsmb" fill="#fff">
              <use xlinkHref="#kmy2lryzga" />
            </mask>
            <path
              fill="#FAFAFA"
              fillRule="nonzero"
              d="M-4 -3H24V25H-4z"
              mask="url(#5yi8zopsmb)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgHome;
