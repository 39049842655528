import React, { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

// import "./classStyle.css";

type DatePickerType = {
  label: string;
  date: any;
  setDate: (date: any) => void;
  max?: any;
  min?: any;
};

const CusDatePicker = ({ label, date, setDate, max, min }: DatePickerType) => {
  const [value, setValue] = useState<Date | null>(date);
  const onChange = (event: Date | null) => {
    if (event !== null) {
      setValue(event);
      setDate(event);
    }
  };
  useEffect(()=> {
    const getStartAt = localStorage.getItem('startAt');
    const getEndedAt = localStorage.getItem('endedAt');
    if(label.includes('Start') && getStartAt){
      const getStorageDate = new Date(getStartAt);
      setValue(getStorageDate);
    }
    if(label.includes('End') && getEndedAt){
      const getStorageDate = new Date(getEndedAt);
      setValue(getStorageDate);
    }
    // eslint-disable-next-line
  },[]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        inputFormat="yyyy/MM/dd"
        maxDate={max}
        minDate={min}
        mask="____/__/__"
        onChange={onChange}
        renderInput={(params: any) => (
          <TextField
            {...params}
            onKeyDown={(e: any) => e.preventDefault()}
            className="custom-picker"
            size='small'
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CusDatePicker;