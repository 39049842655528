import React, { useState, useEffect } from 'react';
import FaqModal from './FaqModal';
import settingObject from './settingObject';
import { 
  FaqContainer, FaqSelectWrap, FaqSelectBox, FaqSelectLabel, 
  FaqSelect, FaqSelectItem } from './style';

interface settingType {
  edition:string;
  language:string;
}

const initializeState = {
  edition: '- Select -',
  language: '- Select -'
}

export default () => {
  const [objState, setObjState] = useState(initializeState);
  const [languageState, setLanguageState] = useState(false);

  const selectHandler = (event:any) => {
    const { value, name } = event.target;
    let obj= {};

    if(name==='edition'){
      obj = { edition : value };

      if(value.indexOf('china')>0){
        obj = {...obj, language: initializeState.language} 
      }
    }

    if(name==='language'){
      obj = { language : value };
    }
    
    setObjState((prev : settingType) => ({
      ...prev,
      ...obj,
    }));
  }

  useEffect(() => {
    setObjState(initializeState);
  },[]);

  useEffect(()=> {
    return objState.edition.indexOf('china') > 0
      ? setLanguageState(true)
      : setLanguageState(false);
  },[objState.edition]);
  
  return(
    <FaqContainer>
      <FaqSelectWrap>
        <FaqSelectBox>
          <FaqSelectLabel>Edition</FaqSelectLabel>
          <FaqSelect 
            value={objState.edition}
            name='edition'
            onChange={selectHandler}
          > 
            {settingObject.edition.map((item) => (
              <FaqSelectItem
                value={item.value}
                key={item.value}
                style={{ zIndex: 1600 }}
              >
                {item.name}
              </FaqSelectItem>
            ))}
          </FaqSelect>
        </FaqSelectBox>
      </FaqSelectWrap>
      <FaqSelectWrap>
        <FaqSelectBox>
          <FaqSelectLabel>Language</FaqSelectLabel>
          <FaqSelect 
            value={objState.language}
            name='language'
            onChange={selectHandler}
            disabled={languageState}
          > 
            {settingObject.language.map((item) => (
              <FaqSelectItem
                value={item.value}
                key={item.value}
                style={{ zIndex: 1600 }}
              >
                {item.name}
              </FaqSelectItem>
            ))}
          </FaqSelect>
        </FaqSelectBox>
      </FaqSelectWrap>
      <FaqModal objState={objState} initializeState={initializeState} />
      
    </FaqContainer>
  )
};
