import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../store';
import { MainContainer, HomeContainer } from './style';
import Accordion from '../../components/Accordion/Accordion';
import SingleAccordion from '../../components/Accordion/AccordionButton';
import { Links } from './links';
import { AccordionProps } from '../../components/Accordion/type';

export default observer(() => {
  const {
    appStore: { 
      appState :{
        server
      }
    },
  } = useStore();

  return(
    <MainContainer>
      <HomeContainer>
        {Links.map(({title,contents} : AccordionProps) => 
          contents.length === 1
          ? <SingleAccordion key={title} contents={contents} server={server}/>
          : <Accordion key={title} title={title} contents={contents} server={server}/>
        )}
      </HomeContainer>
    </MainContainer>
  );
});
