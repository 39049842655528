import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgEmail = (props : Props) => {
  return (
    <svg {...props} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_5114_1170)">
<path d="M42.2501 9.75H9.75008C8.25508 9.75 7.04175 10.9633 7.04175 12.4583V39.5417C7.04175 41.0367 8.25508 42.25 9.75008 42.25H42.2501C43.7451 42.25 44.9584 41.0367 44.9584 39.5417V12.4583C44.9584 10.9633 43.7451 9.75 42.2501 9.75Z" fill="#9A82DB"/>
<path d="M9.75008 10.75H42.2501C43.1928 10.75 43.9584 11.5156 43.9584 12.4583V39.5417C43.9584 40.4844 43.1928 41.25 42.2501 41.25H9.75008C8.80737 41.25 8.04175 40.4844 8.04175 39.5417V12.4583C8.04175 11.5156 8.80737 10.75 9.75008 10.75Z" stroke="url(#paint0_linear_5114_1170)" strokeOpacity="0.1" strokeWidth="2"/>
<g filter="url(#filter0_di_5114_1170)">
<path d="M40.6088 36.2917H11.3913C10.7847 36.2917 10.2917 35.8606 10.2917 35.3272V16.6728C10.2917 16.1394 10.7847 15.7083 11.3913 15.7083H40.6088C41.2155 15.7083 41.7084 16.1394 41.7084 16.6728V35.3272C41.7084 35.8606 41.2155 36.2917 40.6088 36.2917Z" fill="url(#paint1_linear_5114_1170)" shapeRendering="crispEdges"/>
<path d="M40.6088 35.7917H11.3913C10.9968 35.7917 10.7917 35.5245 10.7917 35.3272V16.6728C10.7917 16.4755 10.9968 16.2083 11.3913 16.2083H40.6088C41.0034 16.2083 41.2084 16.4755 41.2084 16.6728V35.3272C41.2084 35.5245 41.0034 35.7917 40.6088 35.7917Z" stroke="url(#paint2_linear_5114_1170)" strokeOpacity="0.1" strokeLinecap="round" shapeRendering="crispEdges"/>
</g>
<g opacity="0.8" filter="url(#filter1_d_5114_1170)">
<path d="M25.4236 28.5185C25.7766 28.7716 26.2236 28.7716 26.5766 28.5185L41.1912 18.0372C42.1239 17.3683 41.7129 15.7083 40.6146 15.7083H11.3855C10.2872 15.7083 9.87631 17.3683 10.809 18.0372L25.4236 28.5185Z" fill="url(#paint3_linear_5114_1170)" shapeRendering="crispEdges"/>
<path d="M25.715 28.1121C25.8938 28.2404 26.1064 28.2404 26.2852 28.1121L40.8998 17.6309C41.1626 17.4424 41.2654 17.0905 41.1792 16.7421C41.0926 16.3925 40.8655 16.2083 40.6146 16.2083H11.3855C11.1346 16.2083 10.9075 16.3925 10.821 16.7421C10.7347 17.0905 10.8375 17.4424 11.1004 17.6309L25.715 28.1121Z" stroke="url(#paint4_linear_5114_1170)" strokeOpacity="0.1" shapeRendering="crispEdges"/>
</g>
</g>
<path d="M37.9167 46.5833C42.7031 46.5833 46.5833 42.7031 46.5833 37.9167C46.5833 33.1302 42.7031 29.25 37.9167 29.25C33.1302 29.25 29.25 33.1302 29.25 37.9167C29.25 42.7031 33.1302 46.5833 37.9167 46.5833Z" fill="#6544EA"/>
<path d="M45.5833 37.9167C45.5833 42.1508 42.1508 45.5833 37.9167 45.5833C33.6825 45.5833 30.25 42.1508 30.25 37.9167C30.25 33.6825 33.6825 30.25 37.9167 30.25C42.1508 30.25 45.5833 33.6825 45.5833 37.9167Z" stroke="url(#paint5_linear_5114_1170)" strokeOpacity="0.1" strokeWidth="2"/>
<g filter="url(#filter2_di_5114_1170)">
<path fillRule="evenodd" clipRule="evenodd" d="M43.095 34.9115C42.7772 34.585 42.262 34.585 41.9443 34.9115L36.7659 40.2317L33.8891 37.2761C33.5713 36.9496 33.0561 36.9496 32.7383 37.2761C32.4206 37.6026 32.4206 38.1319 32.7383 38.4584L36.1906 42.0052C36.5083 42.3316 37.0235 42.3316 37.3413 42.0052C37.3425 42.0039 37.3437 42.0027 37.3449 42.0015L43.095 36.0938C43.4128 35.7673 43.4128 35.238 43.095 34.9115Z" fill="url(#paint6_linear_5114_1170)" shapeRendering="crispEdges"/>
</g>
<defs>
<filter id="filter0_di_5114_1170" x="10.2917" y="11.7083" width="31.4167" height="25.5833" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5114_1170"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5114_1170" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-4"/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_5114_1170"/>
</filter>
<filter id="filter1_d_5114_1170" x="8.29175" y="14.7083" width="35.4167" height="17" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5114_1170"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5114_1170" result="shape"/>
</filter>
<filter id="filter2_di_5114_1170" x="32.5" y="34.6667" width="10.8333" height="8.58336" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5114_1170"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5114_1170" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="shape" result="effect2_innerShadow_5114_1170"/>
</filter>
<linearGradient id="paint0_linear_5114_1170" x1="26.0001" y1="9.75" x2="26.0001" y2="42.25" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_5114_1170" x1="26.0001" y1="15.7083" x2="26.0001" y2="35.2083" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0.9"/>
<stop offset="1" stopColor="white" stopOpacity="0.8"/>
<stop offset="1" stopColor="white" stopOpacity="0.8"/>
</linearGradient>
<linearGradient id="paint2_linear_5114_1170" x1="26.0001" y1="15.7083" x2="26.0001" y2="36.2917" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_5114_1170" x1="26.0001" y1="28.7083" x2="26.0001" y2="15.7083" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0.9"/>
<stop offset="1" stopColor="white" stopOpacity="0.8"/>
</linearGradient>
<linearGradient id="paint4_linear_5114_1170" x1="26.0001" y1="28.7083" x2="26.0001" y2="15.7083" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_5114_1170" x1="37.9167" y1="29.25" x2="37.9167" y2="46.5833" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_5114_1170" x1="37.9167" y1="34.6667" x2="37.9167" y2="42.25" gradientUnits="userSpaceOnUse">
<stop stopColor="white" stopOpacity="0.9"/>
<stop offset="1" stopColor="white" stopOpacity="0.8"/>
</linearGradient>
<clipPath id="clip0_5114_1170">
<rect width="37.9167" height="32.5" fill="white" transform="translate(7.04175 9.75)"/>
</clipPath>
</defs>
</svg>
  )
}

export default SvgEmail;
