import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgDashBoard = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_117_2176)">
      <path
        d="M42.25 9.75H9.74996C8.25496 9.75 7.04163 10.9633 7.04163 12.4583V39.5417C7.04163 41.0367 8.25496 42.25 9.74996 42.25H42.25C43.745 42.25 44.9583 41.0367 44.9583 39.5417V12.4583C44.9583 10.9633 43.745 9.75 42.25 9.75Z"
        fill="#9A82DB"
      />
      <path
        d="M9.74996 10.75H42.25C43.1927 10.75 43.9583 11.5156 43.9583 12.4583V39.5417C43.9583 40.4844 43.1927 41.25 42.25 41.25H9.74996C8.80724 41.25 8.04163 40.4844 8.04163 39.5417V12.4583C8.04163 11.5156 8.80724 10.75 9.74996 10.75Z"
        stroke="url(#paint0_linear_117_2176)"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g filter="">
        <path
          d="M40.6087 38.9999H11.3912C10.7845 38.9999 10.2916 38.5666 10.2916 38.0303V20.3666C10.2916 19.8303 10.7845 19.397 11.3912 19.397H40.6087C41.2154 19.397 41.7083 19.8303 41.7083 20.3666V38.0303C41.7083 38.5666 41.2154 38.9999 40.6087 38.9999Z"
          fill="url(#paint1_linear_117_2176)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M40.6087 38.4999H11.3912C10.9993 38.4999 10.7916 38.2327 10.7916 38.0303V20.3666C10.7916 20.1641 10.9993 19.897 11.3912 19.897H40.6087C41.0007 19.897 41.2083 20.1641 41.2083 20.3666V38.0303C41.2083 38.2327 41.0007 38.4999 40.6087 38.4999Z"
          stroke="url(#paint2_linear_117_2176)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <g filter="">
        <path
          d="M20.6158 16.147H11.8625C10.9958 16.147 10.2916 15.4429 10.2916 14.5762C10.2916 13.7095 10.9958 13.0054 11.8625 13.0054H20.6158C21.4825 13.0054 22.1866 13.7095 22.1866 14.5762C22.1866 15.4429 21.4825 16.147 20.6158 16.147Z"
          fill="url(#paint3_linear_117_2176)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M20.6158 15.647H11.8625C11.2719 15.647 10.7916 15.1667 10.7916 14.5762C10.7916 13.9857 11.2719 13.5054 11.8625 13.5054H20.6158C21.2063 13.5054 21.6866 13.9857 21.6866 14.5762C21.6866 15.1667 21.2063 15.647 20.6158 15.647Z"
          stroke="url(#paint4_linear_117_2176)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <g filter="">
        <path
          d="M25.9999 16.1417C26.8675 16.1417 27.5707 15.4384 27.5707 14.5708C27.5707 13.7033 26.8675 13 25.9999 13C25.1324 13 24.4291 13.7033 24.4291 14.5708C24.4291 15.4384 25.1324 16.1417 25.9999 16.1417Z"
          fill="url(#paint5_linear_117_2176)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M27.0707 14.5708C27.0707 15.1622 26.5913 15.6417 25.9999 15.6417C25.4085 15.6417 24.9291 15.1622 24.9291 14.5708C24.9291 13.9794 25.4085 13.5 25.9999 13.5C26.5913 13.5 27.0707 13.9794 27.0707 14.5708Z"
          stroke="url(#paint6_linear_117_2176)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <rect
        x="15.1666"
        y="22.2085"
        width="21.6667"
        height="6.5"
        rx="2"
        fill="#D0BCFF"
      />
      <g filter="">
        <path
          d="M33.1094 24.375H18.8906C18.3297 24.375 17.875 24.86 17.875 25.4583C17.875 26.0566 18.3297 26.5417 18.8906 26.5417H33.1094C33.6703 26.5417 34.125 26.0566 34.125 25.4583C34.125 24.86 33.6703 24.375 33.1094 24.375Z"
          fill="url(#paint7_linear_117_2176)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M18.8906 24.875H33.1094C33.3642 24.875 33.625 25.1053 33.625 25.4583C33.625 25.8114 33.3642 26.0417 33.1094 26.0417H18.8906C18.6358 26.0417 18.375 25.8114 18.375 25.4583C18.375 25.1053 18.6358 24.875 18.8906 24.875Z"
          stroke="url(#paint8_linear_117_2176)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <rect
        x="15.1666"
        y="30.3335"
        width="21.6667"
        height="6.5"
        rx="2"
        fill="#D0BCFF"
      />
      <g filter="">
        <path
          d="M33.1094 32.5H18.8906C18.3297 32.5 17.875 32.985 17.875 33.5833C17.875 34.1816 18.3297 34.6667 18.8906 34.6667H33.1094C33.6703 34.6667 34.125 34.1816 34.125 33.5833C34.125 32.985 33.6703 32.5 33.1094 32.5Z"
          fill="url(#paint9_linear_117_2176)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M18.8906 33H33.1094C33.3642 33 33.625 33.2303 33.625 33.5833C33.625 33.9364 33.3642 34.1667 33.1094 34.1667H18.8906C18.6358 34.1667 18.375 33.9364 18.375 33.5833C18.375 33.2303 18.6358 33 18.8906 33Z"
          stroke="url(#paint10_linear_117_2176)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_di_117_2176"
        x="10.2916"
        y="19.397"
        width="31.4166"
        height="20.603"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_117_2176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_117_2176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_117_2176"
        />
      </filter>
      <filter
        id="filter1_di_117_2176"
        x="10.2916"
        y="13.0054"
        width="11.895"
        height="4.1416"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_117_2176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_117_2176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_117_2176"
        />
      </filter>
      <filter
        id="filter2_di_117_2176"
        x="24.4291"
        y="13"
        width="3.14172"
        height="4.1416"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_117_2176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_117_2176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_117_2176"
        />
      </filter>
      <filter
        id="filter3_di_117_2176"
        x="17.875"
        y="24.375"
        width="16.25"
        height="3.1665"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_117_2176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_117_2176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_117_2176"
        />
      </filter>
      <filter
        id="filter4_di_117_2176"
        x="17.875"
        y="32.5"
        width="16.25"
        height="3.1665"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_117_2176"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_117_2176"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_117_2176"
        />
      </filter>
      <linearGradient
        id="paint0_linear_117_2176"
        x1="26"
        y1="9.75"
        x2="26"
        y2="42.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_117_2176"
        x1="26"
        y1="19.397"
        x2="26"
        y2="38.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_117_2176"
        x1="26"
        y1="19.397"
        x2="26"
        y2="38.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_117_2176"
        x1="16.2391"
        y1="13.0054"
        x2="16.2391"
        y2="16.147"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_117_2176"
        x1="16.2391"
        y1="13.0054"
        x2="16.2391"
        y2="16.147"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_117_2176"
        x1="25.9999"
        y1="13"
        x2="25.9999"
        y2="16.1417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_117_2176"
        x1="25.9999"
        y1="13"
        x2="25.9999"
        y2="16.1417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_117_2176"
        x1="26"
        y1="24.375"
        x2="26"
        y2="26.5417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_117_2176"
        x1="26"
        y1="24.375"
        x2="26"
        y2="26.5417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_117_2176"
        x1="26"
        y1="32.5"
        x2="26"
        y2="34.6667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_117_2176"
        x1="26"
        y1="32.5"
        x2="26"
        y2="34.6667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_117_2176">
        <rect
          width="37.9167"
          height="32.5"
          fill="white"
          transform="translate(7.04163 9.75)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDashBoard;