import React from 'react';

interface Props {
  width: number;
  height: number;
  color?: string;
  opacity?: number;
}

const SvgLicenseRegister = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53637_9886)">
      <path
        d="M75 18H15C12.24 18 10 20.24 10 23V73C10 75.76 12.24 78 15 78H75C77.76 78 80 75.76 80 73V23C80 20.24 77.76 18 75 18Z"
        fill="#99E97D"
      />
      <path
        d="M15 19H75C77.2077 19 79 20.7923 79 23V73C79 75.2077 77.2077 77 75 77H15C12.7923 77 11 75.2077 11 73V23C11 20.7923 12.7923 19 15 19Z"
        stroke="url(#paint0_linear_53637_9886)"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g opacity="0.7" filter="url(#filter0_di_53637_9886)">
        <path
          d="M46 60H18C16.8954 60 16 60.8954 16 62C16 63.1046 16.8954 64 18 64H46C47.1046 64 48 63.1046 48 62C48 60.8954 47.1046 60 46 60Z"
          fill="url(#paint1_linear_53637_9886)"
          shapeRendering="crispEdges"
        />
        <path
          d="M18 60.5H46C46.8284 60.5 47.5 61.1716 47.5 62C47.5 62.8284 46.8284 63.5 46 63.5H18C17.1716 63.5 16.5 62.8284 16.5 62C16.5 61.1716 17.1716 60.5 18 60.5Z"
          stroke="url(#paint2_linear_53637_9886)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="crispEdges"
        />
      </g>
      <g opacity="0.7" filter="url(#filter1_di_53637_9886)">
        <path
          d="M38 68H18C16.8954 68 16 68.8954 16 70C16 71.1046 16.8954 72 18 72H38C39.1046 72 40 71.1046 40 70C40 68.8954 39.1046 68 38 68Z"
          fill="url(#paint3_linear_53637_9886)"
          shapeRendering="crispEdges"
        />
        <path
          d="M18 68.5H38C38.8284 68.5 39.5 69.1716 39.5 70C39.5 70.8284 38.8284 71.5 38 71.5H18C17.1716 71.5 16.5 70.8284 16.5 70C16.5 69.1716 17.1716 68.5 18 68.5Z"
          stroke="url(#paint4_linear_53637_9886)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_di_53637_9886)">
        <path
          d="M71.97 54H18.03C16.91 54 16 53.6021 16 53.1097V36.8903C16 36.3979 16.91 36 18.03 36H71.97C73.09 36 74 36.3979 74 36.8903V53.1097C74 53.6021 73.09 54 71.97 54Z"
          fill="url(#paint5_linear_53637_9886)"
          shapeRendering="crispEdges"
        />
        <path
          d="M71.97 53.5H18.03C17.5224 53.5 17.0862 53.4088 16.7957 53.2815C16.6497 53.2176 16.5629 53.1543 16.5195 53.1093C16.51 53.0995 16.5038 53.092 16.5 53.0868V36.9132C16.5038 36.908 16.51 36.9005 16.5195 36.8907C16.5629 36.8457 16.6497 36.7824 16.7957 36.7185C17.0862 36.5912 17.5224 36.5 18.03 36.5H71.97C72.4776 36.5 72.9138 36.5912 73.2043 36.7185C73.3503 36.7824 73.4371 36.8457 73.4805 36.8907C73.49 36.9005 73.4962 36.908 73.5 36.9132V53.0868C73.4962 53.092 73.49 53.0995 73.4805 53.1093C73.4371 53.1543 73.3503 53.2176 73.2043 53.2815C72.9138 53.4088 72.4776 53.5 71.97 53.5ZM16.4944 53.0779C16.4944 53.0779 16.4947 53.0784 16.4952 53.0795L16.4944 53.0779ZM16.4944 36.9221C16.4943 36.9221 16.4946 36.9216 16.4952 36.9205L16.4944 36.9221ZM73.5056 36.9221C73.5056 36.9221 73.5053 36.9216 73.5048 36.9205L73.5056 36.9221ZM73.5056 53.0779C73.5057 53.0778 73.5055 53.0784 73.5048 53.0795L73.5056 53.0779Z"
          stroke="url(#paint6_linear_53637_9886)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter3_di_53637_9886)">
        <path
          d="M35.06 29.8098H18.9C17.3 29.8098 16 28.5098 16 26.9098C16 25.3098 17.3 24.0098 18.9 24.0098H35.06C36.66 24.0098 37.96 25.3098 37.96 26.9098C37.96 28.5098 36.66 29.8098 35.06 29.8098Z"
          fill="url(#paint7_linear_53637_9886)"
          shapeRendering="crispEdges"
        />
        <path
          d="M18 60.5H46C46.8284 60.5 47.5 61.1716 47.5 62C47.5 62.8284 46.8284 63.5 46 63.5H18C17.1716 63.5 16.5 62.8284 16.5 62C16.5 61.1716 17.1716 60.5 18 60.5Z"
          stroke="url(#paint8_linear_53637_9886)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter4_di_53637_9886)">
        <path
          d="M44.9996 29.8C46.6012 29.8 47.8996 28.5016 47.8996 26.9C47.8996 25.2984 46.6012 24 44.9996 24C43.398 24 42.0996 25.2984 42.0996 26.9C42.0996 28.5016 43.398 29.8 44.9996 29.8Z"
          fill="url(#paint9_linear_53637_9886)"
          shapeRendering="crispEdges"
        />
        <path
          d="M18 68.5H38C38.8284 68.5 39.5 69.1716 39.5 70C39.5 70.8284 38.8284 71.5 38 71.5H18C17.1716 71.5 16.5 70.8284 16.5 70C16.5 69.1716 17.1716 68.5 18 68.5Z"
          stroke="url(#paint10_linear_53637_9886)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="crispEdges"
        />
      </g>
      <path d="M67 71H74V78H67V71Z" fill="#D9D9D9" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.4915 58.1261C62.4438 57.4843 60.4674 59.4018 61.1306 61.3875L70.1058 88.2842C70.8301 90.4551 73.9347 90.6033 74.9722 88.5154L80.6644 77.0636L92.4704 71.5381C94.6223 70.5316 94.4689 67.5205 92.2293 66.8187L64.4915 58.1261Z"
      fill="url(#paint11_linear_53637_9886)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.704 74.0327L87.3969 69.4962L65.7941 62.7261L72.8355 83.8276L77.704 74.0327ZM92.2293 66.8187L64.4915 58.1261C62.4438 57.4843 60.4674 59.4018 61.1306 61.3875L70.1058 88.2842C70.8301 90.4551 73.9347 90.6033 74.9722 88.5154L80.6644 77.0636L92.4704 71.5381C94.6223 70.5316 94.4689 67.5205 92.2293 66.8187Z"
      fill="#4EBC28"
    />
    <path
      d="M87.6089 69.949L88.774 69.4037L87.5465 69.019L65.9436 62.249L65.0102 61.9565L65.3198 62.8844L72.3612 83.9859L72.7444 85.1342L73.2832 84.0501L78.0741 74.4116L87.6089 69.949ZM92.0798 67.2959L92.0798 67.2959C93.8562 67.8525 94.0239 70.2595 92.2586 71.0852L92.2584 71.0852L80.4524 76.6108L80.2943 76.6848L80.2166 76.8411L74.5245 88.2929L74.5245 88.2929C73.6682 90.016 71.1511 89.8375 70.5801 88.1259L70.1058 88.2842L70.58 88.1259L61.6049 61.2293L61.6048 61.2291C61.0822 59.6641 62.6494 58.0727 64.342 58.6032L64.342 58.6032L92.0798 67.2959Z"
      stroke="url(#paint12_linear_53637_9886)"
      strokeOpacity="0.1"
    />
    <defs>
      <filter
        id="filter0_di_53637_9886"
        x="16"
        y="60"
        width="32"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53637_9886"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53637_9886"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53637_9886"
        />
      </filter>
      <filter
        id="filter1_di_53637_9886"
        x="16"
        y="68"
        width="24"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53637_9886"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53637_9886"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53637_9886"
        />
      </filter>
      <filter
        id="filter2_di_53637_9886"
        x="16"
        y="36"
        width="58"
        height="19"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53637_9886"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53637_9886"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53637_9886"
        />
      </filter>
      <filter
        id="filter3_di_53637_9886"
        x="16"
        y="24.0098"
        width="21.96"
        height="6.80078"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53637_9886"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53637_9886"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53637_9886"
        />
      </filter>
      <filter
        id="filter4_di_53637_9886"
        x="42.0996"
        y="24"
        width="5.7998"
        height="6.80078"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_53637_9886"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_53637_9886"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_53637_9886"
        />
      </filter>
      <linearGradient
        id="paint0_linear_53637_9886"
        x1="45"
        y1="18"
        x2="45"
        y2="78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_53637_9886"
        x1="32"
        y1="60"
        x2="32"
        y2="64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_53637_9886"
        x1="32"
        y1="60"
        x2="32"
        y2="64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_53637_9886"
        x1="28"
        y1="68"
        x2="28"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_53637_9886"
        x1="28"
        y1="68"
        x2="28"
        y2="72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_53637_9886"
        x1="45"
        y1="36"
        x2="45"
        y2="54"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_53637_9886"
        x1="45"
        y1="36"
        x2="45"
        y2="54"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_53637_9886"
        x1="26.98"
        y1="24.0098"
        x2="26.98"
        y2="29.8098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_53637_9886"
        x1="26.98"
        y1="24.0098"
        x2="26.98"
        y2="29.8098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_53637_9886"
        x1="44.9996"
        y1="24"
        x2="44.9996"
        y2="29.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_53637_9886"
        x1="44.9996"
        y1="24"
        x2="44.9996"
        y2="29.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_53637_9886"
        x1="77.5"
        y1="58"
        x2="77.5"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_53637_9886"
        x1="77.5"
        y1="58"
        x2="77.5"
        y2="90"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_53637_9886">
        <rect
          width="70"
          height="60"
          fill="white"
          transform="translate(10 18)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLicenseRegister;
