import styled from 'styled-components';
import { Container, Box, Button, InputLabel, Select, MenuItem } from '@mui/material';

export const NoticeContainer = styled(Container)`
  display:block;
  width:100%;
  padding-top:120px;
  height:auto;
`;

export const NoticeSelectWrap = styled(Box)`
  display:flex;
  justify-content:center;
  align-items:center;
`;

export const NoticeSelectBox = styled(Box)`
  
`;

export const NoticeSelectLabel = styled(InputLabel)`
  margin-bottom:16px;
`;

export const NoticeSelect = styled(Select)`
  width:200px;
  margin-bottom:32px;
`;

export const NoticeSelectItem = styled(MenuItem)`

`;

export const NoticeSearchButton = styled(Button)`
  &.MuiButtonBase-root{
    text-transform:none !important;
    background-color: #6750A4;
    color: white;
    // width:100px;
    font-weight:700;
    // pointer-events:none;
  }
  &.MuiButtonBase-root:hover{
    // background: none !important;
    background-color : #6750A4 !important;
  }
`;

export const NoticeModalContents = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: none;
  padding: 24px;
`;

export const NoticeModalConfirmButton = styled(Button)`
  &.MuiButtonBase-root{
    text-transform:none !important;
    background-color: transparent !important;
    color: #6750A4;
    // width:100px;
    font-weight:700;
    // pointer-events:none;
  }
  &.MuiButtonBase-root:hover{
    // background: none !important;
    background-color : transparent !important;
  }
`;