import React from 'react';
import { observer } from 'mobx-react';
import { MenuButton } from './style';
// import MenuModal from './MenuModal';
import ServerModal from './ServerModal';

export default observer(({ server, menuHandler, locationProps }:any) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const MenuButtonHandler = () => {
    return modalOpen ? setModalOpen(false) : setModalOpen(true);
  }
  const isHome = locationProps.pathname==='/';
  
  if(isHome){
    return (
      <>
        <MenuButton 
          onClick={MenuButtonHandler}
        />
        <ServerModal
          server={server} 
          menuHandler={menuHandler}
          modalOpen={modalOpen} 
          setModalOpen={setModalOpen}
        />
      </>
    )
  }else{
    return (<></>)
  }
});