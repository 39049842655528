import React,{ useState, useEffect } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { StyledContents, StyledDatePickerBox, StyledDataGridBox, HistoryDetailWrap } from './style';
import DatePicker from '../../components/DatePicker/DatePicker';
import { DataGrid } from "../../components/DataGrid";
import { StatusContentsTitle } from './index';
// import DummyData from "./DummyData";
import { getStatusList } from '../../utils/fetch_status';
import { useDidMountEffect } from '../../utils/customHook';

export default () => {
  dayjs.extend(utc);
  
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "w").toDate()
  );
  const [endDate, setEndDate] = useState(
    dayjs().toDate()
  );
  
  const [historyRows, setHistoryRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const fetchHistoryInfo = async (pageNo? : any, display? : any) => {

    window.addEventListener('beforeunload', () => {
      localStorage.removeItem('startAt');
      localStorage.removeItem('endedAt');
      localStorage.removeItem('pageNo');
      // localStorage.removeItem('display');
    });

    const params = {
      startedAt: dayjs(startDate).utc(true).startOf("d").format(),
      endedAt: dayjs(endDate).utc(true).endOf("d").format(),
      display: !display ? rowsPerPage : display,
      page: pageNo+1
    }

    if(localStorage.getItem('startAt')){
      params.startedAt = localStorage.getItem('startAt')??'';
    }

    if(localStorage.getItem('endedAt')){
      params.endedAt = localStorage.getItem('endedAt')??'';
    }

    if(localStorage.getItem('display')){
      params.display = localStorage.getItem('display')??'';
    }

    setLoading(true);

    try {
      const data : any = await getStatusList(params);
      const statusList = data.history.map((list : any) => {
        return {
          ...list,
          id:list.historyId,
        }
      })
      setHistoryRows(statusList);
      setTotalCount(data.totalCount);
    }catch(error :any){
      throw new Error(error);
    }finally{
      setLoading(false);
    }
  }

  const initializeDataGrid = () => {
    const storagePageNo
      = localStorage.getItem('pageNo') 
        ? Number(localStorage.getItem('pageNo')) 
        : 0;
    const storageDisplay 
      = localStorage.getItem('display')
        ? Number(localStorage.getItem('display'))
        : 20;
    if(storagePageNo === 0){
      fetchHistoryInfo(0,rowsPerPage);
    }else{
      setRowsPerPage(storageDisplay);
      setPage(storagePageNo);
    }
    localStorage.removeItem('pageNo');
  }

  const setDateInStorage = () => {
    localStorage.setItem('startAt',dayjs(startDate).utc(true).startOf('d').format());
    localStorage.setItem('endedAt',dayjs(endDate).utc(true).endOf('d').format());
  }

  useDidMountEffect(() => {
    if(localStorage.getItem('display')){
      localStorage.setItem('display',rowsPerPage.toString());
      fetchHistoryInfo(page,rowsPerPage);
      setPage(page);
    }else{
      fetchHistoryInfo(0,rowsPerPage);
      setPage(0);
    }
    
  },[rowsPerPage]);

  useDidMountEffect(() => {
    // if(page===0) return;
    fetchHistoryInfo(page,rowsPerPage);
  },[page]);

  useDidMountEffect(() => {
    setDateInStorage();
    setHistoryRows([]);
    fetchHistoryInfo(0,rowsPerPage);
    setPage(0);
  },[startDate,endDate]);

  useEffect(() => {
    initializeDataGrid();
    // eslint-disable-next-line
  },[]);

  const onPageSizeChange = (params: any) => {
    window.scrollTo(0, 0);
    setRowsPerPage(params);
  };

  const onPageChange = (params: any) => {
    window.scrollTo(0, 0);
    setPage(params);
  };

  const column = [
    {
      field: "number",
      headerName: "No",
      width: 60,
      renderCell: (params: any) => (
        <p style={{ textAlign: "center", display: "block", width: "100%" }}>
          {
            page * rowsPerPage +
              params.api.getRowIndex(params.row.historyId) + 1
          }
        </p>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 365,
      minWidth:196,
      maxWidth:365,
      flex:1,
      renderCell: (params: any) => (
        <p style={{ display: "block", width: "100%" }}>
          {dayjs(params.row.createdAt).utc().format("YYYY-MM-DD HH:mm")} (UTC)
        </p>
      ),
    },
    {
      field: "details.action",
      headerName: "Action",
      width: 365,
      minWidth:135,
      maxWidth:365,
      flex:1,
      renderCell: (params: any) => params.row.details?.action,
    },
    {
      field: "user",
      headerName: "Published by",
      width: 365,
      minWidth: 227,
      maxWidth: 365,
      flex:1,
    },
    {
      field: "details",
      headerName: "Detail",
      width: 365,
      minWidth:220,
      maxWidth:365,
      flex:1,
      renderCell: (params: any) => {
        if (params.row.details.action.includes('End')) {
          return (
            <HistoryDetailWrap>
              <dl style={{ display: "block" }}>
                <dt>Total : </dt>
                <dd>{params.row.details?.total}</dd>
              </dl>
              <dl style={{ display: "block" }}>
                <dt>Success : </dt>
                <dd>{params.row.details?.success}</dd>
              </dl>
              <dl style={{ display: "block" }}>
                <dt>Fail : </dt>
                <dd>{params.row.details?.fail}</dd>
              </dl>
            </HistoryDetailWrap>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <StyledContents>
      <StatusContentsTitle />
      <StyledDatePickerBox>
        <DatePicker
          label="Start Date"
          date={startDate}
          setDate={setStartDate}
          max={endDate}
        />
        <DatePicker
          label="End Date"
          date={endDate}
          setDate={setEndDate}
          min={startDate}
        />
      </StyledDatePickerBox>
      <StyledDataGridBox>
        <DataGrid
          rows={historyRows}
          column={column}
          totalCount={totalCount}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          loading={loading}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </StyledDataGridBox>
    </StyledContents>
  )
}