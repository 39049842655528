const Account = {
  title: 'Account',
  contents: [
    {
      name: 'Accounts',
      description: 'Suspend or delete member accounts, and register creators',
      icon: 'userIcon',
      product: 'https://account-console.kinemaster.com/',
      test: 'https://test-account-console.kinemaster.com/',
      popup: true,
    },
    {
      name: 'Dashboard',
      description: 'Count users',
      icon: 'SvgDashboard',
      product: `https://console.kinemaster.com/dashboard`,
      test: `https://test-console.kinemaster.com/dashboard`,
      popup: true,
    },
    {
      name: 'Bulk Email',
      description:
        'Service that allows you to send emails to a large number of users',
      icon: 'SvgEmail',
      product: `https://admin-bulk-message.kinemaster.com/sent`,
      test: `https://test-admin-bulk-message.kinemaster.com/sent`,
      popup: true,
    },
  ],
};

const Asset = {
  title: 'Asset',
  contents: [
    {
      name: 'Asset Store Console v2',
      description: 'Review, Publish Assets',
      icon: 'ImgAssetStoreV2',
      product: 'https://asset-console.kinemaster.com',
      test: 'https://test-asset-console.kinemaster.com',
      popup: true,
    },
    {
      name: 'Asset Store Console v1',
      description: 'Upload and distribute assets',
      icon: 'SvgAssetStore',
      product: 'https://asms.nexstreaming.com/',
      test: 'https://test-kinemaster-asms.nexstreaming.com/',
      popup: true,
    },
    {
      name: 'Asset Store v1 Preview',
      description: 'Asset Store Preview',
      icon: 'SvgAssetStoreWebViewer',
      product: 'https://assetstore.nexstreaming.com/',
      test: 'https://assetstore.nexstreaming.com/',
      popup: true,
    },
  ],
};

const MixAndSearch = {
  title: 'Mix & Search',
  contents: [
    {
      name: 'Templates on Mix',
      description: 'Review, Publish Templates',
      icon: 'SvgTemplates',
      product: 'https://templates-console.kinemaster.com',
      test: 'https://test-templates-console.kinemaster.com',
      popup: true,
    },
    {
      name: 'Sections & Banners',
      description: 'Manage sections and banners.',
      icon: 'SvgSection',
      product: 'https://section-console.kinemaster.com',
      test: 'https://test-section-console.kinemaster.com',
      popup: true,
    },
    {
      name: 'Dashboard',
      description: 'The Number of Templates',
      icon: 'SvgTemplateDashboard',
      product: 'https://console.kinemaster.com/templates',
      test: 'https://test-console.kinemaster.com/templates',
      popup: true,
    },
    // {
    //   name: 'Mix & Search (In-House)',
    //   description: 'Publish, edit, delete projects',
    //   icon: 'projectsIcon',
    //   product: 'https://admin-project-feed.kinemaster.com/',
    //   test: 'https://test-admin-project-feed.kinemaster.com/',
    //   popup: true,
    // },
    // {
    //   name: 'Mix & Search (In-House)',
    //   description: 'Publish projects',
    //   icon: 'projectsIcon',
    //   product: 'https://templates-jira-console.kinemaster.com/',
    //   test: 'https://test-templates-jira-console.kinemaster.com/',
    //   popup: true,
    // },
    {
      name: 'Mix & Search Viewer',
      description: 'Mix & Search Viewer',
      icon: 'SvgMixSearch',
      product: 'https://project-search-view.kinemaster.com/home',
      test: 'https://test-project-search-view.kinemaster.com/home',
      popup: true,
    },
    // {
    //   name: 'Video Template Publishing Status (JIRA)',
    //   description: 'Video Template Publishing Status (JIRA)',
    //   icon: 'SvgVideo',
    //   product: `https://console.kinemaster.com/status`,
    //   test: `https://test-console.kinemaster.com/status`,
    //   // test: 'https://dvk0tlkcsml5l.cloudfront.net',
    //   popup: true,
    // },
    {
      name: 'Comments',
      description: 'Search and delete comments',
      icon: 'commentsIcon',
      product: 'https://project-search-view.kinemaster.com/',
      test: 'https://test-project-search-view.kinemaster.com/',
      // test: 'https://dvk0tlkcsml5l.cloudfront.net',
      popup: true,
    },
    {
      name: 'Forbidden words',
      description: 'Creation and deletion of forbidden words',
      icon: 'ImgForbiddenword',
      product: 'https://admin-blockword.kinemaster.com',
      test: 'https://test-admin-blockword.kinemaster.com',
      popup: true,
    },
    {
      name: 'Popular keywords',
      description: 'Creation and deletion of popular keywords',
      icon: 'ImgPopularKeyword',
      product: 'https://admin-top-searched.kinemaster.com',
      test: 'https://test-admin-top-searched.kinemaster.com',
      popup: true,
    },
  ],
};

const PromoCode = {
  title: 'License Code',
  contents: [
    {
      name: 'License Management System',
      description: 'Manage License Code',
      icon: 'SvgLicense',
      product: 'https://promo-console.kinemaster.com/promo',
      test: 'https://test-promo-console.kinemaster.com/promo',
      popup: true,
    },
    {
      name: 'License Code Registration',
      description: 'Register License Code',
      icon: 'SvgLicenseRegister',
      product: 'https://kinemaster.com/license',
      test: 'https://test-www.kinemaster.com/license',
      popup: true,
    },
    {
      name: 'Global Promo Code',
      description: 'Manage Global Promotion Code (Excluding China)',
      icon: 'SvgGlobalPromoCode',
      product: 'https://kmadmin.kinemaster.com/',
      test: '',
      popup: true,
    },
    {
      name: 'Promo Code Registration',
      description: 'Register Global Promotion Code',
      icon: 'SvgPromoCodeReg',
      product: 'https://promo.kinemaster.com/promo',
      test: '',
      popup: true,
    },
    // {
    //   name: 'China Promo Code',
    //   description: 'Manage Promotion Code for China Android version',
    //   icon: 'svgChinaPromoCode',
    //   product: 'http://promo-cn.nexstreaming.com/',
    //   test: '',
    //   popup: true,
    // },
  ],
};

const Receipt = {
  title: 'Receipt',
  contents: [
    {
      name: 'Receipt (Android)',
      description: 'Find receipt information stored in the Android server',
      icon: 'SvgReceiptAndroid',
      product:
        'https://search-payment-nyouc4e3ha5jcuesgsjhq5zddu.ap-northeast-2.es.amazonaws.com/_plugin/kibana/',
      test: '',
      popup: true,
    },
    {
      name: 'Receipt (iOS)',
      description: 'Find receipt information stored in the iOS server',
      icon: 'SvgReceiptIos',
      product:
        'https://search-receipt-dashboard-bsxxpaugppcnjtclnp37a7zbb4.ap-northeast-2.es.amazonaws.com/_plugin/kibana/app/kibana',
      test: '',
      popup: true,
    },
    {
      name: `Subscription Information (iOS)`,
      description: 'Find the Last receipt information stored in the iOS server',
      icon: 'SvgReceiptIos',
      product: `https://console.kinemaster.com/receipt`,
      test: `https://test-console.kinemaster.com/receipt`,
      popup: true,
    },
  ],
};

const Notice = {
  title: 'Notice',
  contents: [
    {
      name: 'Notice',
      description: 'Create and upload notices',
      icon: 'SvgNotice',
      product: 'https://admin-notice.kinemaster.com/',
      test: 'https://test-admin-notice.kinemaster.com/',
      popup: true,
    },
    {
      name: 'Notice Preview',
      description: 'Notice Preview',
      icon: 'SvgNoticePreview',
      product: 'https://console.kinemaster.com/noticepreview',
      test: 'https://test-console.kinemaster.com/noticepreview',
      popup: true,
    },
  ],
};
const SKU = {
  title: 'SKU',
  contents: [
    {
      name: 'SKU',
      description: 'Managing SKUs for apps like KineMaster, Sping, and others.',
      icon: 'imgSku',
      product: 'https://sku-console.kinemaster.com',
      test: 'https://test-sku-console.kinemaster.com/',
      popup: true,
    },
  ],
};

const DCI = {
  title: 'DCI',
  contents: [
    {
      name: 'DCI',
      description: 'Device Capability Interface',
      icon: 'SvgDci',
      product: 'https://dci-web.kinemaster.com/',
      test: 'http://test-dci-web.kinemaster.com/',
      popup: true,
    },
  ],
};

const FAQ = {
  title: 'FAQ',
  contents: [
    {
      name: 'FAQ',
      description: 'Create and upload FAQ',
      icon: 'SvgFaq',
      product:
        'https://kinemaster.zendesk.com/knowledge/arrange/?brand_id=1895',
      test: 'https://kinemaster1573111923.zendesk.com/knowledge/arrange/?brand_id=360000015721',
      // product: 'https://kinemaster.zendesk.com/',
      // test: 'https://kinemaster.zendesk.com/',
      popup: true,
    },
    {
      name: 'FAQ Preview',
      description: 'FAQ Preview',
      icon: 'SvgFAQPreview',
      product: `https://console.kinemaster.com/faqpreview`,
      test: 'https://test-console.kinemaster.com/faqpreview',
      // product: 'https://kinemaster.zendesk.com/',
      // test: 'https://kinemaster.zendesk.com/',
      popup: true,
    },
  ],
};

const Homepage = {
  title: 'Homepage',
  contents: [
    {
      name: 'KineMaster',
      description: 'Official Website',
      icon: 'SvgHomepage',
      product: 'http://www.kinemaster.com/',
      test: 'http://test-www.kinemaster.com',
      popup: true,
    },
    {
      name: 'Blog',
      description: 'Manage Blog Articles',
      icon: 'SvgBlog',
      product: 'https://blog-console.kinemaster.com',
      test: 'https://test-blog-console.kinemaster.com',
      popup: true,
    },
    {
      name: 'Purchase Inquiry',
      description: 'Manage Purchase Inquiries',
      icon: 'SvgPurchase',
      product: 'https://purchase-inquiry.kinemaster.com/',
      test: 'https://test-purchase-inquiry.kinemaster.com/',
      popup: true,
    },
    {
      name: 'AWS S3 Bucket Management',
      description:
        'Efficiently manage and optimize S3 buckets for streamlined data storage',
      icon: 'ImgS3',
      product: 'https://s3-console.kinemaster.com/',
      test: 'https://test-s3-console.kinemaster.com/',
      popup: true,
    },
    {
      name: 'Google Search Console',
      description: 'Google Search Console',
      icon: 'SvgGoogle',
      product: 'https://search.google.com/search-console',
      test: '',
      popup: true,
    },
    {
      name: 'Bing Webmaster Tools',
      description: 'Bing Webmaster Tools',
      icon: 'SvgBing',
      product: 'https://www.bing.com/webmasters/',
      test: '',
      popup: true,
    },
    {
      name: 'Naver Search Advisor',
      description: 'Naver Search Advisor',
      icon: 'SvgNaver',
      product: 'https://searchadvisor.naver.com/',
      test: '',
      popup: true,
    },
  ],
};

const Authentication = {
  title: 'Authentication',
  contents: [
    {
      name: 'Authentication',
      description:
        'Manage authentication and access for KineMaster Service Console',
      icon: 'SvgNauth',
      product: 'https://auth.nexstreaming.com',
      test: '',
      popup: true,
    },
    {
      name: 'Change Password',
      description: 'Change KineMaster Service Password',
      icon: 'SvgPassword',
      product: 'https://sso.kinemaster.com/',
      test: '',
      popup: true,
    },
  ],
};

const ShortLink = {
  title: 'Short URLs',
  contents: [
    {
      name: 'Firebase Dynamic Links',
      description: 'Dynamic Links Management Console',
      icon: 'SvgFirebase',
      product:
        'https://console.firebase.google.com/project/api-project-652995075284/durablelinks',
      test: '',
      popup: true,
    },
    {
      name: 'Yourls',
      description: 'URL Shortener',
      icon: 'SvgYourls',
      product: 'https://get.kine.to/admin/',
      test: '',
      popup: true,
    },
    {
      name: 'Dynamic Links Analytics',
      description: 'Find the Analytics Information',
      icon: 'SvgDashboard',
      product: 'http://console.kinemaster.com/shorturl',
      // test: 'http://test-console.kinemaster.com/shorturl',
      popup: true,
    },
  ],
};

export const Links: any = [
  MixAndSearch,
  Homepage,
  Account,
  PromoCode,
  ShortLink,
  Receipt,
  Asset,
  Notice,
  FAQ,
  SKU,
  DCI,
  Authentication,
];
