import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgPlay = (props: Props) => (
  <svg {...props} viewBox="0 0 22 26">
    <defs>
      <filter
        id="2r73919yua"
        width="128.6%"
        height="125%"
        x="-14.3%"
        y="-12.5%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius="1"
          result="shadowSpreadOuter1"
        />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
      </filter>
      <path
        id="n2vey07p1b"
        d="M20.64 11.43L3.224 1.245C2.03.548.536 1.42.536 2.818v20.367c0 1.393 1.494 2.27 2.688 1.568L20.64 14.572c1.194-.697 1.194-2.445 0-3.142"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <g>
          <g transform="translate(-325 -175) translate(304 158) translate(21 17)">
            <use fill="#FFF" xlinkHref="#n2vey07p1b" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgPlay;
