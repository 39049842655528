import styled from 'styled-components';
import { IconButton, Paper, Grid } from '@mui/material';

export const Container = styled.div``;

export const ReceiptGrid = styled(Grid)`
  padding-top: 90px;
  height:100vh;
  background-color: #fff;
`

export const ReceiptInformation = styled.div`
  flex: 1;
  padding-left: 50px;
  padding-right: 50px;
  width:calc(100vw - 100px);
`;

export const ReceiptPaper = styled(Paper)`
  padding: 25px;
  // width: 100%;
  max-width: 600px;
  margin: 60px auto 0;
  background: linear-gradient(
      0deg,
      rgba(103, 80, 164, 0.05),
      rgba(103, 80, 164, 0.05)
    ),
    #ffffff;
  dl {
    display: table;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    width: 100%;
  }
  dl:last-child {
    border: none;
  }
  dt {
    display: table-cell;
    font-weight: 600;
    width: 200px;
    color: rgb(103, 80, 164);
  }
  dd {
    display: table-cell;
    text-align: center;
  }
`;

export const InputButtonWrap = styled.div`
  display: flex;
`;

export const SearchGrid = styled(Grid)`
  flex: 1;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const SearchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  padding: 3px 3px 3px 16px;
  border-radius: 100px;
  background: linear-gradient(
      0deg,
      rgba(103, 80, 164, 0.05),
      rgba(103, 80, 164, 0.05)
    ),
    #fffbfe;
`;

export const SearchInput = styled.input`
  border: 0;
  padding: 0;
  color: #000;
  width: 100%;
  font-size: inherit;
  caret-color: #6750a4;
  background-color: transparent;
`;

export const SearchForm = styled.form`
  width: 100%;
  position: relative;
  .alert-message {
    position: absolute;
    width: 100%;
  }
`;

export const MuiIconButton = styled(IconButton)`
  font-size: 0;
  &.MuiButtonBase-root {
    padding: 0px;
  }
`;
