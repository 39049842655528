import styled from 'styled-components';
import { 
  Box as MuiBox,
  TextField as MuiTextField,
  Divider as MuiDivider
} from "@mui/material";

export const Container = styled.div``;

export const Box = styled(MuiBox)`
  &.MuiBox-root {
    width:auto;
    display:flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`;

export const Search = styled(MuiBox)`
  &.MuiBox-root {
    padding-left: 16px;
    width: 388px;
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(0deg, rgba(103, 80, 164, 0.05), rgba(103, 80, 164, 0.05));
    border-radius: 22px;
  }
`;

export const TextField = styled(MuiTextField)`
  &.MuiTextField-root{
    height:inherit;
    border:none !important;
    flex-direction:row;
    width:310px !important;
    max-width:100%;
  }
  & .MuiInputBase-input{
    height:inherit;
    border:none !important;
    padding:0;
    width:300px;
  }
  & .MuiOutlinedInput-notchedOutline{
    border:none;
  }
`;

export const Button = styled(MuiBox)`
  &.MuiBox-root {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background: #EADDFF;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const Wrap = styled(MuiBox)`
  &.MuiBox-root {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Divider = styled(MuiDivider)`
  &.MuiDivider-root {
    background: rgba(33, 33, 33, 0.08);
    width: calc(100% - 96px);
    margin: 24px 0;
  }
`;

export const MessageBox = styled(MuiBox)`
  &.MuiBox-root {
    display:flex;
    justify-contents: center;
    align-items: center;
    width: 350px;
    height: 32px;
    border-radius: 16px;
    background: linear-gradient(0deg, rgba(103, 80, 164, 0.05), rgba(103, 80, 164, 0.05));
  }
`;