import React from 'react';
import { observer } from 'mobx-react';
import { 
  StyledContents, StyledContentsTitle, StyledDetailBox, StyledAnchorText,
  StyledDetailRows, StyledDetailTableHead, StyledDetailTableCell, StyledAnchorBox
} from './style';
import { useStore } from '../../store'

export default observer(() => {
  const {
    statusStore:{
      getStatus
    }
  } = useStore();
  
  const TaskList = (array:any) => {
    const baseUrl = 'https://kinemastercorp.atlassian.net/browse/'
    return (
      array?.map((task : string, index:number) => 
        <StyledAnchorBox key={task}>
          <StyledAnchorText 
            href={`${baseUrl}${task}`}
            target="_blank"
          >
            {`${task}`}
          </StyledAnchorText>
          {index+1===array.length ? '' : ','}
        </StyledAnchorBox>
      )
    )
  }

  return (
    <StyledContents>
      <StyledContentsTitle>Published Results</StyledContentsTitle>
      <StyledDetailBox>
        <StyledDetailRows>
          <StyledDetailTableHead>Published By</StyledDetailTableHead>
          <StyledDetailTableCell>{getStatus.details.action}</StyledDetailTableCell>
        </StyledDetailRows>
        <StyledDetailRows>
          <StyledDetailTableHead>Start</StyledDetailTableHead>
          <StyledDetailTableCell>{getStatus.details.startAt}</StyledDetailTableCell>
        </StyledDetailRows>
        <StyledDetailRows>
          <StyledDetailTableHead>End</StyledDetailTableHead>
          <StyledDetailTableCell>{getStatus.createdAt}</StyledDetailTableCell>
        </StyledDetailRows>
        <StyledDetailRows>
          <StyledDetailTableHead>Total</StyledDetailTableHead>
          <StyledDetailTableCell>{getStatus.details.total}</StyledDetailTableCell>
        </StyledDetailRows>
        <StyledDetailRows className='success'>
          <StyledDetailTableHead className='success'>Success({getStatus.details.success})</StyledDetailTableHead>
          <StyledDetailTableCell>{TaskList(getStatus.details.succeed)}</StyledDetailTableCell>
        </StyledDetailRows>
        <StyledDetailRows className='fail'>
          <StyledDetailTableHead className='fail'>Fail({getStatus.details.fail})</StyledDetailTableHead>
          <StyledDetailTableCell>{TaskList(getStatus.details.failed)}</StyledDetailTableCell>
        </StyledDetailRows>
      </StyledDetailBox>
    </StyledContents>
  )
});
