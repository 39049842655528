import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgAssetStoreWebViewer = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78 18H18C15.24 18 13 20.24 13 23V73C13 75.76 15.24 78 18 78H78C80.76 78 83 75.76 83 73V23C83 20.24 80.76 18 78 18Z"
      fill="#99E97D"
    />
    <path
      d="M18 19H78C80.2077 19 82 20.7923 82 23V73C82 75.2077 80.2077 77 78 77H18C15.7923 77 14 75.2077 14 73V23C14 20.7923 15.7923 19 18 19Z"
      stroke="url(#paint0_linear_51736_10532)"
      strokeOpacity="0.1"
      strokeWidth="2"
    />
    <g filter="">
      <path
        d="M74.97 72.0001H21.03C19.91 72.0001 19 71.2001 19 70.2101V37.6001C19 36.6101 19.91 35.8101 21.03 35.8101H74.97C76.09 35.8101 77 36.6101 77 37.6001V70.2101C77 71.2001 76.09 72.0001 74.97 72.0001Z"
        fill="url(#paint1_linear_51736_10532)"
        shapeRendering="geometricPrecision"
      />
    </g>
    <g filter="">
      <path
        d="M38.06 29.8098H21.9C20.3 29.8098 19 28.5098 19 26.9098C19 25.3098 20.3 24.0098 21.9 24.0098H38.06C39.66 24.0098 40.96 25.3098 40.96 26.9098C40.96 28.5098 39.66 29.8098 38.06 29.8098Z"
        fill="url(#paint2_linear_51736_10532)"
        shapeRendering="geometricPrecision"
      />
    </g>
    <g filter="">
      <path
        d="M48.0001 29.8C49.6017 29.8 50.9001 28.5016 50.9001 26.9C50.9001 25.2984 49.6017 24 48.0001 24C46.3985 24 45.1001 25.2984 45.1001 26.9C45.1001 28.5016 46.3985 29.8 48.0001 29.8Z"
        fill="url(#paint3_linear_51736_10532)"
        shapeRendering="geometricPrecision"
      />
    </g>
    <path
      d="M60.34 50.3699L56.77 44.8199C56.51 44.4099 56.06 44.1699 55.58 44.1699H40.41C39.93 44.1699 39.48 44.4199 39.22 44.8199L35.66 50.3699C34.96 51.4499 34.81 53.3099 35.47 54.4199C36.13 55.5299 37.29 56.1899 38.6 56.1899C40.1 56.1899 41.43 55.2699 41.98 53.8799C42.13 54.2499 42.32 54.6099 42.59 54.9199C43.29 55.7299 44.31 56.1899 45.38 56.1899C46.45 56.1899 47.34 55.7599 48.01 55.0799C48.68 55.7599 49.61 56.1899 50.64 56.1899C51.67 56.1899 52.73 55.7299 53.43 54.9199C53.7 54.6099 53.89 54.2499 54.04 53.8799C54.59 55.2699 55.92 56.1899 57.42 56.1899C58.73 56.1899 59.88 55.5299 60.55 54.4199C61.22 53.3099 61.06 51.4499 60.36 50.3699H60.34Z"
      fill="#99E97D"
    />
    <path
      d="M57.19 66.0899H38.81C38.22 66.0899 37.75 65.6099 37.75 65.0299V55.1299H39.87V63.9699H56.14V55.1299H58.26V65.0299C58.26 65.6199 57.78 66.0899 57.2 66.0899H57.19Z"
      fill="#99E97D"
    />
    <path
      d="M52.6001 61.8397H43.4101C42.8201 61.8397 42.3501 61.3597 42.3501 60.7797C42.3501 60.1997 42.8301 59.7197 43.4101 59.7197H52.6001C53.1901 59.7197 53.6601 60.1997 53.6601 60.7797C53.6601 61.3597 53.1801 61.8397 52.6001 61.8397Z"
      fill="#99E97D"
    />
    <defs>
      <filter
        id="filter0_di_51736_10532"
        x="19"
        y="35.8101"
        width="58"
        height="37.1899"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10532"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10532"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10532"
        />
      </filter>
      <filter
        id="filter1_di_51736_10532"
        x="19"
        y="24.0098"
        width="21.96"
        height="6.7998"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10532"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10532"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10532"
        />
      </filter>
      <filter
        id="filter2_di_51736_10532"
        x="45.1001"
        y="24"
        width="5.80005"
        height="6.7998"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_10532"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_10532"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_10532"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51736_10532"
        x1="48"
        y1="18"
        x2="48"
        y2="78"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_51736_10532"
        x1="48"
        y1="35.8101"
        x2="48"
        y2="72.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_51736_10532"
        x1="29.98"
        y1="24.0098"
        x2="29.98"
        y2="29.8098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_51736_10532"
        x1="48.0001"
        y1="24"
        x2="48.0001"
        y2="29.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgAssetStoreWebViewer;
