import styled from 'styled-components';
import Wrap from '@mui/material/Accordion';
import Summary from '@mui/material/AccordionSummary';
import Details from '@mui/material/AccordionDetails';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

export const Accordion = styled(Wrap)`
  border:1.22px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow:0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  border-radius: 12.2px !important;
  &:before{
    background-color: transparent !important;
  }
  &.customExpended{
    height:100%;
  }
`;

export const AccordionSummary = styled(Summary)<{expanded?:string}>`
  border:none !important;
  border-radius: ${(props) => props.expanded ==='none' ? '12.2px' : '12.2px 12.2px 0 0'} !important;
  font-size:16px;
  height:72px;
  padding-left:24px !important;
  &:hover{
    background-color : #F6F6F6;
    & .icon{
      background-color : #D9D9D9;
    }
  }
  &.none-link {
    opacity: 0.5;
    cursor: inherit;
    svg {
      filter: grayscale(1);
    }
  }
`;

export const AccordionDetails = styled(Details)`
  width:100%;
  height:auto;
  padding:0 !important;
`;

export const AccordionDetail = styled.a`
  height:auto;
  display:flex;
  justify-contents: left;
  align-items: center;
  padding: 10px 12px;
  border-radius: 12.2px;
  &.none-link {
    opacity: 0.5;
    cursor: default;
    svg {
      filter: grayscale(1);
    }
  }
  &:hover{
    background-color : #F6F6F6;
  }
`;

export const LinkAccordionDetail = styled(Link)`
  height:auto;
  display:flex;
  justify-contents: left;
  align-items: center;
  margin-top:11px;
  padding-top:10px;
  padding-left:10px;
  padding-bottom:10px;
  &.none-link {
    opacity: 0.5;
    cursor: default;
    svg {
      filter: grayscale(1);
    }
  }
`;

export const AccordionDetailContent = styled.div`
  display:block;
  text-align:left;
  margin-left:10px;
  
  & .MuiTypography-root{
    line-height:1;
  } 
`;

export const AccordionDetailName = styled(Typography)`
  font-size:16px;
  font-weight:500;
  padding-bottom:4px;
`;

export const AccordionDetailDesc = styled(Typography)`
  color:#787579;
  font-weight:400;
  font-size:12px !important;
`;

export const DivisionLine = styled.hr`
  width: calc(100% - 18px);
  border: 1.22px solid rgba(0, 0, 0, 0.2);
  border-top:none;
  border-left:none;
  border-right:none;
  margin:0;
  margin-left:18px;
  padding:0;
`;

export const SingleAccordion = styled.a`
  display:flex;
  border:1.22px solid rgba(0, 0, 0, 0.2) !important;
  box-shadow:0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  border-radius: 12.2px;
  justify-contents: left;
  align-items: center;
  padding-left:12px;
  background-color:white;
  height:72px;
  &.none-link {
    opacity: 0.5;
    cursor: inherit;
    svg {
      filter: grayscale(1);
    }
  }
  &:hover{
    background-color : #F6F6F6;
  }
`;

export const SingleAccordionContent = styled.div`
  display:block;
  text-align:left;
  margin-left:10px;
  & .MuiTypography-root{
    line-height:1;
  } 
`;

export const SingleAccordionName = styled(Typography)`
  font-size:16px;
  font-weight:500;
  padding-bottom:4px;
`;

export const SingleAccordionDesc = styled(Typography)`
  padding-right:12px;
  color:#787579;
  font-size:12px !important;
  font-weight:400;
`;

export const Container = styled.div`

`;

export const IconWrap = styled.div`
  min-width:52px;
  min-height:52px;
  display:flex;
  justify-content: center;
  align-items: center;
`;

export const ExpandIconWrap = styled.div`
  display:flex;
  align-Items:center;
  justify-content:center;
  width:50px;
  height:50px;
  border-radius:50%;
`