import React from 'react';
import { signOut } from '../../utils/nauth';
import { LogoutButton } from './style';

export default () => {
  const logoutHandler = () => {
    signOut();
  }
  const buttonText = 'Log out';
  return (
    <LogoutButton 
      onClick={logoutHandler}
    >
      {buttonText}
    </LogoutButton>
  )
}