import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgReceiptAndroid = (props: Props) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_51736_9998)">
      <path
        d="M66.5 12.5H19.5C16.1863 12.5 13.5 15.1863 13.5 18.5C13.5 21.8137 16.1863 24.5 19.5 24.5H66.5C69.8137 24.5 72.5 21.8137 72.5 18.5C72.5 15.1863 69.8137 12.5 66.5 12.5Z"
        fill="#BAA675"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 14C17.0147 14 15 16.0147 15 18.5C15 20.9853 17.0147 23 19.5 23H66.5C68.9853 23 71 20.9853 71 18.5C71 16.0147 68.9853 14 66.5 14H19.5ZM12 18.5C12 14.3579 15.3579 11 19.5 11H66.5C70.6421 11 74 14.3579 74 18.5C74 22.6421 70.6421 26 66.5 26H19.5C15.3579 26 12 22.6421 12 18.5Z"
        fill="#E3C993"
      />
      <path
        d="M64.9102 17.71H20.9102C19.8056 17.71 18.9102 18.6054 18.9102 19.71V75.71C18.9102 76.8145 19.8056 77.71 20.9102 77.71H64.9102C66.0147 77.71 66.9102 76.8145 66.9102 75.71V19.71C66.9102 18.6054 66.0147 17.71 64.9102 17.71Z"
        fill="#E9E1BB"
      />
      <path
        d="M20.9102 18.71H64.9102C65.4624 18.71 65.9102 19.1577 65.9102 19.71V75.71C65.9102 76.2622 65.4624 76.71 64.9102 76.71H20.9102C20.3579 76.71 19.9102 76.2622 19.9102 75.71V19.71C19.9102 19.1577 20.3579 18.71 20.9102 18.71Z"
        stroke="url(#paint0_linear_51736_9998)"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g filter="">
        <path
          d="M56.9102 33H28.9102C27.8056 33 26.9102 33.8954 26.9102 35C26.9102 36.1046 27.8056 37 28.9102 37H56.9102C58.0147 37 58.9102 36.1046 58.9102 35C58.9102 33.8954 58.0147 33 56.9102 33Z"
          fill="url(#paint1_linear_51736_9998)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M28.9102 33.5H56.9102C57.7386 33.5 58.4102 34.1716 58.4102 35C58.4102 35.8284 57.7386 36.5 56.9102 36.5H28.9102C28.0817 36.5 27.4102 35.8284 27.4102 35C27.4102 34.1716 28.0817 33.5 28.9102 33.5Z"
          stroke="url(#paint2_linear_51736_9998)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <g filter="">
        <path
          d="M57 45H29C27.8954 45 27 45.8954 27 47C27 48.1046 27.8954 49 29 49H57C58.1046 49 59 48.1046 59 47C59 45.8954 58.1046 45 57 45Z"
          fill="url(#paint3_linear_51736_9998)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M29 45.5H57C57.8284 45.5 58.5 46.1716 58.5 47C58.5 47.8284 57.8284 48.5 57 48.5H29C28.1716 48.5 27.5 47.8284 27.5 47C27.5 46.1716 28.1716 45.5 29 45.5Z"
          stroke="url(#paint4_linear_51736_9998)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <g filter="">
        <path
          d="M57 57H29C27.8954 57 27 57.8954 27 59C27 60.1046 27.8954 61 29 61H57C58.1046 61 59 60.1046 59 59C59 57.8954 58.1046 57 57 57Z"
          fill="url(#paint5_linear_51736_9998)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M29 57.5H57C57.8284 57.5 58.5 58.1716 58.5 59C58.5 59.8284 57.8284 60.5 57 60.5H29C28.1716 60.5 27.5 59.8284 27.5 59C27.5 58.1716 28.1716 57.5 29 57.5Z"
          stroke="url(#paint6_linear_51736_9998)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
      <path
        d="M83.5601 69.4502C83.5601 60.6136 76.3966 53.4502 67.5601 53.4502C58.7235 53.4502 51.5601 60.6136 51.5601 69.4502C51.5601 78.2868 58.7235 85.4502 67.5601 85.4502C76.3966 85.4502 83.5601 78.2868 83.5601 69.4502Z"
        fill="#3DDC84"
      />
      <path
        d="M67.5601 54.4502C75.8443 54.4502 82.5601 61.1659 82.5601 69.4502C82.5601 77.7345 75.8443 84.4502 67.5601 84.4502C59.2758 84.4502 52.5601 77.7345 52.5601 69.4502C52.5601 61.1659 59.2758 54.4502 67.5601 54.4502Z"
        stroke="url(#paint7_linear_51736_9998)"
        strokeOpacity="0.1"
        strokeWidth="2"
      />
      <g filter="">
        <path
          d="M72.4401 67.24L74.1001 64.36C74.1401 64.28 74.1601 64.19 74.1301 64.1C74.1101 64.01 74.0501 63.94 73.9701 63.89C73.8901 63.84 73.8001 63.83 73.7101 63.85C73.6201 63.87 73.5501 63.93 73.5001 64.01L71.8201 66.92C70.4801 66.32 69.0301 66 67.5601 66C66.0901 66 64.6401 66.31 63.3001 66.92L61.6201 64.01C61.5701 63.93 61.5001 63.88 61.4101 63.85C61.3201 63.83 61.2301 63.85 61.1501 63.89C61.0701 63.94 61.0101 64.01 60.9901 64.1C60.9701 64.19 60.9801 64.28 61.0201 64.36L62.6801 67.24C59.8201 68.79 57.8801 71.68 57.6001 75.08H77.4901C77.2101 71.68 75.2601 68.79 72.4301 67.24H72.4401ZM62.9901 72.24C62.8301 72.24 62.6701 72.19 62.5301 72.1C62.3901 72.01 62.2901 71.88 62.2301 71.73C62.1701 71.58 62.1501 71.41 62.1801 71.25C62.2101 71.09 62.2901 70.94 62.4101 70.83C62.5301 70.71 62.6701 70.64 62.8301 70.6C62.9901 70.57 63.1601 70.58 63.3101 70.65C63.4601 70.71 63.5901 70.82 63.6801 70.96C63.7701 71.1 63.8201 71.26 63.8201 71.42C63.8201 71.64 63.7301 71.85 63.5801 72.01C63.4301 72.17 63.2101 72.25 63.0001 72.25L62.9901 72.24ZM72.1501 72.24C71.9901 72.24 71.8301 72.19 71.6901 72.1C71.5501 72.01 71.4501 71.88 71.3901 71.73C71.3301 71.58 71.3101 71.41 71.3401 71.25C71.3701 71.09 71.4501 70.94 71.5701 70.83C71.6901 70.71 71.8301 70.64 71.9901 70.6C72.1501 70.57 72.3201 70.58 72.4701 70.65C72.6201 70.71 72.7501 70.82 72.8401 70.96C72.9301 71.1 72.9801 71.26 72.9801 71.42C72.9801 71.64 72.8901 71.85 72.7401 72.01C72.5901 72.17 72.3701 72.25 72.1601 72.25L72.1501 72.24Z"
          fill="url(#paint8_linear_51736_9998)"
          shapeRendering="geometricPrecision"
        />
        <path
          d="M72.1899 67.6785C74.7232 69.066 76.5146 71.5858 76.9319 74.58H58.1582C58.5747 71.5855 60.3587 69.0668 62.9183 67.6796L63.3385 67.4519L63.5073 67.3751C64.7813 66.7951 66.1606 66.5 67.5601 66.5C68.9574 66.5 70.3379 66.8042 71.6158 67.3763L72.0275 67.5607L72.2531 67.17L72.2695 67.1416L72.3199 67.1707L72.1899 67.6785ZM62.738 70.1086L62.7233 70.1113L62.7088 70.1149C62.4802 70.1721 62.2574 70.2786 62.0641 70.469C61.8644 70.6554 61.7368 70.9013 61.6887 71.1578C61.6399 71.4177 61.6731 71.6839 61.7659 71.9157C61.8604 72.1521 62.0235 72.3687 62.2597 72.5206C62.4111 72.6179 62.5821 72.6874 62.7627 72.7197L62.793 72.75H63.0001C63.3271 72.75 63.6867 72.6273 63.9449 72.352C64.1744 72.1071 64.3201 71.7773 64.3201 71.42C64.3201 71.1561 64.2381 70.9033 64.1007 70.6896C63.9602 70.4711 63.7551 70.2928 63.5087 70.191C63.2476 70.073 62.9725 70.0646 62.738 70.1086ZM71.898 70.1086L71.8833 70.1113L71.8688 70.1149C71.6402 70.1721 71.4174 70.2786 71.2241 70.4689C71.0244 70.6554 70.8968 70.9013 70.8487 71.1578C70.7999 71.4177 70.8331 71.6839 70.9259 71.9157C71.0204 72.1521 71.1835 72.3687 71.4197 72.5206C71.5711 72.6179 71.7421 72.6874 71.9227 72.7197L71.953 72.75H72.1601C72.4871 72.75 72.8467 72.6273 73.1049 72.352C73.3344 72.1071 73.4801 71.7773 73.4801 71.42C73.4801 71.1561 73.3981 70.9033 73.2607 70.6896C73.1202 70.4711 72.9151 70.2928 72.6687 70.191C72.4076 70.073 72.1325 70.0646 71.898 70.1086Z"
          stroke="url(#paint9_linear_51736_9998)"
          strokeOpacity="0.1"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_di_51736_9998"
        x="26.9102"
        y="33"
        width="32"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_9998"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_9998"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_9998"
        />
      </filter>
      <filter
        id="filter1_di_51736_9998"
        x="27"
        y="45"
        width="32"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_9998"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_9998"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_9998"
        />
      </filter>
      <filter
        id="filter2_di_51736_9998"
        x="27"
        y="57"
        width="32"
        height="5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_9998"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_9998"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_9998"
        />
      </filter>
      <filter
        id="filter3_di_51736_9998"
        x="57.6001"
        y="63.8403"
        width="19.8901"
        height="12.2397"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51736_9998"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51736_9998"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_51736_9998"
        />
      </filter>
      <linearGradient
        id="paint0_linear_51736_9998"
        x1="42.9102"
        y1="17.71"
        x2="42.9102"
        y2="77.71"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_51736_9998"
        x1="42.9102"
        y1="33"
        x2="42.9102"
        y2="37"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_51736_9998"
        x1="42.9102"
        y1="33"
        x2="42.9102"
        y2="37"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_51736_9998"
        x1="43"
        y1="45"
        x2="43"
        y2="49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_51736_9998"
        x1="43"
        y1="45"
        x2="43"
        y2="49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_51736_9998"
        x1="43"
        y1="57"
        x2="43"
        y2="61"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_51736_9998"
        x1="43"
        y1="57"
        x2="43"
        y2="61"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_51736_9998"
        x1="67.5601"
        y1="53.4502"
        x2="67.5601"
        y2="85.4502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_51736_9998"
        x1="67.5451"
        y1="63.8403"
        x2="67.5451"
        y2="75.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.9" />
        <stop offset="1" stopColor="white" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_51736_9998"
        x1="67.5451"
        y1="63.8403"
        x2="67.5451"
        y2="75.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_51736_9998">
        <rect
          width="71.56"
          height="74.45"
          fill="white"
          transform="translate(12 11)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgReceiptAndroid;
