import React from 'react';
import { Typography } from '@mui/material';
import { MessageBox } from './style';

export const ErrorMessage = ({ errorMessage } : { errorMessage : string}) => {
	return (
		<MessageBox>
			<Typography 
				sx={{ 
					width: '100%', 
					textAlign: 'center',
					color: 'red'
				}}
			>
				{`${errorMessage}`}
			</Typography>
		</MessageBox>
	)
};

export default ErrorMessage;
