import { RefObject } from "react";
import { makeObservable, observable, action, computed } from 'mobx';
import { ReceiptData, SearchData } from "../pages/Receipt/types";

const initVendorData = {
  vendorId: '-',
  transactionId: '-',
  productId: '-',
  purchaseDate: '-',
  expireDate: '-',
  status: '-',
};

export default class ReceiptStore {
  constructor() {
    makeObservable(this, {
      receiptData: observable,
      searchData: observable,
      visibility:computed,
      receiptContents:computed,
    });
  }

  receiptData : ReceiptData = {
    vendorId: '-',
    transactionId: '-',
    productId: '-',
    purchaseDate: '-',
    expireDate: '-',
    status: '-',
  }

  searchData : SearchData = {
    isVendorId : false,
    inputRef: null,
    errorMessage: '',
    searchValue: ''
  }
  
  get visibility() {
    return this.searchData.searchValue ? 'visible' : 'hidden';
  }
  
  setReceiptData = action(( inputData : ReceiptData ) => {
    this.receiptData = inputData;
  })

  setSearchData = {
    setIsVendorId : action (( inputData : boolean ) => {
      this.searchData.isVendorId = inputData;
    }),
    setInputRef : action(( inputData : RefObject<HTMLInputElement> | null) => {
      this.searchData.inputRef = inputData;
    }),
    setErrorMessage : action(( inputData : string | undefined ) => {
      this.searchData.errorMessage = inputData;
    }),
    setSearchValue : action(( inputData : string ) => {
      this.searchData.searchValue = inputData;
    })
  }

  onSubmit = action(async (e: React.FormEvent) => {
    e.preventDefault();
    const { inputRef, searchValue } = this.searchData;
    const { setSearchValue, setIsVendorId, setErrorMessage} = this.setSearchData;
    const vendorId = inputRef?.current?.value.trim() ?? '';
    setSearchValue(vendorId);
    // const serverType = server;
    const baseUrl = process.env.REACT_APP_RECEIPT_BASE_URL;
    const ReceiptUrl = process.env.REACT_APP_RECEIPT_VENDORS_URL;
    const result = await fetch(`${baseUrl}${ReceiptUrl}/${searchValue}`);

    if (result.status === 200) {
      await result.json().then((res) => {
        this.setReceiptData(res);
        setIsVendorId(false);
      });
    } else {
      await result.json().then((res) => {
        this.setReceiptData(initVendorData);
        setIsVendorId(true);
        if (res.errorCode === 'NOT_FOUND') {
          setErrorMessage("Can't find subscription information");
        } else {
          setErrorMessage(res.errorMessage);
        }
      });
    }
  })

  onClick = action(() => {
    this.setSearchData.setSearchValue('');
  })

  onChange = action((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target?.value;
    this.setSearchData.setSearchValue(value);
  })

  receiptStatus = action(() => {
    const inputStatus = this.receiptData.status;
    if (inputStatus === '-') { return inputStatus; } 
    else if (inputStatus === false) { return '구독만료'; } 
    else { return '구독중'; }
  })

  get receiptContents() {
    return [
      {
        name:'Vender Id',
        value:this.receiptData.vendorId
      },
      {
        name:'Transaction Id',
        value:this.receiptData.transactionId
      },
      {
        name:'Product Id',
        value:this.receiptData.productId
      },
      {
        name:'Purchase Date',
        value:this.receiptData.purchaseDate
      },
      {
        name:'Expire Date',
        value:this.receiptData.expireDate
      },
      {
        name:'Status',
        value:this.receiptStatus()
      },
    ]
  };
}
