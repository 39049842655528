
export const getStatusList = ({
  startedAt,
  endedAt,
  page,
  display,
}:any) => {
  
  const ntoken = localStorage.getItem("ntoken");
  const baseUrl = process.env.REACT_APP_HISTORY_BASE_URL;
  const historyUrl = process.env.REACT_APP_HISTORY_URL;
  const serviceName = process.env.REACT_APP_HISTORY_STATUS_SERVICE_NAME??'';

  const params = new URLSearchParams({
    serviceName,
    startedAt,
    endedAt,
    page,
    display
  }).toString();

  const fetchUrl = `${baseUrl}${historyUrl}?${params}`

  const option = {
    headers:{
      Authorization: `Bearer ${ntoken}`,
    }
  };

  const sendRequest = async () => {
    const response = await fetch(fetchUrl, option);
    if(!response.ok){
      throw new Error('could not get data');
    }
    const data = await response.json();
    return data;
  }
  
  try {
    const statusData = sendRequest();
    return statusData;
  }catch (error) {
    return error;
  }finally{
    localStorage.removeItem('display');
  }
};

export const getDynamicLinkAnalytics = (
  link: string,
  duration = 365
) => {
  const encodeURL = encodeURIComponent(link);

  const ntoken = localStorage.getItem("ntoken");
  const fetchUrl = `https://api-account-console.kinemasters.com/link/statistics/${encodeURL}?durationDays=${duration}`

  const option = {
    headers:{
      Authorization: `Bearer ${ntoken}`,
    }
  };

  const sendRequest = async () => {
    const response = await fetch(fetchUrl, option);
    if(!response.ok){
      throw new Error('could not get data');
    }
    const data = await response.json();
    return data;
  }

  try {
    const data = sendRequest();
    return data;
  }catch (error) {
    return error;
  }finally{
    localStorage.removeItem('display');
  }
}