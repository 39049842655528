import React from "react";
import RateReviewIcon from '@mui/icons-material/RateReview';

interface Props {
  width: number;
  height: number;
}

const SvgBlog = () => (
  <RateReviewIcon fontSize="large" />
);

export default SvgBlog;