import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from 'mobx';
import { UserCount } from '../components/Header/type';

const SECTION_NAME = {
  TOTAL: 'Members',
  ACTIVATED: 'Activated Memebers',
  SUSPENDED: 'Suspended Members',
  DELETED: 'Deleted Members',
  DELETED_BYUSER: 'To be deleted Members (by Member)',
  DELETED_BYADMIN: 'To be deleted Members (by Admin)',
};

export default class DashboardStore {
  constructor() {
    makeObservable(this, {
      latestDate: observable,
      userCount: observable,
      getLocaleString: computed,
      // sectionLabels: observable,
    });
  }

  latestDate = '';

  userCount: any = {
    users: [
      {
        count: 1461,
        name: 'ACTIVATED',
      },
      {
        count: 1152,
        name: 'DELETED',
      },
      {
        count: 5,
        name: 'SUSPENDED',
      },
    ],
    badges: [
      {
        count: 0,
        name: 'OFFICIAL_CHANNEL',
        image: 'https://resource.kinemaster.com/image/official_badge.png',
      },
      {
        count: 0,
        name: 'POWER_CREATOR_GOLD',
        image:
          'https://resource.kinemaster.com/image/power_creator_badge_gold.png',
      },
      {
        count: 0,
        name: 'POWER_CREATOR_BLACK',
        image:
          'https://resource.kinemaster.com/image/power_creator_badge_black.png',
      },
      {
        count: 0,
        name: 'POWER_CREATOR_PURPLE',
        image:
          'https://resource.kinemaster.com/image/power_creator_badge_purple.png',
      },
      {
        count: 0,
        name: 'POWER_CREATOR_ORANGE',
        image:
          'https://resource.kinemaster.com/image/power_creator_badge_orange.png',
      },
      {
        count: 0,
        name: 'POWER_CREATOR_BLUE',
        image:
          'https://resource.kinemaster.com/image/power_creator_badge_blue.png',
      },
      {
        count: 0,
        name: 'POWER_CREATOR_GREEN',
        image:
          'https://resource.kinemaster.com/image/power_creator_badge_green.png',
      },
      {
        count: 0,
        name: 'CERTIFIED_INSTRUCTOR',
        image: 'https://resource.kinemaster.com/image/certified_badge.png',
      },
      {
        count: 0,
        name: 'SUPPORTER',
        image: 'https://resource.kinemaster.com/image/support_badge.png',
      },
      {
        count: 0,
        name: 'GOLD',
        image: 'https://resource.kinemaster.com/image/k-awards-gold.png',
      },
      {
        count: 0,
        name: 'SILVER',
        image: 'https://resource.kinemaster.com/image/k-awards-silver.png',
      },
      {
        count: 0,
        name: 'BRONZE',
        image: 'https://resource.kinemaster.com/image/k-awards-bronze.png',
      },
      {
        count: 0,
        name: 'KINEMASTER_CHALLENGE',
        image: 'https://resource.kinemaster.com/image/ic_badge_challenge.png',
      },
      {
        count: 0,
        name: 'SPRING_CHALLENGE',
        image: 'https://resource.kinemaster.com/image/SP_Challenge.png',
      },
    ],
  };

  sectionLabels = [
    SECTION_NAME.TOTAL,
    SECTION_NAME.ACTIVATED,
    SECTION_NAME.SUSPENDED,
    SECTION_NAME.DELETED_BYUSER,
    SECTION_NAME.DELETED_BYADMIN,
    SECTION_NAME.DELETED,
  ];

  setUserCount = action((res: UserCount) => {
    this.userCount = res;
  });

  isLoading = true;

  sectionValue = action((label: string) => {
    switch (label) {
      case SECTION_NAME.TOTAL:
        return this.getLocaleString.allUserCount;
      case SECTION_NAME.ACTIVATED:
        return this.getLocaleString.activatedUserCount;
      case SECTION_NAME.SUSPENDED:
        return this.getLocaleString.suspendedUserCount;
      case SECTION_NAME.DELETED:
        return this.getLocaleString.deletedUserCount;
      case SECTION_NAME.DELETED_BYUSER:
        return this.getLocaleString.toBeDeletedByUserCount;
      case SECTION_NAME.DELETED_BYADMIN:
        return this.getLocaleString.toBeDeletedByAdminCount;
      default:
        return 0;
    }
  });

  userUpdate = action(async () => {
    this.isLoading = true;

    const baseUrl = process.env.REACT_APP_DASHBOARD_BASE_URL;
    const userCountUrl = process.env.REACT_APP_DASHBOARD_USER_COUNT_URL;
    const data = await fetch(`${baseUrl}${userCountUrl}`, {
      cache: 'no-store',
    });

    if (!data) return;
    data.json().then((res) => {
      runInAction(() => {
        const age = Number(data.headers.get('age'));
        dayjs.extend(utc);
        const nowDate = dayjs(new Date()).utc();
        const latestDate = nowDate
          .subtract(age, 'second')
          .format('YYYY-MM-DD HH:mm:ss');
        this.setUserCount(res);
        this.isLoading = false;
        this.latestDate = latestDate;
      });
    });
  });

  get getLocaleString() {
    const resultObject = Object.assign(this.userCount);
    const keyArray = Object.keys(resultObject);
    keyArray.forEach((key) => {
      resultObject[key] = resultObject[key].toLocaleString();
    });

    return resultObject;
  }
}
