import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgArrowBack = (props: Props) => (
  <svg {...props} viewBox="0 0 28 28">
    <defs>
      <path
        id="shh3k15jqa"
        d="M8.533 14.933c-.467-.466-.467-1.05-.117-1.516l.117-.117 8.283-8.283c.467-.467 1.167-.467 1.633 0 .467.466.467 1.05.117 1.516l-.117.117-7.466 7.467 7.466 7.466c.467.467.467 1.05.117 1.517l-.117.117c-.466.466-1.05.466-1.516.116l-.117-.116-8.283-8.284z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-398 -1525) translate(60 1471) translate(338 54)">
            <path fillRule="nonzero" d="M0 0H28V28H0z" />
            <mask id="quo6u4iivb" fill="#fff">
              <use xlinkHref="#shh3k15jqa" />
            </mask>
            <path
              fill="#FAFAFA"
              fillRule="nonzero"
              d="M0 0H28V28H0z"
              mask="url(#quo6u4iivb)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgArrowBack;
