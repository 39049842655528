import React from 'react';

interface Props {
  width: number;
  height: number;
  color?: string;
  opacity?: number;
}

const SvgInputDelete = (props: Props) => (
  <svg {...props} viewBox="0 0 24 24">
    <defs>
      <path
        id="svhlehqlma"
        d="M12 4.5c4.142 0 7.5 3.358 7.5 7.5 0 4.142-3.358 7.5-7.5 7.5-4.142 0-7.5-3.358-7.5-7.5 0-4.142 3.358-7.5 7.5-7.5zm3.571 3.97c-.293-.293-.768-.293-1.06 0l-2.491 2.489-2.49-2.49-.065-.058c-.294-.233-.723-.213-.995.059-.293.293-.293.767 0 1.06l2.489 2.49-2.449 2.45-.058.065c-.233.294-.214.723.058.995.293.293.768.293 1.061 0l2.449-2.449 2.45 2.45.065.058c.294.233.723.213.995-.059.293-.293.293-.767 0-1.06l-2.449-2.45 2.49-2.49.058-.065c.234-.294.214-.723-.058-.995z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(-578 -10) translate(60 5) translate(518 5)">
            <path fillRule="nonzero" d="M0 0H24V24H0z" />
            <mask id="7ddsup7tlb" fill="#fff">
              <use xlinkHref="#svhlehqlma" />
            </mask>
            <path
              // eslint-disable-next-line react/destructuring-assignment
              fill={props.color ? props.color : '#FFF'}
              // eslint-disable-next-line react/destructuring-assignment
              fillOpacity={props.opacity ? props.opacity : 0.3}
              fillRule="nonzero"
              d="M0 0H24V24H0z"
              mask="url(#7ddsup7tlb)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgInputDelete;
