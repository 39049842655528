import React from 'react';
import { observer } from 'mobx-react';
import { SearchForm } from './style';
import { useStore } from '../../store';
import SearchFormLabel from './SearchFormLabel';
import SearchFormMessage from './SearchFormMessage';

export default observer(() => {
  const {
    receiptStore:{
      onSubmit
    } 
  } = useStore();

  const submitHandler = (e: React.FormEvent) => {
    onSubmit(e);
  }

  return (
    <SearchForm onSubmit={submitHandler}>
      <SearchFormLabel />
      <SearchFormMessage />
    </SearchForm>
  );
});
