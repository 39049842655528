import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home, DashBoard, Receipt, Status, FaqPreview, NoticePreview, Templates, ShortUrl } from '../pages';
// import { DashBoard } from '../pages/DashBoard';
// import { Home } from '../pages/Home';
// import { Receipt } from '../pages/Receipt';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/receipt"
        element={
          <PrivateRoute>
            <Receipt />
          </PrivateRoute>
        }
      >
        <Route
        path=":menu"
        element={
          <PrivateRoute>
            <Receipt />
          </PrivateRoute>
        }
        />
      </Route>
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashBoard />
          </PrivateRoute>
        }
      />
      <Route
        path="/templates"
        element={
          <PrivateRoute>
            <Templates />
          </PrivateRoute>
        }
      />
      <Route
        path="/status/*"
        element={
          <PrivateRoute>
            <Status />
          </PrivateRoute>
        }
      />
       <Route
        path="/faqpreview"
        element={
          <PrivateRoute>
            <FaqPreview />
          </PrivateRoute>
        }
      />
      <Route
        path="/noticepreview"
        element={
          <PrivateRoute>
            <NoticePreview />
          </PrivateRoute>
        }
      />
      <Route
        path="/shorturl"
        element={
          <PrivateRoute>
            <ShortUrl />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AppRouter;
