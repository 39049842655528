import styled from "styled-components";
import { 
  Box as MuiBox, 
  Typography as MuiTypography 
} from "@mui/material";
import { ProcessingProps } from './Processing';

export const Box = styled(MuiBox)<Pick<ProcessingProps, "size" | "color">>`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }
  & .lds-ring {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
  & .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border: 4px solid ${(props) => props.color};
    border-radius: 50%;
    width: ${(props) => props.size - 12}px;
    height: ${(props) => props.size - 12}px;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }
  & .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #79747E;
  }
`;

export const Wrap = styled(MuiBox)`
  &.MuiBox-root {
    display:flex;
    justify-content: center;
    align-items:center;
    width: 100%;
    height: 100px;
    gap: 16px;
  }
`;
