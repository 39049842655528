import React from 'react';
import * as Assets from './assets';

export interface Props {
  name:
    | 'arrowBack'
    | 'arrowDown'
    | 'close'
    | 'done'
    | 'download'
    | 'favorite'
    | 'home'
    | 'errorLocal'
    | 'errorServer'
    | 'inputDelete'
    | 'play'
    | 'ratio_1_1'
    | 'ratio_16_9'
    | 'ratio_9_16'
    | 'search'
    | 'share'
    | 'time'
    | 'ImgDownload'
    | 'userIcon'
    | 'commentsIcon'
    | 'projectsIcon'
    | 'imgAssetStore'
    | 'imgChinaPromoCode'
    | 'imgSku'
    | 'svgChinaPromoCode'
    | 'SvgAssetStore'
    | 'SvgAssetStoreWebViewer'
    | 'SvgNotice'
    | 'SvgDci'
    | 'SvgFaq'
    | 'SvgGlobalPromoCode'
    | 'SvgPromoCodeReg'
    | 'SvgReceiptAndroid'
    | 'SvgReceiptIos'
    | 'SvgNauth'
    | 'SvgMixSearch'
    | 'SvgDashboard'
    | 'consoleSearch'
    | 'SvgStatus'
    | 'SvgVideo'
    | 'SvgNoticePreview'
    | 'SvgFAQPreview'
    | 'SvgPassword'
    | 'SvgEmail'
    | 'SvgTemplates'
    | 'SvgTemplateDashboard'
    | 'SvgHomepage'
    | 'SvgBlog'
    | 'SvgPurchase'
    | 'SvgNaver'
    | 'SvgGoogle'
    | 'SvgBing'
    | 'SvgFirebase'
    | 'SvgYourls'
    | 'SvgSection'
    | 'SvgLicense'
    | 'SvgLicenseRegister'
    | 'ImgForbiddenword'
    | 'ImgPopularKeyword'
    | 'ImgS3'
    | 'ImgAssetStoreV2';

  size?: number;
  color?: string;
  opacity?: number;
}

const IconView = ({ name, size = 16, opacity, color }: Props) => {
  const props = {
    width: size,
    height: size,
    opacity,
    color,
  };

  if (name === 'ratio_1_1') {
    return <Assets.SvgArrowBack {...props} />;
  } else if (name === 'arrowBack') {
    return <Assets.SvgArrowBack {...props} />;
  } else if (name === 'arrowDown') {
    return <Assets.SvgArrowDown {...props} />;
  } else if (name === 'close') {
    return <Assets.SvgClose {...props} />;
  } else if (name === 'done') {
    return <Assets.SvgDone {...props} />;
  } else if (name === 'errorLocal') {
    return <Assets.SvgErrorLocal {...props} />;
  } else if (name === 'errorServer') {
    return <Assets.SvgErrorServer {...props} />;
  } else if (name === 'favorite') {
    return <Assets.SvgFavorite {...props} />;
  } else if (name === 'home') {
    return <Assets.SvgHome {...props} />;
  } else if (name === 'inputDelete') {
    return <Assets.SvgInputDelete {...props} />;
  } else if (name === 'play') {
    const width = size - 4;
    const height = size;
    return <Assets.SvgPlay width={width} height={height} />;
  } else if (name === 'search') {
    return <Assets.SvgSearch {...props} />;
  } else if (name === 'share') {
    return <Assets.SvgShare {...props} />;
  } else if (name === 'userIcon') {
    return <Assets.SvgUser {...props} />;
  } else if (name === 'commentsIcon') {
    return <Assets.SvgComments {...props} />;
  } else if (name === 'projectsIcon') {
    return <Assets.SvgProjects {...props} />;
  } else if (name === 'SvgAssetStore') {
    return <Assets.SvgAssetStore {...props} />;
  } else if (name === 'svgChinaPromoCode') {
    return <Assets.SvgChinaPromoCode {...props} />;
  } else if (name === 'SvgAssetStoreWebViewer') {
    return <Assets.SvgAssetStoreWebViewer {...props} />;
  } else if (name === 'SvgNotice') {
    return <Assets.SvgNotice {...props} />;
  } else if (name === 'SvgDci') {
    return <Assets.SvgDci {...props} />;
  } else if (name === 'SvgFaq') {
    return <Assets.SvgFaq {...props} />;
  } else if (name === 'SvgGlobalPromoCode') {
    return <Assets.SvgGlobalPromoCode {...props} />;
  } else if (name === 'SvgPromoCodeReg') {
    return <Assets.SvgPromoCodeReg {...props} />;
  } else if (name === 'SvgReceiptAndroid') {
    return <Assets.SvgReceiptAndroid {...props} />;
  } else if (name === 'SvgReceiptIos') {
    return <Assets.SvgReceiptIos {...props} />;
  } else if (name === 'SvgMixSearch') {
    return <Assets.SvgMixSearch {...props} />;
  } else if (name === 'SvgNauth') {
    return <Assets.SvgNauth {...props} />;
  } else if (name === 'SvgDashboard') {
    return <Assets.SvgDashboard {...props} />;
  } else if (name === 'consoleSearch') {
    return <Assets.SvgConsoleSearch {...props} />;
  } else if (name === 'SvgStatus') {
    return <Assets.SvgStatus {...props} />;
  } else if (name === 'SvgVideo') {
    return <Assets.SvgVideo {...props} />;
  } else if (name === 'SvgNoticePreview') {
    return <Assets.SvgNoticePreview {...props} />;
  } else if (name === 'SvgFAQPreview') {
    return <Assets.SvgFAQPreview {...props} />;
  } else if (name === 'SvgPassword') {
    return <Assets.SvgPassword {...props} />;
  } else if (name === 'SvgEmail') {
    return <Assets.SvgEmail {...props} />;
  } else if (name === 'SvgTemplates') {
    return <Assets.SvgTemplates {...props} />;
  } else if (name === 'SvgTemplateDashboard') {
    return <Assets.SvgTemplateDashboard {...props} />;
  } else if (name === 'SvgHomepage') {
    return <Assets.SvgHomepage {...props} />;
  } else if (name === 'SvgBlog') {
    return <Assets.SvgBlog />;
  } else if (name === 'SvgPurchase') {
    return <Assets.SvgPurchase />;
  } else if (name === 'SvgNaver') {
    return <Assets.SvgNaver />;
  } else if (name === 'SvgGoogle') {
    return <Assets.SvgGoogle />;
  } else if (name === 'SvgBing') {
    return <Assets.SvgBing />;
  } else if (name === 'SvgFirebase') {
    return <Assets.SvgFirebase {...props} />;
  } else if (name === 'SvgYourls') {
    return <Assets.SvgYourls {...props} />;
  } else if (name === 'SvgSection') {
    return <Assets.SvgSection {...props} />;
  } else if (name === 'imgSku') {
    return <img src={Assets.ImgSku} alt="imgSku" {...props} />;
  } else if (name === 'ImgForbiddenword') {
    return <img src={Assets.ImgForbiddenWord} alt="imgSku" {...props} />;
  } else if (name === 'ImgPopularKeyword') {
    return <img src={Assets.ImgPopularKeyword} alt="imgSku" {...props} />;
  } else if (name === 'SvgLicense') {
    return <Assets.SvgLicense {...props} />;
  } else if (name === 'SvgLicenseRegister') {
    return <Assets.SvgLicenseRegister {...props} />;
  } else if (name === 'ImgS3') {
    return <img src={Assets.ImgS3} alt="ImgS3" {...props} />;
  } else if (name === 'ImgAssetStoreV2') {
    return (
      <img src={Assets.ImgAssetStoreV2} alt="ImgAssetStoreV2" {...props} />
    );
  } else {
    return null;
  }
};

export default IconView;
