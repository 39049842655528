import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgErrorLocal = (props: Props) => (
  <svg {...props} viewBox="0 0 80 80">
    <defs>
      <path
        id="2h78mrw07a"
        d="M40 9.5c16.845 0 30.5 13.655 30.5 30.5S56.845 70.5 40 70.5 9.5 56.845 9.5 40 23.155 9.5 40 9.5zm0 5c-14.083 0-25.5 11.417-25.5 25.5S25.917 65.5 40 65.5 65.5 54.083 65.5 40 54.083 14.5 40 14.5zm0 34.913c1.729 0 3.13 1.405 3.13 3.137 0 1.733-1.401 3.138-3.13 3.138-1.729 0-3.13-1.405-3.13-3.138 0-1.732 1.401-3.137 3.13-3.137zm0-25.101c1.643 0 2.974 1.332 2.974 2.974 0 .051 0 .102-.004.152l-.876 17.133c-.057 1.115-.978 1.99-2.094 1.99-1.116 0-2.037-.875-2.094-1.99l-.876-17.133c-.084-1.64 1.177-3.038 2.818-3.122.05-.003.101-.004.152-.004z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-294 -113) translate(294 113)">
          <path fillRule="nonzero" d="M0 0L80 0 80 80 0 80z" />
          <mask id="k4n33eszkb" fill="#fff">
            <use xlinkHref="#2h78mrw07a" />
          </mask>
          <path
            fill="#FFF"
            fillOpacity=".6"
            fillRule="nonzero"
            d="M0 0L80 0 80 80 0 80z"
            mask="url(#k4n33eszkb)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgErrorLocal;
