export default {
  application: [
    { name: '- Select -', value: '- Select -' },
    { name: 'kinemasterios', value:'com.kinemaster.kios' },
    { name: 'kinemaster', value:'com.nexstreaming.app.kinemasterfree' },
  ],
  environment: [
    { name: '- Select -', value: '- Select -' },
    { name:'Staging', value: 2 },
    { name:'Publish', value: 3 },
  ],
  edition: {
    default : [
      { name: '- Select -', value: '- Select -' },
    ],
    kinemaster : [
      { name: '- Select -', value: '- Select -' },
      { name:'Android', value:'Android' },
      { name:'China', value:'China' },
    ],
    kinemasterios : [
      { name: '- Select -', value: '- Select -' },
      { name:'iOS', value:'Ios' },
    ],
  },
  language: [
    { name: '- Select -', value: '- Select -' },
    { name: "Korean", value: "ko" },
    { name: "English", value: "en" },
    { name: "Arabic", value: "ar" },
    { name: "German", value: "de" },
    { name: "Indonesian", value: "id" },
    { name: "simplified Chinese", value: "zh-cn" },
    { name: "Traditional Chinese", value: "zh-tw" },
    { name: "Spanish; Castilian", value: "es" },
    { name: "Turkish", value: "tr" },
    { name: "French", value: "fr" },
    { name: "Hindi", value: "hi" },
    { name: "Italian", value: "it" },
    { name: "Japanese", value: "ja" },
    { name: "Malay", value: "ms" },
    { name: "Portuguese", value: "pt" },
    { name: "Russian", value: "ru" },
    { name: "Hong Kong", value: "zh-hk" },
    { name: "Vietnamese", value: "vi" },
    { name: "Thai", value: "th" },
  ],
  
}
