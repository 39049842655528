import React from "react";

interface Props {
  width: number;
  height: number;
}

const SvgHome = (props: Props) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 28 28">  <image id="image0" width="28" height="28" x="0" y="0"
  href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAByCAYAAACP3YV9AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
CXBIWXMAAAsTAAALEwEAmpwYAAAZr0lEQVR42u2deZBfVZXHP+eN/5h0M0GWdAcsAnSHxVK6Ex3G
DRKrcAhLDSCLWCwhoZhYQZaRUcGFICIoA0nYHCSBEBcS9pKEgJbSiViGKc1SMwImURoT8vtlYUyR
BuefvDt/vO3e++597/1+vfyaoU9X1/v93rvr+d5z7jnn3vt+whCSUmoy0AMcEV8nxP+T4yTJ9/cC
7Y3/Afq16+vARqBfRDYOVWUymMxKqQnAWcDJ8XXCyPDo/xX1AUuBNSLS32whTQEZA3g1cA1j4A0l
PQ0sEpG+RjM2BOQYgCNGfcBljUhoZSCVUlcD8xkDcCRpvojcVCVhKZCxFC4AZrW6V+9R6gdmlEln
IZCxFfoUkQU6Rq2jfkrA9AIZg/gCmeswRq2lvURgbnQ9dAIZq9MNjIE42qgfj2QGngwLGQNxNNJk
4KlY0AzKARlbp5e2usVj5KUe4Eb7pqFa43lxA+9CF0NtryOvboW3BlA7dkb3dtSzHgrIpInxtQMO
60C1tyHHdLW66c3SDD1wYAO5lHeDNG6vw0ubCNdtgle3wo46at9A2hsRUAKIiu7Z/0T3RQQlKgLz
2C5k2gnItF7o7Gh1D6tQn4jMSL6kQMbS+FqrW+eldZsIf/4b+MWLqDd2pmBFV5X1JJksRCEiICpL
B0gAKgXSLENEAIWa1IFM6yE4Yyb09rS650WUSqUO5FJGmzS+NYBa8iThg09AInHGfx4QyEBRtkQS
5ZFALDAz0KP8xM8UdHbC1BMI5syGjlEnqalURoomsoL+2upWpbRuE+GCH6HWbYwlSXlB1OdAW5Wm
KhbzWQKkxHmUPo861LIkZc48lWDmadDT02oO6XSgiOxNgJwFPNTqFvHbTYQLlqHWbTLmslIQNXVq
APmxHuTYo1HtbbB5K/S9CJJJHLpKNkDL1LEtzQjQ20sw7yroGhWG0rUisjAB8imi9cTW0LY64b/e
ngFoGSU2iKakga1mObaL4K6bYZKlCnfUCe+4B9a8mKnfRMXGg0Hp5RS1BeDUmQSXtFzl9onIjATI
v9Iil0PdsYxwyROw721TrYExl2UgisFsBSaQx3YRPLQA2tu8dYZzr4b1GyPpTPOTl2iA2DhK51B7
AKGQS2Yjl8xuBfsA9orIgaKU6iHyHUeWttXZP2c+vLzVrTZjZjldCfAy/++efyQviTbV6uy/aA4y
MGAaNrbEBeDUCgnFAw4BOjoJbr+7VdLZG9CCUJx64En2f3ZuCqK4GBV/bgTE4DOfLAcRoLMDmdLl
B1FXp4E4AHS0ZWeNcN4s1FOPjjQ7AXoCRniJSt14H+H8+wx3QiVMQ6XqTDc83AaP+Z/MjVVJpvVq
5YtpBKHVi0ud4h5cAwOo++8ivH/RSLIUYHLASM2Nbw2w/5S5qCVPFQKSN2JwSIAyjZs4n2qkPaJi
gyf5nPmhOP1PNGA1lYojzdOPEl45C3bWR4S1wBEB0dbF4aVtdcJTIlWa9wkVPjfDiNiQpRdNCpXO
4Fp1xqktWw2VXgqiTSKeNLGE/3kL4fVXjhSYBw6/RG6rs//c61Db6yTxTUNtgiFZtsSlTLKkz3BB
4oGg+l6MVHYZ1euoDRuyAYNZjxMg2wXR5d/nquysjRSYRwyvsRODKNt35qRM6eqpyNk3AFTpQEjA
lEDLOzBAeP/S0maFSx6CtwesAaKcdWfXbC5Nr3r7wa1yd9UIbxh2MCcEgy/DQ9vq7D/3y8j2nUbM
05bEvLMvuftKY7KymG2rarX88cjpd0nmwABq0d2wenXmg7raYYChA5zksABP0iHuQbC7Rvj1K+Ht
fcPGbhmuYMD+U+Y6fESLCR5JzILfGKsVLqsxHxyPIz+d8QrGtF4QCDdvgdWrUW8PWKG3gjmxTNrA
BLgs/5HdBAsfHg4c94pSqiFjrwqpG+8jXPyk00XIh8RMEA0GuFyPEhDzZZB3Y/CUD27gnMDoV1V9
EJx5AcGcq4ccySFXreqBJwkXP+mJVWaMU+AJTBeAKFGLJZAofGe4IKY7kl1VfjkrTS9+ECtZrjqI
4m6vPSBWrkCtXDHKgdxWJ7zz4dQYsdVXEtcUzeFPfTnAJT0mMJGrkvwZc2hMeuxUSiTaLcEq0+0+
w0ZrS65MPR2Wyo0/qxVLYNfQGj9DCmR43nWw7+28WgtIozU6oJkFiwmK8T0DSJC8pGt+paHCk3XG
9Htcb4ADWLsnuv/qAsMzJxqfHQZdQu8MEH7/q6MTSHXHstRXtNWjGCPcfKYzIA+SJomBSsER7b6+
hSNfb8ZAASSwgg85Rhe5HzTwzAG+naZ/C+rRJaMMyG11wgXL8kyBnBqMmK99D7L7rrksNY4KBkIK
piZ9Lsn1gpEw31aj2PXZ6tT6d4HoTaNQz66AXbXRA2R457L8XOZjpqYuTSbi/Y8MGw1cTW3ag8Ft
qCT3PEBRwHwgb9CINQiSNBVUrp7mnX2oH3xndACp/vAnePwXKWOT6EvkGuhgauAazPKt92WjP9tf
QzYnxl+TQZHOg4lUYteDg9G2qrXUPeCUtjLf0766QEzm/pfXo15ePwqAvOm+1PJMHXmUZchooTUL
RD2S4wpc6/HVnDo1NIDWBonnzMAzUIpUbVk6W8qM/OIuB0c7NLDV44OfKwcH5LpNxj4bRbTV0K1O
86setkOfRHJsq1U3bADDGvUzSAdA8m33SZSRxu0++DSHy4AzyjHuadchkMr3DSZz+NjzKUOVpQZd
O72zPTZWxw3VFvVQX6JSliQy/VPIBechU3ugVketXo166KH8nGYvDvvUX1s78k8zYXwb6k9bYN1a
U7p0AF0AOcHDMaD8YKonFiPH39c0Fs2H6LbX2f/Ji6KGptsR9X/lANABnpHGymOPcoHgjFORb16f
a45avZrw1lv94TjyZQHIZ09DvvglGN+elfWnLaivXQnvDHhAUIWgFM2JXmNLIHjgF0Y7GqGmVWu4
YBkuUzxVgwe0Icd1If9wAhzQ5gUxyp5fXNYNJWPuu/wyZ3tk5sxIQr1xW7KCkmdd3ch1N+SYJ0d3
I2ddYDI8B5Z46rBA/NBUZPrpyPTTYXK3F0QE1PPNh+6aV60vbcIlWfz9eOTSzxFc9jljS6J6+jnU
vQ+janWzwwGZZS9Y0ZjEF43Tt7cVHrCRjg5r301aezwSNOXT0Ulw43f9ZZ11PuqRJSZQOkCFKlPB
h6YSzPsGHNqZlQnRkta358GeWk41q+eWI+dc3hQczUnkuk2OKA7I4RMJfvZDgqsuze0rlbNOJVi6
ADmuK6dOlQZgIrlKlCFECHDA+PK2eVWdpj06OgluvwsmdvrLGd/mBqooaBB/lo+dTHDTvQaIKR3S
SfC9ZXBIR76Mvw2gXmnO6GkKyPDx5x3SqJCrLoHDCrYjHtZBsOjmlLEqcMRVrYM5poVa0rASBqeM
vu6GYhAB/rzFYwkXuyNyaAcyu2SZalwbMvebbov592uagaRJidTUajonHt6BnHNqed5JHciMTxYb
SGQht8xqVUgpkjrjrZBa0t65VyEf6S0tJly8yFKnqnSAJCqVQzpLy5fjpyLHT82Vo9avbQqSxoHc
Xoc36poUxR08voEDLR/rMRligaj7kiJmVKcSOaxTALloNnL2+aXZ1SMPwn9vyMrSy/G6IZlxU5kO
7szPv2/WovmzQWoYSPXSpvxpJgE57ujKZUh7m6kuNctU7BGuq93SgpP/vA8onzgJubj8fIZ65EHN
yCkBUaw0Vdqok24t61PKq43Pk00BqTM5VX9vNLBQWqvnYqepLWj7j/r2yUqtdajBiZ3RvFiWc93a
CEQbIP1qfHZElV7fXJ2Xf9mclRf3VaHg1d83CksTqvWVraakJPd/+Ztqe0qB8JnnUrWcWK4SaFsc
xYwOZQZ/iWrN+XLAxNhCHd9WnHdXHbUwcUeUv7wiYAXUmlXVdsvtrsEr660pI3a3tm1pGJbGJfLV
rbkOSQBqYB/hvQ+X5//p40i9roGYMahwm0eAM2SaI53R49vL3QyAXfVo7+k7+8jWJcUsM+dLahpD
r/edAcLHHyxtZviThZpmE7OcN4d7jnxlq3OEpnHWHz+Ous8PpnrmOdSd95jqNPUnyQXQM0Za6tJH
lrTIF79UDiIQ3vK1aIHXkIyCnX36d1e47rnlqCcWuyt7ex/qhzfD+jXu+iAaUA0aPI1Fdt4aMBiP
Q4LC/1gKfS8SXPQ55Jgu1AFtyO82Ej7zHGr9xmx3gMEcUnD18FzkgjjmoQpgysWzkVNOK02uFi+C
17ZkklHkJ+p1uNwPjSfqicWotavgoycRHDEFgPAvm+HXz8Lf9jnnYP0Etdq2GTm4fBA2BaR6Y2c2
L1ogGp374xbCb92WAhPqFqmWzjwKgLkfB0f5VUE853zkoooW6s8eNUFMr66yfctUJojp5z01eH4F
YQ58t6WrbKlsgBoDckc9BUDZAGqqJjky7todp0doVM5PNDub3fcw1+b12efBKTMrOfzqZ4/GFqq1
6u9c2ai4ilF1x3lpWarhebIhIIXEPXAtN5lrkj5nXy9M0ole22CFlj59902F+RHgqO5qrtyft0Yq
tWw90QuG61kxiOkynV2fr94GqTFjZ0c9NydmB0RVXn3qcx9Zen0wqNycae64G0znnLSrTvjdr2Ec
unG5GUAuxOcFqlwSlX6ay5PGqK9BiWzY/dCB0iXRDnJnYEtWU3pPWfnjZ4EqYGrEjkHR2wORm7F7
h8Y4u4M6g8UcRC6gXdsone6K1n7DwNHLcgBdkRoEUqvMBkuT0kyylDGBp6erNKMmPeNoB9FzjNO2
8jdL49vgqC4HYC7G5wPuznxYli52OmvvkFWfMvI1r4EaA1KzMLOKlXNjVWpBBvpSVJQ+d3Cn1G/T
9uAMkoKrvwFHdZdIvg8U/VrFsEmuYuYxnvlWVhrra2NAHtaRqkTXbjdTSklHnL4DXOUYWDDqIf9s
sDS+jeA79yL6oq9vTizUDtlnCSR75gTIF/IrUKcHT2qoWw1KZDaybMvU2F9Kfj3R2PtaBcQcA6Qc
zJ11+K8N0bHyEjDl5ntgYodbBer9tQFwtFfltEkDO85z92lq0DYG5KSJOEdsqnJd86cJYLYD3AOi
NiAyHxKqqBr10yWE11+JuuHK8r4c2knw7VgyvctVdgDAoQZ9AJVap0nfPBrgoOpRncaBTHfD6UBF
DUvfV4NjP6pTcim30mxmlo3UpN7XthAuuqW8P4d2wldui9YFfXOcMVeLG6C0fh0g3AB5VTQm0MMJ
pBzTlfMVlQWKfdYj1xH7+FvlzmkMKmxkfP3Vqmi7RlnyI7uRr9xmMt4GKFXxHskN7GdWH9LKNE3j
qk/7LuMOaASaRlVrB+qANs/7TjPfUALywYFKQBXtSdUY6kXFuq5cgVpefq5CPjQVmfeNBqXGU7/L
OHOCijnwU4qNp8O7G4Km8YDApI6U6fq2jITF9vkME+iiHeBV9saUtS4fL1UrllQ6sy/TT0fOm1Mw
iDxqtcyqlnybdO2S2QxZGtUgiNAMkMd05ec4Q80qbc60OlMIjhQwRQO6sHEuhoF6cBHqhVXl2c+9
HDl3TlaO0U7XQNMCB7lnOmAeP9EoWwt9HjalUVia2Orx0Z58YzTrNK9WKrgY+nypg+LLV4iGzbDo
qu65BfWHDeXZz70cOe0CTdX7JLHA+CkE307vWA2ZMq1hWBqXyGk9uYY5l6ECt9WaswQDDzPSCi2G
VWqkm2Hq+19B9Zfvh5GLr0FOPj3+ZoFhGDaeOR0rTUGbXM+kewSApLMDmdShrXxYh1rThlp+ZY5b
/kCykylVgRTH50Sy3hlA3f7VSuf25eJrYPIUR9usIIXeVh9ARVZ4Kgyx1X/QpIZdD2h2p/nJn0rV
ad5HRJM05emAQy25pNW3Il+IAJmLozM1KSs5RLO7BMxx7QTXfi/zMZ0q3hc7tdLn0iR5NaMsKau7
gQ3OGjUFpJz0aUOtmGf2PeClnfP4ZD5m2GkKG2Z/96i1BMyybYsHd8JxvVrZBVNFrj6ftFrlWP2U
E89oBpImgZzag2pP/ElJ46q598IZjUysuypzIp5BUKVxBfn0654a4c3lYMr4drd2KKoPcEakUgMK
E+j4KgdNgibmRxjEQdfggvPMlxa5rFbnd8/8YaQTd5pdNdhZoBJ362cvKzjyr28m/PHCwn6qV39f
YLnq91T54DHsAQfQTapVGASQcv55hvS5ozhJ4z2j0OiotexjbxCO04QPLnIzfOUK1B/WZ3V6DQyL
kWtXEf5oobvMny+P95cWuRFWoKPIXSlpk8y8olk4BvFWj7a2+JfcilYxtI75RqH3RUMu017gpbWE
d90S7UWFaMPviiWohxaVMywhO83zywnvvxlej8vcU0M99QDqJwuqqWqDHFNKkc2QfDzxDPhA49Zq
1qVBvK9VbdyAuuaqYlVSNAqTjpYxylWWK639GhRvvap6m7wgeJaiaLScKF3wpfuhq7n5EQb5nh3p
6YXeXk8HHM6+18qrqHLTwyCucnyr8PrVs55YBqKrTfr2jQZAlFxahXRNGxSIMARvvpJLLyvopNU5
I2PBxF9oCdqAVdybKh5VXaQd9PLsNIUbs/ySaATJkzad8+XBwjC4FyZBJJWqtxc2btDmA1UCkEOd
frgXOao7OnSzq4Z6aW18sKaEseBXb4bBZaUz2Vqi+uLPx05FPtgd7afZswO1aQ38T81K34BaBuTE
M5sKkudwGJJ3mtfrhHNnwcCAvwM+U31iJ3Lt15EP9+aKVc+sQC1ZlB/5NsOKGFfkl+bSeAbEwZ0E
s78Fx+TdA/XblajHFmhH8vQ24gZR4huiCL71zKCMnISG5n2tHR3IOedrDXeY4CnzNUZN7CS49W4n
iABy5gXI9bd5mGPNlc652DMHO9OAE8Rx7QT/9gMniADy8TOQud83DS2jnXa9SVsUcuoVQwIiDOEb
lOWS2XB08mYnVyjLYiogX5hden5RTjwJmXGaNU9CzkLVr0D5HOxze8zPcsrno1BdURunTEM+foZ/
YKX1agP5A5MiIIeIhvSd5sFN34U2x7vUPGpVPn5StYI/E59zrPTWqYJ7pQZRPr984nQq0T+eUVF9
A+9vJ7jy/qFjPEP9KwMTO5FLLvOPQr1zEzvLz/UnWQ7pxKkmdctTv1e0ZTFtk5SnGddWKo1ptg9O
sfLHX431y+jmUKrUhIb8dz/krPOjd9mUSYY0UzjkAwOWCi16c6Nxr8BYSr6PqzbQjDxWGcpqn5x8
IXLyhUPI8YgCYO9QFyr/chUc3UWhf7irVv23onbXrEEgjoFSIb7qk1aXQQbR0baqJ4e3W69lcWwo
k8OnIGcP3md00N5hARIg+N49mSHjmZvUM9V+xjbse9bKb4fiCoLWhgSWbT2JM+lt/OXyam1ct9JU
y/YgOWgSMvuO4WA1xEAOD41vI7jt7gjMnBGhUiDVa8V7aNSzK2DNqhLmY0qczsD0mZj39GdJmyxL
VkRQv1peeuhUrVsJ/7nSUWZ8PWgSwbz7h3xe1GnYJBKI/MTb7kYSMPXOoaKVi2/OQ73wrDO7WrUC
tXShlc9IgTeYnlzLtiym9/KSrFDwt32Ed85F/XYlzha8sBz1k5s0SbTqGQEQgX5RSj0FnDWctaQ/
hrnbDmdp10M7kRM/He2R2VVD/W5tNj8V7g31PUsYGz/31VvlfXPEdX1wCjJlGoxrh3feQr20Cv53
n7+skQERoE+UUkuBS4e7JnbVCG+9Hl7bXOBv2XteVHXm2+UU+nR2fVpdzrLESl/U7vhe1zSCOf8O
72/uHeUN0tIA6B+Jmji0k2DBUuRM613hdviqzLpMP0txOXp6K7/kBkPJUlRRFMix615OvjBy+EcG
RIDX38dIAZn0e87VcGgH6tEH40BzzKg0gePq9P0qrqw4ylSl86VejrglLkmjpZf3t8OpVwyLn1hC
G0QpNRl4baRrZleN8MZ50YapKq9ASV9EUbJ/BrzzpvHqtTKJb3B3gxw2BZlzx0jMhy7qjbo0TL+z
XIXUo0tQjy1xM87l8ONKo+ctMX7se0V1FUlskmZcO9IaKUxor4gcmAA5/JZrEe2uoR5bjFrzrMms
MsOmCOgig6RKmgrppHsa8oX5rZLChPpEZEYC5DXAgla2BoD+zYTLFsLLGzzSFlOjarIExNzrxUqs
aumeBjOviPbatJ5micjDCZATiObJCa1uFRD94NfaVai1z1ZXq85nVJfECu6KdE8dTQAmdKSI9Gca
X6n5wI2tbpVBu2uoJxdHP2qyp+YBSDOAwAO0x0gCS+od8+u4dmT655HpF46kO1GVlorIZXp3iK3X
DYwSqbRJvbIefr0K9cf1sCd5lxzZ1RmmK7OGPVsWD5oEHzkJPjK9qbOKI0gzRKRP71LErNEolS7a
U4t+UuHV9fCXzahtmx0GStlm5fj5uPboTcWHR+E31TUNaeJ8YguoT0Rm6F1MabTNlQ3Rti3Rbxbv
qcGbkcSqNyN1nERb01dLH9QZLRoffsy7BTQXHSki/ckXsZ8qpWYBD7W6lWNUSDeJyHz9hrhSjVgg
fYyaoX4ROdK+6VtYvgbY2OoWj1GO+oEZrgfiyxFbsS8Ak1vd+jECog0Avfq8qJN3q0ecYQYjvDoy
Rk7qJ3I1+n0JCvfsxBl7gYdb3ZP3MG0kAnFjUaLSzVcisldEZgHXMpz7e8bIRYsokcSEpLysjOJ5
cz5jFu1wUx+Ri9FXNUNDQCY0BuiwUR8NAphQU0AmFAM6Hfjn+Dqh1Zx4l9FeojnwaeBhEdnbbEGD
AtImpVQPkbvSQwTqCfGjyfF1Au8dsPeS2RR7iSzPvcDr8eeNZQZMI/R/rmE6ApVslvcAAAAldEVY
dGRhdGU6Y3JlYXRlADIwMjMtMDQtMjFUMDc6MzA6MTArMDA6MDCO46fBAAAAJXRFWHRkYXRlOm1v
ZGlmeQAyMDIzLTA0LTIxVDA3OjMwOjEwKzAwOjAw/74ffQAAACh0RVh0ZGF0ZTp0aW1lc3RhbXAA
MjAyMy0wNC0yMVQwNzozMDoxMiswMDowMD80L4sAAAAASUVORK5CYII=" />
</svg>
);

export default SvgHome;


