import decodeJwt from 'jwt-decode';
import { CRUD, ProjectType, ROLE, RoleNames } from '../types';

interface AuthInfo {
  user_name: string;
  role_name: string;
  user_id?: string;
  user_email?: string;
  user_type?: string;
}

function getJwtDecode(ntoken: string) {
  const decodedToken: AuthInfo = decodeJwt(ntoken);
  return decodedToken;
}
// eslint-disable-next-line
function getPathFromUrl(url: string) {
  return url.split(/[?#]/)[0];
}

export function checkToken(ntoken: string | null) {
  if (ntoken) {
    const decodedToken: AuthInfo = getJwtDecode(ntoken);
    // console.log(decodedToken);
    if (
      !!decodedToken &&
      decodedToken.user_name &&
      decodedToken.role_name &&
      decodedToken.user_email
    ) {
      localStorage.setItem('ntoken', ntoken);
      const expired = new Date();
      expired.setHours(expired.getHours() + 3);
      localStorage.setItem('expired', String(expired.getTime()));
      localStorage.setItem('name', decodedToken.user_name);
      localStorage.setItem('email', decodedToken.user_email);
      localStorage.setItem('role', decodedToken.role_name);
    }else if(
      !!decodedToken &&
      decodedToken.user_name &&
      decodedToken.role_name
    ) {
      localStorage.setItem('ntoken', ntoken);
      const expired = new Date();
      expired.setHours(expired.getHours() + 3);
      localStorage.setItem('expired', String(expired.getTime()));
      localStorage.setItem('name', decodedToken.user_name);
      localStorage.setItem('role', decodedToken.role_name);
    }
    
    // eslint-disable-next-line
    const location = localStorage.getItem('location')!
    window.location.href = location;
  } else {
      const pathName = window.location.pathname;
      localStorage.setItem('location',pathName);
    if(pathName.includes('/status')){
      window.location.href = 'https://auth.nexstreaming.com/auth';
    }
    if(pathName.includes('/shorturl')){
      window.location.href = 'https://auth.nexstreaming.com/auth';
    }
    // if(pathName.includes('/templates')){
    //   window.location.href = 'https://auth.nexstreaming.com/auth';
    // }
  }
}

export default function getAuth(key: string) {
  const today = new Date().getTime();
  const expired = Number(localStorage.getItem('expired'));
  if (localStorage.getItem('expired')) {
    if (today > expired) {
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      localStorage.removeItem('role');
      checkToken(null);
    } else {
      return localStorage.getItem(key);
    }
  } else {
    return null;
  }
}

export function signOut() {
  localStorage.removeItem('ntoken');
  localStorage.removeItem('expired');
  localStorage.removeItem('name');
  localStorage.removeItem('email');
  localStorage.removeItem('role');

  window.location.href = window.location.origin + window.location.pathname;
}

export function checkAuth(projectType: ProjectType, actionType: CRUD) {
  const role = getAuth('role') as RoleNames;
  if (
    Object.keys(ROLE).includes(role) &&
    ROLE[role][actionType].includes(projectType)
  ) {
    const expired = new Date();
    expired.setHours(expired.getHours() + 3);
    localStorage.setItem('expired', String(expired.getTime()));
    return true;
  } else {
    return false;
  }
}
