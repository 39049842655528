import React from 'react';

interface Props {
  width: number;
  height: number;
}

const SvgYourls = (props: Props) => (
	<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-8.577 0 9761.575 3965.615"
    {...props}
    height={32}
  >
    <defs>
      <filter id="a" width="109.4%" height="113.1%" x="-4.7%" y="-6.6%">
        <feMorphology
          in="SourceAlpha"
          radius={54}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-50}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="b" width="109.4%" height="113.1%" x="-4.7%" y="-6.6%">
        <feMorphology
          in="SourceAlpha"
          radius={54}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-50}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="c" width="109.4%" height="113.1%" x="-4.7%" y="-6.6%">
        <feMorphology
          in="SourceAlpha"
          radius={54}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-50}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="e" width="109.4%" height="113.1%" x="-4.7%" y="-6.6%">
        <feMorphology
          in="SourceAlpha"
          radius={54}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-50}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="f" width="109.4%" height="113.1%" x="-4.7%" y="-6.6%">
        <feMorphology
          in="SourceAlpha"
          radius={54}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-50}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="g" width="108.4%" height="141.8%" x="-4.2%" y="-20.9%">
        <feMorphology
          in="SourceAlpha"
          radius={25}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-51}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="h" width="108.4%" height="141.8%" x="-4.2%" y="-20.9%">
        <feMorphology
          in="SourceAlpha"
          radius={25}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-51}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="i" width="108.4%" height="141.8%" x="-4.2%" y="-20.9%">
        <feMorphology
          in="SourceAlpha"
          radius={25}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-51}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="j" width="109.3%" height="113%" x="-4.7%" y="-6.5%">
        <feMorphology
          in="SourceAlpha"
          radius={32}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-30}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="l" width="109.3%" height="113%" x="-4.7%" y="-6.5%">
        <feMorphology
          in="SourceAlpha"
          radius={32}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-30}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="m" width="109.3%" height="113%" x="-4.7%" y="-6.5%">
        <feMorphology
          in="SourceAlpha"
          radius={32}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-30}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <filter id="n" width="108.5%" height="142.1%" x="-4.2%" y="-21%">
        <feMorphology
          in="SourceAlpha"
          radius={15}
          result="shadowSpreadInner1"
        />
        <feOffset
          dy={-31}
          in="shadowSpreadInner1"
          result="shadowOffsetInner1"
        />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2={-1}
          k3={1}
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.262745098 0 0 0 0 0.576470588 0 0 0 0 0.733333333 0 0 0 1 0"
        />
      </filter>
      <path
        id="d"
        d="M396 0h318c218.705 0 396 177.295 396 396S932.705 792 714 792H396C177.295 792 0 614.705 0 396S177.295 0 396 0zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
      />
      <path
        id="k"
        d="M237.6 0h190.8C559.623 0 666 106.556 666 238S559.623 476 428.4 476H237.6C106.377 476 0 369.444 0 238S106.377 0 237.6 0zm0 152.657c-47.055 0-85.2 38.21-85.2 85.343 0 47.134 38.145 85.343 85.2 85.343h190.8c47.055 0 85.2-38.21 85.2-85.343 0-47.134-38.145-85.343-85.2-85.343z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="#4393bb"
          d="M2405.092 2322.584a1210.46 1210.46 0 0 1-133.54 167.692c-45.9 185.978-147.59 353.021-291.045 479.362-169.615 149.38-387.81 233.343-618.57 233.362-230.792-.019-449.238-84.128-618.995-233.616-143.63-126.479-245.363-293.646-290.987-479.612a1210.448 1210.448 0 0 1-133.06-167.188c-134.585-203.693-203.531-442.246-201.878-692.817l1.99-321.2C120.873 792.49 539.802 375 1056 375c104.933 0 208.211 17.48 305.993 51.246C1459.776 392.48 1563.053 375 1667.987 375c516.197 0 935.126 417.49 936.994 933.567l1.99 321.2c1.652 250.57-67.294 489.124-201.879 692.817zM2936.5 2861c-685.661 0-1241.5-555.838-1241.5-1241.5S2250.839 378 2936.5 378 4178 933.838 4178 1619.5 3622.16 2861 2936.499 2861zM4816 378c515.833 0 934 418.166 934 934v307c0 685.727-557.293 1241-1244 1241-686.708 0-1244-555.273-1244-1241v-307c0-515.834 418.166-934 934-934 106.404 0 211.08 18.033 310.001 52.82C4604.92 396.034 4709.595 378 4816 378zm931 2481c-515.873 0-934-418.205-934-934.216l.086-313.242c12.758-677.771 553.346-1220.584 1226.135-1233.317 7.94-.15 15.884-.225 23.83-.225h222.948c515.834 0 934 418.166 934 934 0 221.64-77.796 432.021-217.101 598.7-96.116 115.004-218.051 204.98-355.274 262.637C6538.583 2570.013 6175.23 2859 5746.998 2859zM7229.997 72c515.834 0 934 418.166 934 934v919.425c0 516.223-417.98 934.575-934 934.575-515.834 0-934-418.166-934-934v-920c0-515.834 418.166-934 934-934zm1377 2791h-409c-517.506 0-937-419.553-937-937.065 0-70.646 7.914-140.695 23.5-208.974-15.586-68.273-23.5-138.318-23.5-208.961a938.43 938.43 0 0 1 7.962-122.022C7337.07 809.956 7826.04 375 8406.998 375h409c517.505 0 937 419.553 937 937.065 0 70.646-7.915 140.695-23.501 208.974 15.586 68.273 23.5 138.318 23.5 208.961 0 40.828-2.659 81.584-7.961 122.022C9676.925 2428.044 9187.953 2863 8606.997 2863z"
        />
        <path
          fill="#fff"
          d="M2338.976 1631.484c1.312 197.344-52.159 383.953-157.484 543.362-44.488 67.332-97.076 128.183-156.858 181.952-45.037 327.006-324.081 578.175-662.675 578.202-338.754-.027-618.687-251.815-662.883-578.452-59.667-53.708-112.162-114.474-156.58-181.702-105.326-159.41-158.796-346.018-157.485-543.362l1.992-321.63C388.168 941.289 687.303 643 1056 643c108.42 0 212.884 26.035 305.994 73.963 93.11-47.928 197.573-73.963 305.993-73.963 368.697 0 667.833 298.289 668.997 666.854zM2936.5 2593c-537.65 0-973.5-435.85-973.5-973.5S2398.85 646 2936.5 646s973.5 435.85 973.5 973.5-435.85 973.5-973.5 973.5zM4816 646c367.822 0 666 298.178 666 666v307c0 537.765-437.357 973-976 973s-976-435.235-976-973v-307c0-367.822 298.178-666 666-666 110.075 0 216.006 26.96 310.003 76.43C4599.997 672.96 4705.926 646 4816 646zm931 1945c-367.84 0-666-298.196-666-666.142l.086-310.757c10.011-531.855 435.089-957.929 963.207-967.924a991.39 991.39 0 0 1 18.759-.177H6286c367.822 0 666 298.178 666 666 0 324.832-232.552 595.349-540.25 654.146C6390.499 2314.805 6101.003 2591 5747 2591zM7230 340c367.822 0 666 298.178 666 666v919.425C7896 2293.68 7597.963 2592 7230 2592c-367.822 0-666-298.178-666-666v-920c0-367.821 298.178-666 666-666zm2255 1390c0 29.741-1.973 59.412-5.906 88.832C9427.747 2260.43 9053.081 2595 8607 2595h-409c-369.5 0-669-299.572-669-669.065 0-71.836 11.416-142.227 33.347-208.976C7540.416 1650.216 7529 1579.83 7529 1508c0-29.741 1.973-59.412 5.906-88.832C7586.253 977.57 7960.919 643 8407 643h409c369.5 0 669 299.572 669 669.065 0 71.836-11.416 142.227-33.347 208.976C9473.584 1587.784 9485 1658.17 9485 1730z"
        />
        <path
          fill="#4393bb"
          d="M9181.569 1521.048C9216.839 1582.62 9237 1653.956 9237 1730c0 19.464-1.34 38.84-3.996 58.006C9197.252 2104.943 8928.636 2347 8607 2347h-409c-232.537 0-421-188.543-421-421.065 0-76.052 20.161-147.399 55.431-208.983C7797.161 1655.38 7777 1584.044 7777 1508c0-19.464 1.34-38.84 3.996-58.006C7816.748 1133.057 8085.364 891 8407 891h409c232.537 0 421 188.543 421 421.065 0 76.052-20.161 147.399-55.431 208.983z"
        />
      </g>
      <path
        fill="#fff"
        d="M8663 1730c0-84.5 68.5-153 153-153s153 68.5 153 153c0 8.229-.65 16.306-1.9 24.183C8948.42 1936.653 8794.326 2079 8607 2079h-409c-84.5 0-153-68.53-153-153.065s68.5-153.065 153-153.065h422c23.699 0 42.92-19.18 43-42.87zm-312-222c0 84.5-68.5 153-153 153s-153-68.5-153-153c0-8.229.65-16.306 1.9-24.183C8065.58 1301.347 8219.674 1159 8407 1159h409c84.5 0 153 68.53 153 153.065s-68.5 153.065-153 153.065h-422c-23.699 0-42.92 19.18-43 42.87z"
      />
      <path
        fill="#4393bb"
        fillRule="nonzero"
        d="M6812 1006c0-230.855 187.145-418 418-418s418 187.145 418 418v919.425c0 231.43-187.145 418.575-418 418.575s-418-187.145-418-418z"
      />
      <path
        fill="#fff"
        d="M7077 1006c0-84.5 68.5-153 153-153s153 68.5 153 153v920c0 84.5-68.5 153-153 153s-153-68.5-153-153c0-.334.001-.667.003-1z"
      />
      <path
        fill="#4393bb"
        fillRule="nonzero"
        d="M6165 1925.018c-.01 230.847-187.151 417.982-418 417.982-230.855 0-418-187.145-418-418.073l.085-308.457c7.468-396.759 325.587-714.874 719.9-722.337a740.69 740.69 0 0 1 14.067-.133H6286c230.855 0 418 187.145 418 418s-187.145 418-418 418h-120.978z"
      />
      <path
        fill="#fff"
        d="m5900 1925 .034-306c0-85.052 68.956-154 154.017-154H6286c84.5 0 153-68.5 153-153s-68.5-153-153-153h-222.948c-3.024 0-6.042.029-9.052.086-251.88 4.766-455.26 207.556-460 459.414V1925c0 84.5 68.5 153 153 153s153-68.5 153-153z"
      />
      <path
        fill="#4393bb"
        fillRule="nonzero"
        d="M4816 894c230.855 0 418 187.145 418 418v307c0 400.793-326.32 725-728 725s-728-324.207-728-725v-307c0-230.855 187.145-418 418-418 122.96 0 233.52 53.092 310.007 137.588C4582.492 947.086 4693.047 894 4816 894z"
      />
      <path
        fill="#fff"
        d="M4663 1619h-.113c-3.153 83.928-72.187 151-156.887 151s-153.734-67.072-156.887-151l-.113-307c0-84.5-68.5-153-153-153s-153 68.5-153 153v307c0 254.051 207.292 460 463 460s463-205.949 463-460v-307c0-84.5-68.5-153-153-153s-153 68.5-153 153z"
      />
      <path
        fill="#4393bb"
        fillRule="nonzero"
        d="M2936.5 2345c-400.683 0-725.5-324.817-725.5-725.5S2535.817 894 2936.5 894s725.5 324.817 725.5 725.5-324.817 725.5-725.5 725.5z"
      />
      <path
        fill="#fff"
        d="M2936.5 2077c-252.67 0-457.5-204.83-457.5-457.5s204.83-457.5 457.5-457.5 457.5 204.83 457.5 457.5-204.83 457.5-457.5 457.5zm.5-305c84.5 0 153-68.5 153-153s-68.5-153-153-153-153 68.5-153 153 68.5 153 153 153z"
      />
      <path
        fill="#4393bb"
        fillRule="nonzero"
        d="M1667.987 891c232.17 0 420.444 187.932 421 419.971l1.995 322.109c.995 148.382-38.332 286.891-116.405 405.053-50.883 77.012-115.648 141.783-191.59 193.854v31.743c0 234.77-188.468 423.251-421.009 423.27C1129.473 2686.981 941 2498.504 941 2266c.003-1.676 0-34.013 0-34.013-75.942-52.071-140.706-116.842-191.59-193.854-78.072-118.162-117.4-256.67-116.404-405.053L635 1310.97C635.556 1078.932 823.831 891 1056 891c120.54 0 229.247 50.658 305.994 131.847C1438.74 941.658 1547.448 891 1667.987 891z"
      />
      <path
        fill="#fff"
        d="M1209 2266c0 84.495 68.494 152.993 153 153 84.494-.007 152.987-68.505 152.987-153 0-1.337-.017-2.67 0-4v-191.516c27.181-10.086 54.234-22.202 79.084-36.25 62.812-35.512 116.615-82.86 156.905-143.839 47.676-72.156 72.674-158.32 72.011-255.58l-2-322.815c0-84.5-68.5-153-153-153s-153 68.5-153 153v294c.07 10.116-.195 19.164-.889 27.407.588 5.45.89 10.986.89 16.593 0 84.497-68.498 152.997-152.994 153-84.497-.003-152.994-68.503-152.994-153 0-5.607.302-11.143.89-16.593-.694-8.243-.96-17.29-.89-27.407v-294c0-84.5-68.5-153-153-153s-153 68.5-153 153l-2 322.815c-.663 97.26 24.336 183.424 72.011 255.58 40.29 60.979 94.093 108.327 156.905 143.838 24.85 14.05 51.903 26.165 79.084 36.251V2262c.017 1.33 0 2.663 0 4z"
      />
      <g fillRule="nonzero">
        <path
          fill="#fff"
          d="M7296 3259v-233c0-55.04 65.62-83.615 105.915-46.123l460 428c26.78 24.917 26.78 67.329 0 92.246l-460 428C7361.62 3965.615 7296 3937.04 7296 3882v-233h-549c-34.794 0-63-28.206-63-63v-264c0-34.794 28.206-63 63-63z"
        />
        <g strokeLinecap="square" strokeLinejoin="round">
          <path
            fill="#4393bb"
            fillRule="evenodd"
            d="M7359 3322h-612v264h612v296l460-428-460-428z"
          />
          <path
            stroke="#fff"
            strokeWidth={63}
            d="M7327.5 3290.5h-612v327h612v336.835l52.957-49.273 460-428 24.786-23.062-24.786-23.062-460-428-52.957-49.273z"
          />
        </g>
      </g>
      <path
        fill="#fff"
        d="M4668 3042h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396s177.295-396 396-396zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
      />
      <path
        fill="#000"
        d="M2736 0h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396S2517.295 0 2736 0zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
        filter="url(#a)"
        transform="translate(1932 3042)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M4668 3093c-190.538 0-345 154.462-345 345s154.462 345 345 345h318c190.538 0 345-154.462 345-345s-154.462-345-345-345zm0-51h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396s177.295-396 396-396zm0 305c-50.258 0-91 40.742-91 91s40.742 91 91 91h318c50.258 0 91-40.742 91-91s-40.742-91-91-91zm0-51h318c78.424 0 142 63.576 142 142s-63.576 142-142 142h-318c-78.424 0-142-63.576-142-142s63.576-142 142-142z"
      />
      <path
        fill="#fff"
        d="M5838 3042h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396s177.295-396 396-396zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
      />
      <path
        fill="#000"
        d="M3906 0h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396S3687.295 0 3906 0zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
        filter="url(#b)"
        transform="translate(1932 3042)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M5838 3093c-190.538 0-345 154.462-345 345s154.462 345 345 345h318c190.538 0 345-154.462 345-345s-154.462-345-345-345zm0-51h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396s177.295-396 396-396zm0 305c-50.258 0-91 40.742-91 91s40.742 91 91 91h318c50.258 0 91-40.742 91-91s-40.742-91-91-91zm0-51h318c78.424 0 142 63.576 142 142s-63.576 142-142 142h-318c-78.424 0-142-63.576-142-142s63.576-142 142-142z"
      />
      <path
        fill="#fff"
        d="M3498 3042h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396s177.295-396 396-396zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
      />
      <path
        fill="#000"
        d="M1566 0h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396S1347.295 0 1566 0zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
        filter="url(#c)"
        transform="translate(1932 3042)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M3498 3093c-190.538 0-345 154.462-345 345s154.462 345 345 345h318c190.538 0 345-154.462 345-345s-154.462-345-345-345zm0-51h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396s177.295-396 396-396zm0 305c-50.258 0-91 40.742-91 91s40.742 91 91 91h318c50.258 0 91-40.742 91-91s-40.742-91-91-91zm0-51h318c78.424 0 142 63.576 142 142s-63.576 142-142 142h-318c-78.424 0-142-63.576-142-142s63.576-142 142-142z"
      />
      <g transform="translate(1932 3042)">
        <use xlinkHref="#d" fill="#fff" />
        <use xlinkHref="#d" fill="#000" filter="url(#e)" />
      </g>
      <path
        fill="#000"
        d="M396 0h318c218.705 0 396 177.295 396 396S932.705 792 714 792H396C177.295 792 0 614.705 0 396S177.295 0 396 0zm0 254c-78.424 0-142 63.576-142 142s63.576 142 142 142h318c78.424 0 142-63.576 142-142s-63.576-142-142-142z"
        filter="url(#f)"
        transform="translate(1932 3042)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M2328 3093c-190.538 0-345 154.462-345 345s154.462 345 345 345h318c190.538 0 345-154.462 345-345s-154.462-345-345-345zm0-51h318c218.705 0 396 177.295 396 396s-177.295 396-396 396h-318c-218.705 0-396-177.295-396-396s177.295-396 396-396zm0 305c-50.258 0-91 40.742-91 91s40.742 91 91 91h318c50.258 0 91-40.742 91-91s-40.742-91-91-91zm0-51h318c78.424 0 142 63.576 142 142s-63.576 142-142 142h-318c-78.424 0-142-63.576-142-142s63.576-142 142-142z"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M2713 3580c-78.424 0-142-63.576-142-142s63.576-142 142-142h724c78.424 0 142 63.576 142 142s-63.576 142-142 142z"
      />
      <path
        fill="#fff"
        d="M2713 3529h724c50.258 0 91-40.742 91-91s-40.742-91-91-91h-724c-50.258 0-91 40.742-91 91s40.742 91 91 91z"
      />
      <path
        stroke="#026090"
        strokeWidth={51}
        d="M2713 3554.5h724c64.341 0 116.5-52.159 116.5-116.5s-52.159-116.5-116.5-116.5h-724c-64.341 0-116.5 52.159-116.5 116.5s52.159 116.5 116.5 116.5z"
      />
      <path
        fill="#000"
        d="M781 487h724c50.258 0 91-40.742 91-91s-40.742-91-91-91H781c-50.258 0-91 40.742-91 91s40.742 91 91 91z"
        filter="url(#g)"
        transform="translate(1932 3042)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M3880 3580c-78.424 0-142-63.576-142-142s63.576-142 142-142h724c78.424 0 142 63.576 142 142s-63.576 142-142 142z"
      />
      <path
        fill="#fff"
        d="M3880 3529h724c50.258 0 91-40.742 91-91s-40.742-91-91-91h-724c-50.258 0-91 40.742-91 91s40.742 91 91 91z"
      />
      <path
        stroke="#026090"
        strokeWidth={51}
        d="M3880 3554.5h724c64.341 0 116.5-52.159 116.5-116.5s-52.159-116.5-116.5-116.5h-724c-64.341 0-116.5 52.159-116.5 116.5s52.159 116.5 116.5 116.5z"
      />
      <path
        fill="#000"
        d="M1948 487h724c50.258 0 91-40.742 91-91s-40.742-91-91-91h-724c-50.258 0-91 40.742-91 91s40.742 91 91 91z"
        filter="url(#h)"
        transform="translate(1932 3042)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M5047 3580c-78.424 0-142-63.576-142-142s63.576-142 142-142h724c78.424 0 142 63.576 142 142s-63.576 142-142 142z"
      />
      <path
        fill="#fff"
        d="M5047 3529h724c50.258 0 91-40.742 91-91s-40.742-91-91-91h-724c-50.258 0-91 40.742-91 91s40.742 91 91 91z"
      />
      <path
        stroke="#026090"
        strokeWidth={51}
        d="M5047 3554.5h724c64.341 0 116.5-52.159 116.5-116.5s-52.159-116.5-116.5-116.5h-724c-64.341 0-116.5 52.159-116.5 116.5s52.159 116.5 116.5 116.5z"
      />
      <path
        fill="#000"
        d="M3115 487h724c50.258 0 91-40.742 91-91s-40.742-91-91-91h-724c-50.258 0-91 40.742-91 91s40.742 91 91 91z"
        filter="url(#i)"
        transform="translate(1932 3042)"
      />
      <path
        fill="#fff"
        d="M8953.6 3200h190.8c131.223 0 237.6 106.556 237.6 238s-106.377 238-237.6 238h-190.8c-131.223 0-237.6-106.556-237.6-238s106.377-238 237.6-238zm0 152.657c-47.055 0-85.2 38.21-85.2 85.343 0 47.134 38.145 85.343 85.2 85.343h190.8c47.055 0 85.2-38.21 85.2-85.343 0-47.134-38.145-85.343-85.2-85.343z"
      />
      <path
        fill="#000"
        d="M939.6 0h190.8C1261.623 0 1368 106.556 1368 238s-106.377 238-237.6 238H939.6C808.377 476 702 369.444 702 238S808.377 0 939.6 0zm0 152.657c-47.055 0-85.2 38.21-85.2 85.343 0 47.134 38.145 85.343 85.2 85.343h190.8c47.055 0 85.2-38.21 85.2-85.343 0-47.134-38.145-85.343-85.2-85.343z"
        filter="url(#j)"
        transform="translate(8014 3200)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M8953.6 3230.652c-114.323 0-207 92.833-207 207.348s92.677 207.348 207 207.348h190.8c114.323 0 207-92.833 207-207.348s-92.677-207.348-207-207.348zm0-30.652h190.8c131.223 0 237.6 106.556 237.6 238s-106.377 238-237.6 238h-190.8c-131.223 0-237.6-106.556-237.6-238s106.377-238 237.6-238zm0 183.308c-30.155 0-54.6 24.486-54.6 54.692s24.445 54.692 54.6 54.692h190.8c30.155 0 54.6-24.486 54.6-54.692s-24.445-54.692-54.6-54.692zm0-30.651h190.8c47.055 0 85.2 38.21 85.2 85.343 0 47.134-38.145 85.343-85.2 85.343h-190.8c-47.055 0-85.2-38.21-85.2-85.343 0-47.134 38.145-85.343 85.2-85.343z"
      />
      <g transform="translate(8014 3200)">
        <use xlinkHref="#k" fill="#fff" />
        <use xlinkHref="#k" fill="#000" filter="url(#l)" />
      </g>
      <path
        fill="#000"
        d="M237.6 0h190.8C559.623 0 666 106.556 666 238S559.623 476 428.4 476H237.6C106.377 476 0 369.444 0 238S106.377 0 237.6 0zm0 152.657c-47.055 0-85.2 38.21-85.2 85.343 0 47.134 38.145 85.343 85.2 85.343h190.8c47.055 0 85.2-38.21 85.2-85.343 0-47.134-38.145-85.343-85.2-85.343z"
        filter="url(#m)"
        transform="translate(8014 3200)"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M8251.6 3230.652c-114.323 0-207 92.833-207 207.348s92.677 207.348 207 207.348h190.8c114.323 0 207-92.833 207-207.348s-92.677-207.348-207-207.348zm0-30.652h190.8c131.223 0 237.6 106.556 237.6 238s-106.377 238-237.6 238h-190.8c-131.223 0-237.6-106.556-237.6-238s106.377-238 237.6-238zm0 183.308c-30.155 0-54.6 24.486-54.6 54.692s24.445 54.692 54.6 54.692h190.8c30.155 0 54.6-24.486 54.6-54.692s-24.445-54.692-54.6-54.692zm0-30.651h190.8c47.055 0 85.2 38.21 85.2 85.343 0 47.134-38.145 85.343-85.2 85.343h-190.8c-47.055 0-85.2-38.21-85.2-85.343 0-47.134 38.145-85.343 85.2-85.343z"
      />
      <path
        fill="#026090"
        fillRule="nonzero"
        d="M8482.6 3523.343c-47.055 0-85.2-38.21-85.2-85.343 0-47.134 38.145-85.343 85.2-85.343H8917c47.055 0 85.2 38.21 85.2 85.343 0 47.134-38.145 85.343-85.2 85.343z"
      />
      <path
        fill="#fff"
        d="M8482.6 3492.692H8917c30.155 0 54.6-24.486 54.6-54.692s-24.445-54.692-54.6-54.692h-434.4c-30.155 0-54.6 24.486-54.6 54.692s24.445 54.692 54.6 54.692z"
      />
      <path
        stroke="#026090"
        strokeWidth={30.6}
        d="M8482.6 3507.992H8917c38.61 0 69.9-31.342 69.9-69.992s-31.29-69.992-69.9-69.992h-434.4c-38.61 0-69.9 31.342-69.9 69.992s31.29 69.992 69.9 69.992z"
      />
      <path
        fill="#000"
        d="M468.6 292.692H903c30.155 0 54.6-24.486 54.6-54.692s-24.445-54.692-54.6-54.692H468.6c-30.155 0-54.6 24.486-54.6 54.692s24.445 54.692 54.6 54.692z"
        filter="url(#n)"
        transform="translate(8014 3200)"
      />
    </g>
  </svg>
);

export default SvgYourls;