import React from 'react';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import { observer } from 'mobx-react';
import {
  MenuContainer, MenuContent, MenuName,
  MenuTitle, MenuContents, MenuTitleText } from './style';

export default observer(({server, menuHandler, modalOpen, setModalOpen}:any) => {

  const containerRef = React.useRef(null);
  const MenuButtonHandler = () => {
    return modalOpen ? setModalOpen(false) : setModalOpen(true);
  }
  const MenuContentsHandler = (event:any) => {
    menuHandler(event);
    setModalOpen(false);
  }

  return(
    <Modal
      open={modalOpen}
      onClose={MenuButtonHandler}
      ref={containerRef}
    >
      <Slide direction="right" in={modalOpen} container={containerRef.current}>
        <MenuContainer>
          <MenuTitle onClick={MenuButtonHandler}>
            <MenuTitleText>KineMaster Console</MenuTitleText>
          </MenuTitle>
          <MenuContents>
            <MenuContent value="product" server={server} onClick={MenuContentsHandler}>
              <MenuName>Product</MenuName>
            </MenuContent>
            <MenuContent value="test" server={server} onClick={MenuContentsHandler}>
              <MenuName>Test</MenuName>
            </MenuContent>
          </MenuContents>
        </MenuContainer>
      </Slide>
    </Modal>
  )
})