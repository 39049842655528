import React, { useState, useEffect } from 'react';
import NoticeModal from './NoticeModal';
import settingObject from './settingObject';
import { NoticeContainer } from './style';
import NoticeSelect from './NoticePreview.Select';
import NoticeEdition from './NoticePreview.Edtion';

interface settingType {
  edition:string;
  application:string;
  language:string;
  environment:string;
}

const initializeState = {
  edition: '- Select -',
  application: '- Select -',
  language: '- Select -',
  environment: '- Select -'
}

const applicationList : any = {
  'com.kinemaster.kios' : 'kinemasterios',
  'com.nexstreaming.app.kinemasterfree' : 'kinemaster',
  '- Select -': 'default'
}

export default () => {
  const [objState, setObjState] = useState(initializeState);
  const [application, setApplication] = useState('default');
  const [languageState, setLanguageState] = useState(true);

  const selectHandler = (event:any) => {
    const { value, name } = event.target;
    let obj= {};
    if(name==='application') {
      obj = { application : value };
      objState.edition='- Select -';
      setApplication(applicationList[value]);
    }
    if(name==='edition') obj = { edition : value };
    if(name==='language') obj = { language : value };
    if(name==='environment') obj = { environment : value };

    setObjState((prev : settingType) => ({
      ...prev,
      ...obj,
    }));
  }

  useEffect(() => {
    setObjState(initializeState);
  },[]);

  useEffect(() => {
    console.log(objState);
    if(objState.edition === 'China'){
      setLanguageState(true);
      setObjState((prev : settingType) => ({
        ...prev,
        language : 'zh-cn',
      }));
    }else{
      setLanguageState(false);
    }
  },[objState]);
  
  return(
    <NoticeContainer>
      <NoticeSelect
        nameText="application"
        objState={objState}
        selectHandler={selectHandler}
        settingObject={settingObject}
      />
      <NoticeSelect
        nameText="environment"
        objState={objState}
        selectHandler={selectHandler}
        settingObject={settingObject}
      />
      <NoticeEdition
        application={application}
        objState={objState}
        selectHandler={selectHandler}
        settingObject={settingObject}
      />
      <NoticeSelect
        nameText="language"
        objState={objState}
        selectHandler={selectHandler}
        settingObject={settingObject}
        languageState={languageState}
      />
      <NoticeModal objState={objState} initializeState={initializeState} />
    </NoticeContainer>
  )
};
