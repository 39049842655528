import styled from 'styled-components';
import Select from '@mui/material/Select';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Toolbar, Typography, AppBar, Box, Button } from '@mui/material';

export const HeaderWrap = styled(Box)`
  flex-grow:1;
`;

export const MuiAppBar = styled(AppBar)`
  background-color: #6750A4 !important;
  height:64px !important;
  & > div{
    height:64px !important;
  }
`;

export const MuiTitleTypography = styled(Typography)`
  font-size:22px !important;
`;

export const MuiSelect = styled(Select)`
  width: 128px;
  margin-left: 36px;
  .MuiSelect-outlined {
    border-color: transparent;
  }
  .MuiSelect-select {
    color: #fff;
  }
  .MuiSelect-icon {
    color: #fff;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  @media screen and (max-width: 600px) {
    display:none !important;
  }

`;

export const HeaderTitle = styled.div`
  display:flex;
  align-items:center;
  min-height: 64px !important;
`;

export const ServerText = styled(Typography)`
  display:flex;
  align-items:flex-start;
  height:100%;
  padding-bottom:15px;
  padding-left:5px;
  font-size:11px !important;
`;

export const LogoutButton = styled.button`
  color: #D0BCFF;
  background-color:transparent !important;
  text-transform: none;
  box-shadow:none;
  border:none;
  position: absolute;
  right: 30px;
  top: auto;
  font-weight:500;
`;

export const HeaderToolbar = styled(Toolbar)`
  height: 56px !important;
  @media screen and (max-width: 600px) {
    display:flex;
    align-items:center;
    justify-content:space-between;
  }
`

export const HeaderBlank = styled.div`
  width:24px;
`

export const MenuButton = styled(DragHandleIcon)`
  margin-right:5px;
`

export const MenuContainer = styled(Box)`
  background-color:black; 
  width:100vw;
  height:100vh;
  border-radius:none;
`

export const MenuContents = styled(Button)`
  width:calc(100vw - 60px);
  display:flex !important;
  justify-content:space-between !important;
  align-items: center !important;
`

export const MenuName = styled(Typography)`
  font-size: 34px !important;
  font-family: 'Roboto' !important;
`