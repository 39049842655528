import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle<{pathname:string}>`
${reset}
  // 추가적인 reset code 및 가본 css가 필요하면 추가
  html, body{
    height:100%;
    --dashboard-container-padding: 5vw;
    --Home-container-padding: 5vw;
    background-color:#F2F0F8;
    font-family: Roboto;
  }
  #root{height:100%}
  a {
    color: inherit;
    text-decoration: none;
    &:active{
      color:inherit
    }
  }
  ${(props) => 
    props.pathname.includes("/status") && 
    `&.MuiTablePagination-menuItem{
      font-size:12px !important;
    }
    &.MuiTablePagination-menuItem[aria-selected="true"]{
      background: rgba(103, 80, 164, 0.12) !important;
    }
    &.MuiTablePagination-menuItem:hover{
      background: rgba(103, 80, 164, 0.12) !important;
    }
    &.MuiPopover-paper{
      background: linear-gradient(0deg,rgba(103,80,164,0.05),rgba(103,80,164,0.05)),rgb(255,251,254) !important;
    }
    
    & .MuiPickersDay-root.Mui-selected{
      background-color:#6750A4 !important;
    }
    `
  }
  
`;
export default GlobalStyle;
