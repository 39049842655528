import React from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { Box, Search, TextField, Button } from "./style";

export interface SearchProps {
  value: string;
  textHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  action: () => void;
}

export const SearchComponent = ({
  value,
  textHandler,
  action,
}: SearchProps) => {
	const enterHandler = (event : any) => {
		if (event.key === 'Enter') {
			action();
			(document.activeElement as HTMLElement).blur();
		}
	}
	return (
		<Box>
			<Search>
				<TextField value={value} onChange={textHandler} onKeyDown={enterHandler}/>
				<Button onClick={action}>
					<SearchIcon sx={{ color: "#21005D" }} />
				</Button>
			</Search>
		</Box>
	);
};

export default SearchComponent;
