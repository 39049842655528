import styled from "styled-components";

export const HomeMainContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  touch-action: auto;
  gap: 24px;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: auto;
  touch-action: auto;
  gap: 24px;
`;

export const HomeContainer = styled.div`
  margin-top:90px;
  display:grid;
  align-items: stretch;

  @media screen and (min-width: 1920px) {
    width: 100%;
    max-width: 1920px;
    grid-template-columns: repeat(4, 1fr);
    padding-left: var(--dashboard-container-padding);
    padding-right: var(--dashboard-container-padding);
  }

  @media screen and (min-width: 1440px) and (max-width: 1920px) {
    width: calc(100vw - 312px);
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: 1024px) and (max-width:1440px){
    width:100vw;
    grid-template-columns: repeat(3, 1fr);
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (min-width: 640px) and (max-width: 1024px){
    width:100vw;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: 640px) {
    width:100vw;
    grid-template-columns: repeat(1, 1fr);
    padding-left: 20px;
    padding-right: 20px;
  }
  
  row-gap:16px;
  column-gap:16px;
  padding-bottom:30px;
`;